
import { defineComponent } from 'vue';
import useVaults from '@/services/useVaults';
import { fwp } from '@/lib/utils/bn';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';

export default defineComponent({
  components: {},

  setup() {
    const { acsStats } = useVaults();

    return {
      fwp,
      showTokenBalance,
      acsStats
    };
  }
});
