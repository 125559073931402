export const TGW_NFT_BASE_URI = 'https://nft-data.thegreatwave.io';

export const tofunft = 'https://tofunft.com/nft/bsc';

//export const onsenApi = 'https://api.unrekt.net/api/v1/acryptos-onsen';
export const onsenApi = 'https://api.unrekt.info/api/v1/acryptos-onsen';

export const supportedNetworks = [
  {
    id: 'binance',
    label: 'BNB Chain',
    value: 'BSC',
    logo: 'binance.svg',
    chainId: 56
  },
  {
    id: 'cronos',
    label: 'Cronos',
    logo: 'cronos.svg',
    value: 'CRO',
    chainId: 25
  },
  {
    id: 'harmony',
    label: 'Harmony',
    logo: 'harmony.png',
    value: 'ONE',
    chainId: 1666600000
  },
  {
    id: 'fantom',
    label: 'Fantom',
    logo: 'fantom.svg',
    value: 'FTM',
    chainId: 250
  },
  {
    id: 'avalanche',
    label: 'Avalanche',
    logo: 'avalanche.svg',
    value: 'AVAX',
    chainId: 43114
  }
  // {
  //   id: 'aurora',
  //   label: 'Aurora',
  //   logo: 'aurora.svg',
  //   value: 'AURORA',
  //   chainId: 1313161554
  // }
];

export enum NftType {
  FOUNDER_NFT = '0',
  WATERFALL = '1',
  SPARKS = '2'
}

export const NFT_TYPE = {
  '0': 'FOUNDER NFT',
  '1': 'GOV NFT',
  '2': 'SPARKS'
};

export const NFT_TYPE_FULL = {
  '0': `Founder's Token`,
  '1': 'Governance Token',
  '2': 'Sparks - A Concert gNFT'
};

export const NFT_DEFAULT_TYPE = '1';
