<template>
  <BalModal
    noPad
    :show="isVisible"
    width="lg"
    @close="$emit('close')"
    style="z-index: 39;"
  >
    <BalLoadingBlock v-if="loadingTokenLists" class="h-96" />
    <TradeCard v-else />
  </BalModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TradeCard from '@/components/cards/TradeCard/TradeCard.vue';
import useTokenLists from '@/composables/useTokenLists';

export default defineComponent({
  emits: ['close'],

  components: {
    TradeCard
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { loadingTokenLists } = useTokenLists();

    return {
      loadingTokenLists
    };
  }
});
</script>
