
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import AppNavActions from '../navs/AppNav/AppNavActions.vue';
import VaultsTopNav from './top/index.vue';
// import VaultsFilterWithSearch from './VaultsFilterWithSearch.vue';
import VaultsDetail from './detail/index.vue';
// import Tags from './Tags.vue';
import useMenuCollapsed from '@/composables/useMenuCollapsed';
import useBreakpoints from '@/composables/useBreakpoints';
import useVaultsList from '@/composables/useVaultsList';
// import { filterList } from '@/constant';
import useVaults from '@/services/useVaults';
import { plantLevel } from '@/lib/vault/farmVaultUtils';

export default defineComponent({
  components: {
    AppNavActions,
    VaultsTopNav,
    // VaultsFilterWithSearch,
    VaultsDetail
    // Tags
  },

  props: {
    deprecated: { type: Boolean }
  },

  setup(props) {
    const {
      farmVaults,
      formatedFarmVaults,
      formatedFarmVaultsDeprecated
    } = useVaults();
    const { menuCollapsed } = useMenuCollapsed();
    const { width, upToLargeBreakpoint } = useBreakpoints();
    const { selectedVaultId, setSelectedVaultId } = useVaultsList();

    //COMPUTED
    const isLoadingData = computed(() => !farmVaults.value.length);

    const formatedFarmVaultsForPage = computed(() =>
      props.deprecated
        ? formatedFarmVaultsDeprecated.value
        : formatedFarmVaults.value
    );

    const searchedFarmVaults = computed(() => {
      return formatedFarmVaultsForPage.value.filter(
        item =>
          item.name.toLowerCase().indexOf(searchInput.value?.toLowerCase()) > -1
      );
    });

    const filteredFarmVaults = computed(() => {
      return searchedFarmVaults.value.filter(
        item =>
          filterKey.value === 'all' ||
          item.tags.includes(filterKey.value) ||
          (filterKey.value === 'swords' &&
            (!item.walletEmpty ||
              !item.vaultEmpty ||
              !item.walletFarmEmpty ||
              !item.farmEmpty ||
              !item.farmRewardsEmpty))
      );
    });

    const sortedFarmVaults = computed(() => {
      if (sortBy.value === '') {
        return filteredFarmVaults.value;
      } else {
        return [...filteredFarmVaults.value].sort(function(a, b) {
          const x = a[sortBy.value];
          const y = b[sortBy.value];
          const result = sortOrder.value
            ? x < y
              ? -1
              : x > y
              ? 1
              : 0
            : x < y
            ? 1
            : x > y
            ? -1
            : 0;
          return result;
        });
      }
    });

    // MOUNTED
    onMounted(() => {
      setVaultsViewHeight();
      setSelectedVaultId(upToLargeBreakpoint.value ? -1 : 0);
    });

    // WATCHERS
    watch(width, () => {
      setVaultsViewHeight();
    });
    watch(menuCollapsed, () => {
      setTimeout(() => {
        setVaultsViewHeight();
      }, 1000);
    });
    watch(upToLargeBreakpoint, () => {
      setSelectedVaultId(
        upToLargeBreakpoint.value
          ? -1
          : selectedVaultId.value > -1
          ? selectedVaultId.value
          : formatedFarmVaultsForPage.value[0].id
      );
    });

    // DATA
    const searchInput = ref('');
    const filterKey = ref('all');
    const sortBy = ref('');
    const sortOrder = ref(true);
    const sortMap = ref([
      { label: 'name', col: 2, sortKey: 'name' },
      { label: 'apy', col: 1, sortKey: 'apy' },
      { label: 'daily', col: 1, sortKey: 'daily' },
      { label: 'tvl', col: 1, sortKey: 'tvl' },
      { label: 'balance', col: 1, sortKey: 'balance' },
      { label: 'pending', col: 2, sortKey: 'rewardPending' }
    ]);

    // METHODS
    const search = input => {
      searchInput.value = input;
    };

    const filter = key => {
      filterKey.value = key;
    };

    const sortUpdate = key => {
      if (key === sortBy.value) {
        sortOrder.value = !sortOrder.value;
      } else {
        sortBy.value = key;
        sortOrder.value = true;
      }
    };

    const setVaultsViewHeight = () => {
      if (upToLargeBreakpoint.value) {
        document.getElementById(
          'vaults_list_container'
        )!.style.height = `calc(100vh - 325px)`;
        return;
      }
      const rect = document
        .getElementById('vaults_list_container')!
        .getBoundingClientRect();
      const y = rect.top;
      const height = `calc(100vh - ${y + 8}px)`;
      document.getElementById('vaults_list_container')!.style.height = height;
      document.getElementById(
        'vaults_detail_container'
      )!.style.maxHeight = height;
    };

    return {
      upToLargeBreakpoint,
      menuCollapsed,
      selectedVaultId,
      setSelectedVaultId,
      // data
      filterKey,
      searchInput,
      sortBy,
      sortOrder,
      sortMap,
      // computed
      isLoadingData,
      formatedFarmVaultsForPage,
      searchedFarmVaults,
      filteredFarmVaults,
      sortedFarmVaults,
      // methods
      plantLevel,
      setVaultsViewHeight,
      filter,
      search,
      sortUpdate
    };
  }
});
