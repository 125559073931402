
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { fwp } from '@/lib/vault/bn';
import { showTokenBalance, triggerUpdate } from '@/lib/vault/farmVaultUtils';

export default defineComponent({
  components: {},

  props: {
    farmVault: { type: Object }
  },

  setup(props) {
    const { t } = useI18n();
    const toast = useToast();

    // DATA
    const showBoostDetail = ref(false);

    // METHOD
    const forceBoost = () => {
      try {
        props.farmVault?.farm
          .deposit(0)
          .then(() => triggerUpdate(props.farmVault));
      } catch (error) {
        console.log(error);
        toast.error(t('alert_force_new_boost_error'));
      }
    };

    return {
      fwp,
      showTokenBalance,
      // data
      showBoostDetail,
      // method
      forceBoost
    };
  }
});
