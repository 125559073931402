
import { defineComponent } from 'vue';
// import HarvestOptimizer from './HarvestOptimizer.vue';
import MyTvl from './MyTvl.vue';
import PendingHarvest from './PendingHarvest.vue';
import TgwBoost from './TgwBoost.vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  components: {
    MyTvl,
    TgwBoost,
    PendingHarvest
    // HarvestOptimizer
  },

  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();
    return {
      upToLargeBreakpoint
    };
  }
});
