import { Config } from './config';
import { quoter } from './quoter';
import { StableSwap } from './stable-swap.js';

import { bn, fw } from './bn.js';

export { Farm };

const wbnb = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
const busd = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
const btcb = '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c';

const maxuint =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

const masterFarmAbi = {
  '0xeaE1425d8ed46554BF56968960e2E567B49D0BED': [
    {
      inputs: [
        {
          internalType: 'contractERC20Mintable',
          name: '_sushi',
          type: 'address'
        },
        { internalType: 'address', name: '_devaddr', type: 'address' },
        { internalType: 'address', name: '_acsACS', type: 'address' },
        { internalType: 'uint256', name: '_sushiPerBlock', type: 'uint256' },
        { internalType: 'uint256', name: '_startBlock', type: 'uint256' },
        { internalType: 'uint256', name: '_bonusEndBlock', type: 'uint256' }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'pid',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Deposit',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'pid',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'EmergencyWithdraw',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'pid',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Withdraw',
      type: 'event'
    },
    {
      inputs: [],
      name: 'BONUS_MULTIPLIER',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'REWARD_DENOMINATOR',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'acsACS',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'acsACSReward',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'bonusEndBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'devReward',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'devaddr',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'poolInfo',
      outputs: [
        { internalType: 'contractIERC20', name: 'lpToken', type: 'address' },
        { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
        { internalType: 'uint256', name: 'lastRewardBlock', type: 'uint256' },
        { internalType: 'uint256', name: 'accSushiPerShare', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'startBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'sushi',
      outputs: [
        { internalType: 'contractERC20Mintable', name: '', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'sushiPerBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'totalAllocPoint',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'address', name: '', type: 'address' }
      ],
      name: 'userInfo',
      outputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardDebt', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'withdrawalFee',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'poolLength',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
        { internalType: 'contractIERC20', name: '_lpToken', type: 'address' },
        { internalType: 'bool', name: '_withUpdate', type: 'bool' }
      ],
      name: 'add',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
        { internalType: 'bool', name: '_withUpdate', type: 'bool' }
      ],
      name: 'set',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_from', type: 'uint256' },
        { internalType: 'uint256', name: '_to', type: 'uint256' }
      ],
      name: 'getMultiplier',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'address', name: '_user', type: 'address' }
      ],
      name: 'pendingSushi',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'massUpdatePools',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
      name: 'updatePool',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'deposit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'withdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
      name: 'emergencyWithdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_devaddr', type: 'address' }],
      name: 'dev',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_sushiPerBlock', type: 'uint256' }
      ],
      name: 'setSushiPerBlock',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_acsACS', type: 'address' }],
      name: 'setAcsACS',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' }
      ],
      name: 'setWithdrawalFee',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_devReward', type: 'uint256' }
      ],
      name: 'setDevReward',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_acsACSReward', type: 'uint256' }
      ],
      name: 'setAcsACSReward',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  '0x96c8390BA28eB083A784280227C37b853bc408B7': [
    {
      inputs: [
        {
          internalType: 'contractERC20Mintable',
          name: '_sushi',
          type: 'address'
        },
        { internalType: 'address', name: '_devaddr', type: 'address' },
        { internalType: 'address', name: '_acsACS', type: 'address' },
        { internalType: 'address', name: '_vault', type: 'address' },
        { internalType: 'uint256', name: '_sushiPerBlock', type: 'uint256' },
        { internalType: 'uint256', name: '_startBlock', type: 'uint256' },
        { internalType: 'uint256', name: '_bonusEndBlock', type: 'uint256' }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'pid',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Deposit',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'pid',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'EmergencyWithdraw',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'pid',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Withdraw',
      type: 'event'
    },
    {
      inputs: [],
      name: 'BONUS_MULTIPLIER',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'REWARD_DENOMINATOR',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'acsACS',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'acsACSReward',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'bonusEndBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'devReward',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'devaddr',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'disableHarvest',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'poolInfo',
      outputs: [
        { internalType: 'contractIERC20', name: 'lpToken', type: 'address' },
        { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
        { internalType: 'uint256', name: 'lastRewardBlock', type: 'uint256' },
        { internalType: 'uint256', name: 'accSushiPerShare', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'startBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'sushi',
      outputs: [
        { internalType: 'contractERC20Mintable', name: '', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'sushiPerBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'totalAllocPoint',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'address', name: '', type: 'address' }
      ],
      name: 'userInfo',
      outputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardDebt', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardCredit', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'vault',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'withdrawalFee',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'poolLength',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
        { internalType: 'contractIERC20', name: '_lpToken', type: 'address' },
        { internalType: 'bool', name: '_withUpdate', type: 'bool' }
      ],
      name: 'add',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
        { internalType: 'bool', name: '_withUpdate', type: 'bool' }
      ],
      name: 'set',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_from', type: 'uint256' },
        { internalType: 'uint256', name: '_to', type: 'uint256' }
      ],
      name: 'getMultiplier',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'address', name: '_user', type: 'address' }
      ],
      name: 'pendingSushi',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'massUpdatePools',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
      name: 'updatePool',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'deposit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'withdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
      name: 'harvest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
      name: 'harvestToVault',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
      name: 'emergencyWithdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_devaddr', type: 'address' }],
      name: 'dev',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_sushiPerBlock', type: 'uint256' }
      ],
      name: 'setSushiPerBlock',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_acsACS', type: 'address' }],
      name: 'setAcsACS',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_vault', type: 'address' }],
      name: 'setVault',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bool', name: '_disableHarvest', type: 'bool' }],
      name: 'setDisableHarvest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' }
      ],
      name: 'setWithdrawalFee',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_devReward', type: 'uint256' }
      ],
      name: 'setDevReward',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_acsACSReward', type: 'uint256' }
      ],
      name: 'setAcsACSReward',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  '0xb1fa5d3c0111d8E9ac43A19ef17b281D5D4b474E': [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'lpToken',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Deposit',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'lpToken',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Withdraw',
      type: 'event'
    },
    {
      inputs: [],
      name: 'REWARD_DENOMINATOR',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        {
          components: [
            { internalType: 'address', name: 'rewardToken', type: 'address' },
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'uint256', name: 'rewardPerBlock', type: 'uint256' }
          ],
          internalType: 'struct ACryptoSFarmV3.AdditionalPoolReward[]',
          name: '_additionalPoolRewards',
          type: 'tuple[]'
        }
      ],
      name: 'addAdditionalPoolRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'reward', type: 'uint256' }
          ],
          internalType: 'struct ACryptoSFarmV3.AdditionalReward[]',
          name: '_additionalRewards',
          type: 'tuple[]'
        }
      ],
      name: 'addAdditionalRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' }
      ],
      name: 'additionalPoolRewards',
      outputs: [
        { internalType: 'address', name: 'rewardToken', type: 'address' },
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'uint256', name: 'rewardPerBlock', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'additionalPoolRewardsLength',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'additionalRewards',
      outputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'reward', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'boostFactor',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'boostToken',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'address', name: '_user', type: 'address' }
      ],
      name: 'calculateWeight',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'deleteAdditionalPoolRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'deleteAdditionalRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'deposit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'harvest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'harvestFee',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'harvestFeeAddress',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'maxBoost',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'address', name: '_user', type: 'address' }
      ],
      name: 'pendingSushi',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'poolInfo',
      outputs: [
        { internalType: 'uint256', name: 'totalWeight', type: 'uint256' },
        { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
        { internalType: 'uint256', name: 'lastRewardBlock', type: 'uint256' },
        { internalType: 'uint256', name: 'accSushiPerShare', type: 'uint256' },
        { internalType: 'uint256', name: 'withdrawalFee', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
        { internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' }
      ],
      name: 'set',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_boostFactor', type: 'uint256' }
      ],
      name: 'setBoostFactor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_harvestFee', type: 'uint256' }
      ],
      name: 'setHarvestFee',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_harvestFeeAddress', type: 'address' }
      ],
      name: 'setHarvestFeeAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_maxBoost', type: 'uint256' }],
      name: 'setMaxBoost',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_strategist', type: 'address' }
      ],
      name: 'setStrategist',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_sushiPerBlock', type: 'uint256' }
      ],
      name: 'setSushiPerBlock',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'strategist',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'sushi',
      outputs: [
        { internalType: 'contract ERC20Mintable', name: '', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'sushiPerBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'totalAllocPoint',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'updatePool',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' }
      ],
      name: 'userInfo',
      outputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'uint256', name: 'weight', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardDebt', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardCredit', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'withdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  '0x0C3B6058c25205345b8f22578B27065a7506671C': [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'lpToken',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Deposit',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'lpToken',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Withdraw',
      type: 'event'
    },
    {
      inputs: [],
      name: 'REWARD_DENOMINATOR',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        {
          components: [
            { internalType: 'address', name: 'rewardToken', type: 'address' },
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'uint256', name: 'rewardPerBlock', type: 'uint256' }
          ],
          internalType: 'struct ACryptoSFarmV3.AdditionalPoolReward[]',
          name: '_additionalPoolRewards',
          type: 'tuple[]'
        }
      ],
      name: 'addAdditionalPoolRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'reward', type: 'uint256' }
          ],
          internalType: 'struct ACryptoSFarmV3.AdditionalReward[]',
          name: '_additionalRewards',
          type: 'tuple[]'
        }
      ],
      name: 'addAdditionalRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' }
      ],
      name: 'additionalPoolRewards',
      outputs: [
        { internalType: 'address', name: 'rewardToken', type: 'address' },
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'uint256', name: 'rewardPerBlock', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'additionalPoolRewardsLength',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'additionalRewards',
      outputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'reward', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'boostFactor',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'boostToken',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'address', name: '_user', type: 'address' }
      ],
      name: 'calculateWeight',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'deleteAdditionalPoolRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'deleteAdditionalRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'deposit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'harvest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'harvestFee',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'harvestFeeAddress',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'maxBoost',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'address', name: '_user', type: 'address' }
      ],
      name: 'pendingSushi',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'poolInfo',
      outputs: [
        { internalType: 'uint256', name: 'totalWeight', type: 'uint256' },
        { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
        { internalType: 'uint256', name: 'lastRewardBlock', type: 'uint256' },
        { internalType: 'uint256', name: 'accSushiPerShare', type: 'uint256' },
        { internalType: 'uint256', name: 'withdrawalFee', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
        { internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' }
      ],
      name: 'set',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_boostFactor', type: 'uint256' }
      ],
      name: 'setBoostFactor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_harvestFee', type: 'uint256' }
      ],
      name: 'setHarvestFee',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_harvestFeeAddress', type: 'address' }
      ],
      name: 'setHarvestFeeAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_maxBoost', type: 'uint256' }],
      name: 'setMaxBoost',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_strategist', type: 'address' }
      ],
      name: 'setStrategist',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_sushiPerBlock', type: 'uint256' }
      ],
      name: 'setSushiPerBlock',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'strategist',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'sushi',
      outputs: [
        { internalType: 'contract ERC20Mintable', name: '', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'sushiPerBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'totalAllocPoint',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'updatePool',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' }
      ],
      name: 'userInfo',
      outputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'uint256', name: 'weight', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardDebt', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardCredit', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'withdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  '0x308474E30326A1bbaA97d099A85bC12D2BBebA28': [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'lpToken',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Deposit',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'lpToken',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Withdraw',
      type: 'event'
    },
    {
      inputs: [],
      name: 'REWARD_DENOMINATOR',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        {
          components: [
            { internalType: 'address', name: 'rewardToken', type: 'address' },
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'uint256', name: 'rewardPerBlock', type: 'uint256' }
          ],
          internalType: 'struct ACryptoSFarmV5_WAV.AdditionalPoolReward[]',
          name: '_additionalPoolRewards',
          type: 'tuple[]'
        }
      ],
      name: 'addAdditionalPoolRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'reward', type: 'uint256' }
          ],
          internalType: 'struct ACryptoSFarmV5_WAV.AdditionalReward[]',
          name: '_additionalRewards',
          type: 'tuple[]'
        }
      ],
      name: 'addAdditionalRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' }
      ],
      name: 'additionalPoolRewards',
      outputs: [
        { internalType: 'address', name: 'rewardToken', type: 'address' },
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'uint256', name: 'rewardPerBlock', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'additionalPoolRewardsLength',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'additionalRewards',
      outputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'reward', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'boostFactor',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'boostToken',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'address', name: '_user', type: 'address' }
      ],
      name: 'calculateWeight',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'cutSushiPerBlock',
      outputs: [
        { internalType: 'uint256', name: '_cutSushiPerBlock', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'deleteAdditionalPoolRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'deleteAdditionalRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'deposit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'harvest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'harvestFee',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'harvestFeeAddress',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'maxBoost',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'address', name: '_user', type: 'address' }
      ],
      name: 'pendingSushi',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'poolInfo',
      outputs: [
        { internalType: 'uint256', name: 'totalWeight', type: 'uint256' },
        { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
        { internalType: 'uint256', name: 'lastRewardBlock', type: 'uint256' },
        { internalType: 'uint256', name: 'accSushiPerShare', type: 'uint256' },
        { internalType: 'uint256', name: 'withdrawalFee', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
        { internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' }
      ],
      name: 'set',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_boostFactor', type: 'uint256' }
      ],
      name: 'setBoostFactor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_boostToken', type: 'address' }
      ],
      name: 'setBoostToken',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_harvestFee', type: 'uint256' }
      ],
      name: 'setHarvestFee',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_harvestFeeAddress', type: 'address' }
      ],
      name: 'setHarvestFeeAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: '_maxBoost', type: 'uint256' }],
      name: 'setMaxBoost',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_strategist', type: 'address' }
      ],
      name: 'setStrategist',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_sushiPerBlock', type: 'uint256' }
      ],
      name: 'setSushiPerBlock',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'strategist',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'sushi',
      outputs: [
        { internalType: 'contract ERC20Mintable', name: '', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'sushiPerBlock',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'totalAllocPoint',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
      name: 'updatePool',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' }
      ],
      name: 'userInfo',
      outputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'uint256', name: 'weight', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardDebt', type: 'uint256' },
        { internalType: 'uint256', name: 'rewardCredit', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_lpToken', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'withdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ]
};

const farmMaxBoost = 2.5;

class Farm extends EventTarget {
  static masterFarms = {};

  static masterFarm(address, connect) {
    if (this.masterFarms[address]) return this.masterFarms[address];
    const masterFarm = {
      address: address,
      contract: new connect.web3.eth.Contract(masterFarmAbi[address], address)
    };

    connect.initPoller.add({
      target: masterFarm.address,
      method: () => masterFarm.contract.methods.totalAllocPoint(),
      cb: i => {
        masterFarm.totalAllocPoint = bn(i);
      }
    });
    const rewardPerBlockMethod = this.isV5Farm
      ? masterFarm.contract.methods.cutSushiPerBlock()
      : masterFarm.contract.methods.sushiPerBlock();
    connect.initPoller.add({
      target: masterFarm.address,
      method: () => rewardPerBlockMethod,
      cb: i => {
        masterFarm.rewardPerBlock = bn(i);
      }
    });
    connect.initPoller.add({
      target: masterFarm.address,
      method: () => masterFarm.contract.methods.sushi(),
      cb: i => {
        masterFarm.rewardToken = i;
        masterFarm.rewardTokenSymbol = Config.tokens[i].symbol;
      }
    });

    return (this.masterFarms[address] = masterFarm);
  }

  constructor(i, connect) {
    super();

    this.connect = connect;
    this.masterFarm = Farm.masterFarm(i.master, connect);

    for (const k in i) {
      this[k] = i[k];
    }
    this.stats = {};

    this.isV2Farm =
      this.master === Config.acsMasterFarmV2 ||
      this.master === Config.acsiMasterFarmV2b ||
      this.master === Config.wavFarm;
    this.isV5Farm = this.master === Config.wavFarm;

    if (this.isV2Farm) {
      this.tokenContract = new this.connect.web3.eth.Contract(
        Config.abis.erc20,
        this.token
      );
      this.connect.initPoller.add({
        target: this.masterFarm.address,
        method: () => this.masterFarm.contract.methods.poolInfo(this.token),
        // uint256 totalWeight;      // Total weight of LP tokens users have provided.
        // uint256 allocPoint;       // How many allocation points assigned to this pool. SUSHIs to distribute per block.
        // uint256 lastRewardBlock;  // Last block number that SUSHIs distribution occurs.
        // uint256 accSushiPerShare; // Accumulated SUSHIs per share, times 1e12. See below.
        cb: i => {
          this.totalWeight = bn(i[0]);
          this.allocPoint = bn(i[1]); // allocPoint
        }
      });
      this.connect.initPoller.add({
        target: this.masterFarm.address,
        method: () =>
          this.masterFarm.contract.methods.additionalPoolRewardsLength(
            this.token
          ),
        cb: i => {
          this.additionalPoolRewardsLength = Number(i);
          // console.log('additionalPoolRewardsLength', i)
          if (this.additionalPoolRewardsLength > 0) {
            this.additionalPoolRewards = [];
            for (
              let index = 0;
              index < this.additionalPoolRewardsLength;
              index++
            ) {
              this.connect.initPoller.add({
                target: this.masterFarm.address,
                method: () =>
                  this.masterFarm.contract.methods.additionalPoolRewards(
                    this.token,
                    index
                  ),
                cb: i => {
                  // console.log(i)
                  this.additionalPoolRewards[index] = {
                    rewardToken: i.rewardToken,
                    from: i.from,
                    rewardPerBlock: bn(i.rewardPerBlock)
                  };

                  this._initAdditioanlPoolReward(
                    this.additionalPoolRewards[index]
                  );
                }
              });
            }
          }
        }
      });
    } else {
      this.connect.initPoller.add({
        target: this.masterFarm.address,
        method: () => this.masterFarm.contract.methods.poolInfo(this.pid),
        cb: i => {
          // console.log([this.tokenSymbol,this.pid,i[0],i[1]])
          this.allocPoint = bn(i[1]); // allocPoint
          this.token = i[0];
          this.tokenContract = new this.connect.web3.eth.Contract(
            Config.abis.erc20,
            this.token
          );
        }
      });
    }

    this.connect.initPoller.addEventListener('poll', () => this.init(), {
      once: true
    });
    this.connect.poller.addEventListener('poll', () => this.update());
  }

  _initAdditioanlPoolReward(additionalPoolReward) {
    const rewardTokenContract = new this.connect.web3.eth.Contract(
      Config.abis.erc20,
      additionalPoolReward.rewardToken
    );

    this.connect.initPoller.add({
      target: additionalPoolReward.rewardToken,
      method: () =>
        rewardTokenContract.methods.balanceOf(additionalPoolReward.from),
      cb: i => {
        additionalPoolReward.fromBalance = bn(i);
      }
    });

    this.connect.initPoller.add({
      target: additionalPoolReward.rewardToken,
      method: () =>
        rewardTokenContract.methods.allowance(
          additionalPoolReward.from,
          this.masterFarm.address
        ),
      cb: i => {
        additionalPoolReward.fromAllowance = bn(i);
        // console.log(additionalPoolReward.fromBalance.toString(), additionalPoolReward.fromAllowance.toString())
        if (
          additionalPoolReward.fromBalance.lten(0) ||
          additionalPoolReward.fromAllowance.lten(0)
        ) {
          additionalPoolReward.rewardPerBlock = bn(0);
        }
      }
    });
  }

  init() {
    this.connect.poller.add({
      target: this.token,
      method: () =>
        this.tokenContract.methods.balanceOf(this.masterFarm.address),
      cb: i => (this.stats.farmBalance = bn(i))
    });
    this.connect.poller.add({
      target: this.token,
      method: () =>
        this.connect.account &&
        this.tokenContract.methods.balanceOf(this.connect.account),
      cb: i => (this.stats.userBalance = bn(i))
    });
    if (this.isV2Farm) {
      this.connect.poller.add({
        target: this.master,
        method: () =>
          this.connect.account &&
          this.masterFarm.contract.methods.userInfo(
            this.token,
            this.connect.account
          ),
        // uint256 amount;     // How many LP tokens the user has provided.
        // uint256 weight;     // Weight of LP tokens the user has provided.
        // uint256 rewardDebt; // Reward debt. See explanation below.
        // uint256 rewardCredit;
        cb: i => {
          this.stats.userBalanceFarm = bn(i[0]);
          this.stats.userWeight = bn(i[1]);
        }
      });
      this.connect.poller.add({
        target: this.master,
        method: () =>
          this.connect.account &&
          this.masterFarm.contract.methods.calculateWeight(
            this.token,
            this.connect.account
          ),
        cb: i => (this.stats.calculateWeight = bn(i))
      });
      this.connect.poller.add({
        target: this.master,
        method: () =>
          this.connect.account &&
          this.masterFarm.contract.methods.pendingSushi(
            this.token,
            this.connect.account
          ),
        cb: i => {
          this.stats.rewardPending = bn(i);
        }
      });
    } else {
      this.connect.poller.add({
        target: this.master,
        method: () =>
          this.connect.account &&
          this.masterFarm.contract.methods.userInfo(
            this.pid,
            this.connect.account
          ),
        cb: i => (this.stats.userBalanceFarm = bn(i[0]))
      });
      this.connect.poller.add({
        target: this.master,
        method: () =>
          this.connect.account &&
          this.masterFarm.contract.methods.pendingSushi(
            this.pid,
            this.connect.account
          ),
        cb: i => (this.stats.rewardPending = bn(i))
      });
    }
  }

  async update() {
    if (!this.tokenContract) return;

    let tokenToUsd = quoter.q(this.token, busd);
    let tokenToBnb = quoter.q(this.token, wbnb);
    if (!tokenToBnb) {
      if (this.vault && this.vault.stats.pricePerFullShare) {
        const vaultTokenToBnb = quoter.q(this.vault.token, wbnb);
        const vaultTokenToUsd = quoter.q(this.vault.token, busd);
        if (vaultTokenToBnb) {
          tokenToBnb = this.vault.stats.pricePerFullShare
            .mul(vaultTokenToBnb)
            .div(bn(1e18));
          tokenToUsd = this.vault.stats.pricePerFullShare
            .mul(vaultTokenToUsd)
            .div(bn(1e18));
        }
      } else if (StableSwap.stableSwapsByLpToken[this.token]) {
        if (StableSwap.stableSwapsByLpToken[this.token].baseSymbol == 'BTC') {
          tokenToBnb = StableSwap.stableSwapsByLpToken[this.token].virtualPrice
            ?.mul(quoter.q(btcb, wbnb))
            .div(bn(1e18));
          tokenToUsd = StableSwap.stableSwapsByLpToken[this.token].virtualPrice
            ?.mul(quoter.q(btcb, busd))
            .div(bn(1e18));
        } else {
          tokenToUsd = StableSwap.stableSwapsByLpToken[this.token].virtualPrice;
          tokenToBnb = tokenToUsd?.mul(quoter.q(busd, wbnb)).div(bn(1e18));
        }
      }
    }

    if (this.connect.account) {
      if (this.vault && this.vault.stats.pricePerFullShare) {
        this.stats.userBalanceFarmInVaultToken = this.stats.userBalanceFarm
          ?.mul(this.vault.stats.pricePerFullShare)
          .div(bn(1e18));
        this.stats.userBalanceFarmInVaultTokenNormalized = this.vault.applyTokenDecimals(
          this.stats.userBalanceFarmInVaultToken || bn(0)
        );
      }
      if (tokenToUsd) {
        this.stats.userBalanceFarmUsd = this.stats.userBalanceFarm
          ?.mul(tokenToUsd)
          .div(bn(1e18));
        this.stats.userBalanceUsd = this.stats.userBalance
          ?.mul(tokenToUsd)
          .div(bn(1e18));
      }
    } else {
      this.stats.userBalance = null;
      this.stats.userBalanceFarm = null;
      this.stats.userBalanceFarmInVaultToken = null;
      this.stats.userBalanceFarmInVaultTokenNormalized = null;
      this.stats.userBalanceFarmUsd = null;
      this.stats.userBalanceUsd = null;
      this.stats.userWeight = null;
    }
    // console.log(this.tokenSymbol,this.token,quoter.q(this.token,wbnb))
    if (tokenToUsd) {
      this.stats.farmBalanceBnb = this.stats.farmBalance
        ?.mul(tokenToBnb)
        .div(bn(1e18));
      this.stats.farmBalanceUsd = this.stats.farmBalance
        ?.mul(tokenToUsd)
        .div(bn(1e18));
    }
    if (
      quoter.q(this.masterFarm.rewardToken, wbnb) &&
      this.stats.farmBalanceBnb &&
      this.stats.farmBalanceBnb.gtn(0)
    ) {
      this.stats.rewardPerDay = this.masterFarm.rewardPerBlock
        .muln(28800)
        .mul(this.allocPoint)
        .div(this.masterFarm.totalAllocPoint);
      // console.log(this.tokenSymbol, fwp(this.stats.rewardPerDay))
      this.stats.roiDay = parseFloat(
        fw(
          this.stats.rewardPerDay
            .mul(quoter.q(this.masterFarm.rewardToken, wbnb))
            .div(this.stats.farmBalanceBnb)
        )
      );

      this.additionalPoolRewards &&
        this.additionalPoolRewards.forEach(additionalPoolReward => {
          const toBnb = quoter.q(additionalPoolReward.rewardToken, wbnb);
          const rewardPerDay = additionalPoolReward.rewardPerBlock.muln(28800);
          const roiDay = parseFloat(
            fw(rewardPerDay.mul(toBnb).div(this.stats.farmBalanceBnb))
          );
          this.stats.roiDay += roiDay;
        });

      this.stats.apyDay = (this.stats.roiDay + 1) ** 365 - 1;
      this.stats.aprDay = this.stats.roiDay * 365;
    }

    if (this.totalWeight && !this.deprecated) {
      if (this.stats.roiDay) {
        if (this.totalWeight.gtn(0)) {
          const totalBoost = parseFloat(
            fw(this.stats.farmBalance.mul(bn(1e18)).div(this.totalWeight))
          );
          this.stats.roiDayMin = this.stats.roiDay * totalBoost;
          this.stats.roiDayMax = this.stats.roiDayMin * farmMaxBoost;
        }
        this.stats.apyDayMin = (this.stats.roiDayMin + 1) ** 365 - 1;
        this.stats.aprDayMin = this.stats.roiDayMin * 365;
        this.stats.apyDayMax = (this.stats.roiDayMax + 1) ** 365 - 1;
        this.stats.aprDayMax = this.stats.roiDayMax * 365;
      }
      const boostTokenVault =
        (this.master === Config.acsMasterFarmV2 && Farm.acsVault) ||
        (this.master === Config.acsiMasterFarmV2b && Farm.acsiVault) ||
        (this.master === Config.wavFarm && Farm.wavVault);
      if (boostTokenVault.stats.userBalanceVaultInToken) {
        const boostTokenVaultShare = boostTokenVault.stats.userBalanceVaultInToken
          .mul(bn(1e18))
          .div(boostTokenVault.stats.vaultBalance);
        this.stats.maxBoostOnFirst = boostTokenVaultShare
          .mul(this.stats.farmBalance)
          .div(bn(1e18));
        this.stats.maxBoostOnFirstUsd =
          tokenToUsd &&
          this.stats.maxBoostOnFirst.mul(tokenToUsd).div(bn(1e18));
        if (this.vault && this.vault.stats.pricePerFullShare) {
          this.stats.maxBoostOnFirstInVaultToken = this.stats.maxBoostOnFirst
            .mul(this.vault.stats.pricePerFullShare)
            .div(bn(1e18));
        }
      }
      if (this.stats.userWeight && this.stats.userBalanceFarm.gtn(0)) {
        this.stats.currentBoostX = this.stats.userWeight
          .mul(bn(1e18))
          .div(this.stats.userBalanceFarm);
        this.stats.futureBoostX = this.stats.calculateWeight
          .mul(bn(1e18))
          .div(this.stats.userBalanceFarm);
        this.stats.maxBoostAcsNeeded = this.stats.userBalanceFarm
          .mul(boostTokenVault.stats.vaultBalance)
          .div(this.stats.farmBalance);

        this.stats.currentBoost = this.stats.userWeight.sub(
          this.stats.userBalanceFarm
        );
        this.stats.futureBoost = this.stats.calculateWeight.sub(
          this.stats.userBalanceFarm
        );
        if (this.vault && this.vault.stats.pricePerFullShare) {
          this.stats.currentBoostInVaultToken = this.stats.currentBoost
            .mul(this.vault.stats.pricePerFullShare)
            .div(bn(1e18));
          this.stats.futureBoostInVaultToken = this.stats.futureBoost
            .mul(this.vault.stats.pricePerFullShare)
            .div(bn(1e18));
        }

        if (this.stats.roiDayMin) {
          this.stats.roiDayBoosted =
            this.stats.roiDayMin * parseFloat(fw(this.stats.currentBoostX));
          this.stats.apyDayBoosted = (this.stats.roiDayBoosted + 1) ** 365 - 1;
          this.stats.aprDayBoosted = this.stats.roiDayBoosted * 365;
        }
      }
    }

    if (this.stats.rewardPending) {
      const toUsd = quoter.q(this.masterFarm.rewardToken, busd);
      if (toUsd)
        this.stats.rewardPendingUsd = this.stats.rewardPending
          .mul(toUsd)
          .div(bn(1e18));

      this.additionalPoolRewards &&
        this.additionalPoolRewards.forEach(additionalPoolReward => {
          additionalPoolReward.rewardPending = this.stats.rewardPending
            .mul(additionalPoolReward.rewardPerBlock)
            .div(
              this.masterFarm.rewardPerBlock
                .mul(this.allocPoint)
                .div(this.masterFarm.totalAllocPoint)
            );

          const toUsd = quoter.q(additionalPoolReward.rewardToken, busd);
          if (toUsd)
            additionalPoolReward.rewardPendingUsd = additionalPoolReward.rewardPending
              .mul(toUsd)
              .div(bn(1e18));
        });
    }

    this.dispatchEvent(new CustomEvent('update'));
  }

  async harvest() {
    if (this.isV2Farm) {
      return this.connect.send(
        this.masterFarm.contract.methods.harvest(this.token)
      );
    } else if (this.master == Config.acsMasterFarm) {
      return this.connect.send(
        this.masterFarm.contract.methods.deposit(this.pid, 0)
      );
    } else {
      return this.connect.send(
        this.masterFarm.contract.methods.harvest(this.pid)
      );
    }
  }

  async deposit(amount) {
    const allowance = bn(
      await this.tokenContract.methods
        .allowance(this.connect.account, this.masterFarm.address)
        .call()
    );
    // console.log(fw(allowance),fw(amount),allowance.lt(amount))
    if (allowance.lt(amount)) {
      await this.connect.send(
        this.tokenContract.methods.approve(this.masterFarm.address, maxuint),
        {},
        true
      );
    }
    if (this.isV2Farm) {
      return this.connect.send(
        this.masterFarm.contract.methods.deposit(this.token, amount)
      );
    } else {
      return this.connect.send(
        this.masterFarm.contract.methods.deposit(this.pid, amount)
      );
    }
  }

  async depositInVaultToken(amountInVaultToken) {
    const amount = amountInVaultToken.eq(
      this.vault.stats.userBalanceVaultInToken
    )
      ? this.vault.stats.userBalanceVault
      : amountInVaultToken
          .mul(bn(1e18))
          .div(this.vault.stats.pricePerFullShare);

    return this.deposit(amount);
  }

  async withdrawInVaultToken(amountInVaultToken) {
    const amount = amountInVaultToken.eq(this.stats.userBalanceFarmInVaultToken)
      ? this.stats.userBalanceFarm
      : amountInVaultToken
          .mul(bn(1e18))
          .div(this.vault.stats.pricePerFullShare);

    return this.withdraw(amount);
  }

  async depositAll() {
    const amount = bn(
      await this.tokenContract.methods.balanceOf(this.connect.account).call()
    );

    return this.deposit(amount);
  }

  async withdraw(amount) {
    if (this.isV2Farm) {
      return this.connect.send(
        this.masterFarm.contract.methods.withdraw(this.token, amount)
      );
    } else {
      return this.connect.send(
        this.masterFarm.contract.methods.withdraw(this.pid, amount)
      );
    }
  }
}
