import { ref, onMounted, computed } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import useWeb3 from './web3/useWeb3';
import useVaults from './useVaults';
import useMultiCall from './useMultiCall';
import addresses from '@/constants/addresses';
import { TGW_NFT_BASE_URI, NftType } from '@/constants';
import abis from '@/constants/abis';
import { Call, NFT } from '@/constants/types';
import { BN, bn, tw, fw } from '@/lib/utils/bn';
import { useRoute } from 'vue-router';
import { NFT_DEFAULT_TYPE } from '@/constants';

const isInited = ref(false as boolean);
const calls = ref([] as Call[]);
const allNfts = ref([] as NFT[]);
const totalSupply = ref(0 as number);
// const userAllowance = ref(bn(0) as typeof BN);
const pricePerFullShare = ref(bn(0) as typeof BN);
const minStakeNative = ref(bn(0) as typeof BN);
const typeMintFees = ref([] as typeof BN[]);
const userBnbBalance = ref(bn(0) as typeof BN);
const nftTypesData = ref({});

export default function useNfts() {
  const toast = useToast();
  const { t } = useI18n();
  const { acsStats } = useVaults();
  const { account, connector, connectWallet } = useWeb3();
  const { multiCall, multiCallContract } = useMultiCall();
  const route = useRoute();

  // COMPUTED
  const nftContract = computed(() =>
    connector.value.web3
      ? new connector.value.web3.eth.Contract(abis.nft, addresses.tgwNft)
      : null
  );
  // const wavContract = computed(() =>
  //   connector.value.web3
  //     ? new connector.value.web3.eth.Contract(abis.erc20, addresses.wav)
  //     : null
  // );
  const tgwVaultContract = computed(() =>
    connector.value.web3
      ? new connector.value.web3.eth.Contract(abis.vault, addresses.tgwVault)
      : null
  );
  const minBnbAmount = computed(() =>
    minStakeNative.value && typeMintFees.value.length
      ? minStakeNative.value.iadd(
          typeMintFees.value.reduce(
            (sum, item) => sum.iadd(item || bn(0)),
            bn(0)
          )
        )
      : bn(0)
  );
  const typeMintFeesDesc = computed(() =>
    minStakeNative.value && typeMintFees.value.length
      ? typeMintFees.value.reduce((sum, item) => sum.iadd(item || bn(0)), bn(0))
      : bn(0)
  );

  // MOUNTED
  onMounted(() => {
    if (!isInited.value) {
      isInited.value = true;
      getTypesData();

      setInterval(() => {
        const nftTypefromRoute = computed(
          () => route.query.type || NFT_DEFAULT_TYPE
        );
        const nftTypefromRouteValue = Number(nftTypefromRoute.value);
        getInitialData(nftTypefromRouteValue);
      }, 5000);
    }
  });

  // METHODS
  const stakeMint = async (stakeMintAmount, nftType) => {
    try {
      if (!account.value) {
        await connectWallet();
        getInitialData(nftType);
        return false;
      }
      if (!stakeMintAmount || stakeMintAmount < fw(minBnbAmount.value)) {
        toast.warning(t('alert_input_correct_amount'));
        return;
      }
      if (fw(userBnbBalance.value) < stakeMintAmount) {
        toast.warning(t('alert_no_enough_balance'));
        return;
      }
      if (!nftContract.value) {
        toast.error(t('alert_stake_mint_error'));
        return false;
      }
      // if (userAllowance.value.lt(bn(tw(`${amount}`)))) {
      //   await connector.value.send(
      //     wavContract.value.methods.approve(addresses.tgwNft, Config.maxuint)
      //   );
      // }
      const res: any = await connector.value.send(
        nftContract.value.methods.mintWithTypeStake(account.value, nftType, 0),
        {
          value: tw(`${stakeMintAmount}`)
        }
      );
      const tokenId = res.events['Transfer'].returnValues.tokenId;
      const metadata = await getMetadata(tokenId, nftType);

      getInitialData(nftType);
      return {
        image: metadata.image,
        transactionHash: res.transactionHash
      };
    } catch (error) {
      console.error(error);
      toast.error(t('alert_stake_mint_error'));
      return false;
    }
  };

  const getMetadata = async (tokenId, nftType) => {
    const tokenUri =
      nftType === NftType.FOUNDER_NFT
        ? `${TGW_NFT_BASE_URI}/${tokenId}`
        : `${TGW_NFT_BASE_URI}/${tokenId}-${nftType}`;
    const response = await fetch(tokenUri);
    const metadata = await response.json();
    return metadata;
  };

  const getInitialData = async nftTypefromRoute => {
    allNfts.value = allNfts.value.map(nft => {
      if (nft.stakedTgwBN) {
        const stakedWvp = parseFloat(
          fw(nft.stakedTgwBN.mul(pricePerFullShare.value).div(bn(1e18)))
        );
        const stakedTgw = parseFloat(fw(nft.stakedTgwBN));
        // @ts-ignore
        const wvpPriceUsd = acsStats.value?.wvpPriceUsd || '0';
        const stakedWvpUsd = stakedWvp * Number.parseFloat(wvpPriceUsd);

        return {
          ...nft,
          stakedWvp,
          stakedWvpUsd,
          stakedTgw
        };
      }
      return nft;
    });

    calls.value = [];
    calls.value.push({
      target: addresses.tgwVault,
      method: () => tgwVaultContract.value?.methods.getPricePerFullShare(),
      cb: i => {
        pricePerFullShare.value = bn(i);
      }
    });

    calls.value.push({
      target: addresses.tgwNft,
      method: () => nftContract.value?.methods.totalSupply(),
      cb: async i => {
        const newTotalSupply = Number.parseInt(i);
        if (totalSupply.value < newTotalSupply) {
          await getTokenIds(totalSupply.value, newTotalSupply);
          getNfts(totalSupply.value, newTotalSupply);
        } else if (totalSupply.value > newTotalSupply) {
          await getTokenIds(0, newTotalSupply);
          getNfts(0, newTotalSupply);
        }
        totalSupply.value = Number.parseInt(i);
      }
    });
    calls.value.push({
      target: addresses.tgwNft,
      method: () => nftContract.value?.methods.typeInfo(nftTypefromRoute),
      cb: i => (minStakeNative.value = bn(i.minStakeNative))
    });
    calls.value.push({
      target: addresses.tgwNft,
      method: () => nftContract.value?.methods.typeMintFees(nftTypefromRoute),
      cb: i =>
        i.forEach((item, index) => (typeMintFees.value[index] = bn(item.fee)))
    });

    calls.value.push({
      target: addresses.multiCall,
      method: () =>
        account.value &&
        multiCallContract.value?.methods.getEthBalance(account.value),
      cb: i => (userBnbBalance.value = bn(i))
    });

    // if (account.value && wavContract.value) {
    //   calls.value.push({
    //     target: addresses.wav,
    //     method: () =>
    //       wavContract.value.methods.allowance(
    //         account.value,
    //         addresses.tgwNft
    //       ),
    //     cb: i => {
    //       userAllowance.value = bn(i);
    //     }
    //   });
    // }

    if (!calls.value.length) {
      return;
    }
    multiCall(calls.value);
  };

  const getTokenIds = async (from, to) => {
    if (!nftContract.value) {
      return;
    }
    calls.value = [];
    for (let index = from; index < to; index++) {
      calls.value.push({
        target: addresses.tgwNft,
        method: () => nftContract.value.methods.tokenByIndex(index),
        cb: i => {
          allNfts.value[index] = {
            ...allNfts.value[index],
            id: Number(i)
          };
        }
      });
    }
    await multiCall(calls.value);
  };

  const getNfts = (from, to) => {
    if (!nftContract.value) {
      return;
    }
    calls.value = [];
    for (let index = from; index < to; index++) {
      calls.value.push({
        target: addresses.tgwNft,
        method: () =>
          nftContract.value.methods.ownerOf(allNfts.value[index].id),
        cb: i => {
          allNfts.value[index] = {
            ...allNfts.value[index],
            owner: i
          };
        }
      });

      if (!allNfts.value[index].tokenType) {
        calls.value.push({
          target: addresses.tgwNft,
          method: () =>
            nftContract.value.methods.tokenType(allNfts.value[index].id),
          cb: async i => {
            const metadata = await getMetadata(allNfts.value[index].id, i);
            const tokenUri =
              i === NftType.FOUNDER_NFT
                ? `${TGW_NFT_BASE_URI}/${allNfts.value[index].id}`
                : `${TGW_NFT_BASE_URI}/${allNfts.value[index].id}-${i}`;

            allNfts.value[index] = {
              ...metadata,
              ...allNfts.value[index],
              tokenType: i,
              tokenUri
            };
          }
        });
      }
      calls.value.push({
        target: addresses.tgwNft,
        method: () =>
          nftContract.value.methods.tokenBalanceOfStakedVault(
            allNfts.value[index].id
          ),
        cb: i => {
          allNfts.value[index] = {
            ...allNfts.value[index],
            stakedTgwBN: bn(i)
          };
        }
      });
    }
    multiCall(calls.value);
  };

  const getTypesData = () => {
    fetch(`${TGW_NFT_BASE_URI}/types/index.json`)
      .then(async r => {
        const response = await r.text();
        nftTypesData.value = JSON.parse(response);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const burn = async tokenId => {
    if (!account.value) {
      throw new Error('no connected account');
    }
    if (!nftContract.value) {
      throw new Error('no nft contract');
    }
    await connector.value.send(nftContract.value.methods.burn(tokenId));
  };

  return {
    // computed
    minBnbAmount,
    typeMintFeesDesc,
    // data
    allNfts,
    nftTypesData,
    totalSupply,
    userBnbBalance,
    // methods
    burn,
    stakeMint
  };
}
