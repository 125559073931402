<template>
  <div class="flex flex-wrap">
    <span v-for="(tag, ind) in farmVaultTags" :key="ind">
      <span
        v-if="tags[tag]"
        class="tags"
        :class="[
          tags[tag].toLowerCase(),
          tag === 'boost' || tag === 'onsen' ? '' : 'px-2 rounded-full'
        ]"
      >
        {{ tags[tag] }}
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},

  props: {
    farmVaultTags: { type: Array }
  },

  setup() {
    // DATA
    const tags = {
      core: 'Core',
      venusProtocol: 'Venus',
      channels: 'Channels',
      swipeswap: 'Swip',
      mdex: 'MDEX',
      unifi: 'Unifi',
      wav: '🌊',
      atlantis: 'Atlantis',
      dsg: 'DSG',
      biswap: 'Biswap',
      pancakeV2: 'PancakeV2',
      stableswap: 'StableSwap',
      acsfi: 'ACS',
      acsifi: 'ACSI',
      new: 'New',
      newFees: 'New Fees',
      beta: 'Beta',
      v2: 'V2',
      boost: '🔥',
      onsen: '♨️',
      test: 'Test',
      deprecated: 'Deprecated',
      upgrading: 'Upgrading',
      pancakeV1: 'PancakeV1',
      acsV1: 'AcsFarmV1',
      acsiV1: 'AcsiFarmV1'
    };

    return {
      // data
      tags
    };
  }
});
</script>
<style scoped>
.tags {
  @apply mr-2 my-1 h-3.5 lg:h-4 flex items-center text-2xs lg:text-xs font-medium;
}
.core {
  color: #5ed7ff;
  background-color: rgba(94, 215, 255, 0.2);
}
.mdex {
  color: #897aeb;
  background: #897aeb40;
}
.unifi {
  color: #897aeb;
  background: #897aeb40;
}
.pancakev2 {
  color: #e9a043;
  background: #e9a04333;
}
.venus {
  color: #5ed7ff;
  background-color: rgba(94, 215, 255, 0.2);
}
.channels {
  color: #5ed7ff;
  background-color: rgba(94, 215, 255, 0.2);
}
.new {
  color: #1aba00;
  background: #1aba0033;
}
.acs {
  color: #1aba00;
  background: #1aba0033;
}
.acsi {
  color: #897aeb;
  background: #897aeb40;
}
.swip {
  color: #1aba00;
  background: #1aba0033;
}
.test,
.deprecated {
  color: #333333;
  background: #e9a043;
}
.stableswap {
  color: #1aba00;
  background: #1aba0033;
}
.acsifarmv1 {
  color: #1aba00;
  background: #1aba0033;
}
.pancakev1 {
  color: #897aeb;
  background: #897aeb40;
}
.acsfarmv1 {
  color: #1aba00;
  background: #1aba0033;
}
.atlantis {
  color: #897aeb;
  background: #897aeb40;
}
.dsg {
  color: #897aeb;
  background: #897aeb40;
}
.biswap {
  color: #897aeb;
  background: #897aeb40;
}
</style>
