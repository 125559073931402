<template>
  <div class="vaults">
    <VaultsList :deprecated="deprecated" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import VaultsList from '@/components/vaults/VaultsList.vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  components: {
    VaultsList
  },

  setup() {
    const router = useRouter();
    const { upToLargeBreakpoint } = useBreakpoints();

    // COMPUTED
    const deprecated = computed(
      () => router.currentRoute.value.name === 'deprecated_vaults'
    );

    return {
      upToLargeBreakpoint,
      // computed
      deprecated
    };
  }
});
</script>
<style scoped>
@media (max-width: 1024px) {
  .vaults {
    margin-top: 60px;
  }
}
.vaults {
  @apply relative px-4 pt-5 lg:py-2 lg:mt-24 lg:px-6;
}
</style>
