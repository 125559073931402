<template>
  <NftViewer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NftViewer from '@/components/nftviewer/index.vue';

export default defineComponent({
  components: {
    NftViewer
  }
});
</script>
