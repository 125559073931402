import i18n from '@/plugins/i18n';
import { fw, np } from './bn';

const defaultLogo = '/images/connectors/default.svg';
const frameLogo = '/images/connectors/frame.svg';
const imtokenLogo = '/images/connectors/imtoken.svg';
const metamaskLogo = '/images/connectors/metamask.svg';
const statusLogo = '/images/connectors/status.svg';
const trustwalletLogo = '/images/connectors/trustwallet.svg';
const walletconnectLogo = '/images/connectors/walletconnect.svg';
const binanceLogo = '/images/connectors/binance.svg';

export function getConnectorName(connectorId: string): string {
  if (connectorId === 'injected') {
    const provider = window.ethereum as any;
    if (provider.isMetaMask) {
      return 'MetaMask';
    }
    if (provider.isImToken) {
      return 'imToken';
    }
    if (provider.isStatus) {
      return 'Status';
    }
    if (provider.isTrust) {
      return 'Trust Wallet';
    }
    if (provider.isFrame) {
      return 'Frame';
    }
    return i18n.global.t('browserWallet');
  }
  if (connectorId === 'fortmatic') {
    return 'Fortmatic';
  }
  if (connectorId === 'portis') {
    return 'Portis';
  }
  if (connectorId === 'walletconnect') {
    return 'WalletConnect';
  }
  if (connectorId === 'walletlink') {
    return `Coinbase ${i18n.global.t('wallet')}`;
  }
  if (connectorId === 'gnosis') {
    return 'Gnosis Safe';
  }
  if (connectorId === 'custom-binancechainwallet') {
    return 'Binance Wallet';
  }
  return i18n.global.t('unknown');
}

export function getConnectorLogo(connectorId: string): string {
  if (connectorId === 'injected') {
    const provider = window.ethereum as any;
    if (provider.isMetaMask) {
      return metamaskLogo;
    }
    if (provider.isImToken) {
      return imtokenLogo;
    }
    if (provider.isStatus) {
      return statusLogo;
    }
    if (provider.isTrust) {
      return trustwalletLogo;
    }
    if (provider.isFrame) {
      return frameLogo;
    }
    return defaultLogo;
  }
  if (connectorId === 'walletconnect') {
    return walletconnectLogo;
  }
  if (connectorId === 'custom-binancechainwallet') {
    return binanceLogo;
  }
  return defaultLogo;
}

export const showTokenBalance = i => {
  if (!i) return;
  i = parseFloat(fw(i));
  return i > 10000
    ? i.toLocaleString(undefined, { maximumFractionDigits: 2 })
    : i.toLocaleString(undefined, { maximumSignificantDigits: 5 });
};

export const showApr = i => {
  return i < 10
    ? np(i, 2)
    : i < 100
    ? np(i, 1)
    : i < 1000
    ? np(i, 0)
    : i < 10000
    ? `${np(i / 1000, 2)}K`
    : i < 100000
    ? `${np(i / 1000, 1)}K`
    : i < 1000000
    ? `${np(i / 1000, 0)}K`
    : i < 10000000
    ? `${np(i / 1000000, 2)}M`
    : i < 100000000
    ? `${np(i / 1000000, 1)}M`
    : `${np(i / 1000000, 0)}M`;
};
