<template>
  <div class="main-container grid grid-cols-4 gap-6 mt-2">
    <div
      v-if="
        farmVault.farm &&
          farmVault.farm.totalWeight &&
          !farmVault.farm.deprecated
      "
      class="boost-container"
    >
      <div class="main-label">
        {{
          farmVault.farm.masterFarm.rewardTokenSymbol === 'WAV'
            ? 'TGW'
            : farmVault.farm.masterFarm.rewardTokenSymbol
        }}
        {{ $t('boost') }}
        <!-- <BalIcon
          color="gray-250"
          filledWith="gray"
          name="help-circle"
          :size="upToLargeBreakpoint ? 'xs' : 'sm'"
        /> -->
        <BalIcon
          @click="showBoostDetail = !showBoostDetail"
          class="cursor-pointer ml-auto text-sky-420"
          :name="
            showBoostDetail
              ? 'ant-design:minus-circle-outlined'
              : 'ant-design:plus-circle-outlined'
          "
          size="sm"
        />
      </div>
      <div class="divider"></div>
      <div class="flex mt-2">
        <div class="boost-left">{{ $t('current_boost') }}:</div>
        <div class="boost-right">
          <span
            v-if="farmVault.farm.stats && farmVault.farm.stats.currentBoostX"
            class="boost-x-green"
          >
            {{ fwp(farmVault.farm.stats.currentBoostX) + 'X' }}
          </span>
          <a
            v-else
            href="https://docs.acryptos.com/acryptos-farms#acs-rewards-boost"
            target="_blank"
            class="underline text-sssm lg:text-xs"
            >{{ $t('2.5x_your_apr') }}</a
          >
        </div>
      </div>
      <div
        v-if="
          showBoostDetail &&
            farmVault.farm.stats &&
            farmVault.farm.stats.currentBoostX
        "
      >
        <div class="mt-1.5 flex">
          <div class="boost-left">{{ $t('future_boost') }}:</div>
          <div
            :class="
              fwp(farmVault.farm.stats.futureBoostX) >
              fwp(farmVault.farm.stats.currentBoostX)
                ? 'boost-green'
                : 'boost-gray'
            "
            class="boost-right"
          >
            <a @click="forceBoost">{{ $t('force') }}</a>
            <span class="ml-2">
              {{ `${fwp(farmVault.farm.stats.futureBoostX)}X` }}
            </span>
          </div>
        </div>
        <div class="mt-1.5 flex">
          <div class="boost-left">{{ $t('for') }} 2.5X:</div>
          <div class="boost-right">
            {{
              `${fwp(farmVault.farm.stats.maxBoostAcsNeeded)} ${
                farmVault.farm.masterFarm.rewardTokenSymbol === 'WAV'
                  ? 'WAV-ACS-BNB'
                  : farmVault.farm.masterFarm.rewardTokenSymbol
              }`
            }}
          </div>
        </div>
      </div>
      <div class="flex mt-1.5" v-if="showBoostDetail">
        <div class="boost-left">{{ $t('2.5x_on_first') }}:</div>
        <div class="boost-right">
          {{
            (showTokenBalance(
              farmVault.farm.stats.maxBoostOnFirstInVaultToken
                ? farmVault.farm.stats.maxBoostOnFirstInVaultToken
                : farmVault.farm.stats.maxBoostOnFirst
            ) || 0) +
              ' ' +
              (farmVault.farm.stats.maxBoostOnFirstInVaultToken
                ? farmVault.vault.tokenSymbol
                : farmVault.farm.tokenSymbol)
          }}
          {{
            farmVault.farm.stats.maxBoostOnFirstUsd
              ? ` ($${fwp(farmVault.farm.stats.maxBoostOnFirstUsd)})`
              : ''
          }}
        </div>
      </div>
      <div class="flex mt-1.5" v-if="showBoostDetail">
        <div class="boost-left">
          <a
            class="vault-detail-boost-docs"
            :href="
              `${
                farmVault.farm.masterFarm.rewardTokenSymbol === 'WAV'
                  ? 'https://docs.thegreatwave.io/tokenomics#farm-boost'
                  : 'https://docs.acryptos.com/acryptos-farms#user-interface'
              }`
            "
            target="_blank"
            >{{ $t('docs') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { fwp } from '@/lib/vault/bn';
import { showTokenBalance, triggerUpdate } from '@/lib/vault/farmVaultUtils';

export default defineComponent({
  components: {},

  props: {
    farmVault: { type: Object }
  },

  setup(props) {
    const { t } = useI18n();
    const toast = useToast();

    // DATA
    const showBoostDetail = ref(false);

    // METHOD
    const forceBoost = () => {
      try {
        props.farmVault?.farm
          .deposit(0)
          .then(() => triggerUpdate(props.farmVault));
      } catch (error) {
        console.log(error);
        toast.error(t('alert_force_new_boost_error'));
      }
    };

    return {
      fwp,
      showTokenBalance,
      // data
      showBoostDetail,
      // method
      forceBoost
    };
  }
});
</script>
<style scoped>
.boost-container {
  @apply col-span-4 3xl:col-span-3;
}
.boost-left {
  @apply pr-2 text-sssm lg:text-sm font-medium text-gray-450 leading-4;
  width: 106px;
}
.boost-right {
  @apply flex justify-end items-center font-bold text-sssm lg:text-sm leading-4 text-right;
  width: calc(100% - 106px);
}
.acs-boost-left {
  @apply pr-2 text-sssm lg:text-sm font-medium text-gray-450 leading-4;
  width: 180px;
}
.acs-boost-right {
  @apply flex justify-end items-center font-bold text-sssm lg:text-sm leading-4;
  width: calc(100% - 180px);
}
.boost-x-green,
.boost-gray span,
.boost-green span {
  @apply text-sssm lg:text-sm font-bold;
}
.boost-green a,
.boost-gray a {
  @apply flex items-center h-4 rounded-full justify-center text-xs font-normal;
  width: 48px;
}
.boost-x-green,
.boost-green span {
  @apply text-sky-420 dark:text-green-490;
}
.boost-green a {
  color: #1aba00;
  background: #1aba0033;
}
.boost-gray span {
  @apply text-gray-520 dark:text-gray-320;
}
.boost-gray a {
  @apply bg-gray-320 dark:bg-gray-520 text-gray-520 dark:text-gray-320;
}
.vault-detail-boost-docs {
  @apply text-sky-420;
}
</style>
