<template>
  <div class="mt-4">
    <div v-if="status === 'loading'" class="text-right">
      <div class="loading w-8 h-8"></div>
    </div>
    <div v-else-if="!status" class="vaults-detail-actions-main">
      <div class="vaults-detail-actions-link">
        <a
          class="vaults-detail-actions-link-item"
          target="_blank"
          :href="
            explorerLinks.addressLink(
              farmVault.vault ? farmVault.vault.address : farmVault.farm.master
            )
          "
        >
          {{ $t('view_contract') }}
          <BalIcon
            class="ml-0.5 lg:ml-1"
            name="ei:external-link"
            :size="upToLargeBreakpoint ? 'xs' : 'smd'"
          />
        </a>
        <a
          class="vaults-detail-actions-link-item"
          target="_blank"
          href="https://docs.acryptos.com/security-and-risks#aprs-returns"
        >
          {{ $t('risk_warning') }}
          <BalIcon
            class="ml-0.5 lg:ml-1"
            name="ei:external-link"
            :size="upToLargeBreakpoint ? 'xs' : 'smd'"
          />
        </a>
      </div>
      <div class="vaults-detail-actions-button">
        <BalBtn
          rounded
          color="sky-dark"
          size="sm"
          class="w-28 lg:w-36 ml-2 mt-2"
          v-for="(item, index) in walletInfoList"
          :key="index"
          @click="goTo(item.link)"
        >
          <div>
            <span class="text-sssm lg:text-sm">{{ $t('pair_unpair') }}</span>
            <br />
            <span class="text-3xs lg:text-xs">{{ item.label }} LP</span>
          </div>
        </BalBtn>
        <BalBtn
          v-for="(item, index) in visibleButtonList"
          :key="index"
          :outline="item.name === 'harvest_to_vault'"
          rounded
          color="sky-dark"
          size="sm"
          class="w-28 lg:w-36 ml-2 mt-2"
          @click="gotoStatus(item.name)"
        >
          {{ item.name === 'withdraw' ? $t('withdraw.label') : $t(item.name) }}
        </BalBtn>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-end">
        <BalBtn
          v-if="upToLargeBreakpoint && !status.startsWith('harvest')"
          outline
          rounded
          color="sky-dark"
          size="sm"
          class="w-32 mb-2"
          @click="init()"
        >
          {{ $t('cancel') }}
        </BalBtn>
      </div>
      <div class="flex justify-end">
        <div v-if="status !== 'harvest'">
          <input type="number" v-model="amount" class="action-input" />
          <div class="flex justify-between mt-2">
            <span class="text-2xs lg:text-xs font-light italic"
              >{{ $t('balance') }}: {{ getBalance() }}</span
            >
            <span v-if="isGettingMax" class="loading h-3 w-3 mr-2"></span>
            <a
              v-else
              @click="getMax"
              class="underline italic text-2xs lg:text-xs float-right mr-2"
              >{{ $t('max') }}</a
            >
          </div>
        </div>
        <div class="text-right">
          <BalBtn
            rounded
            color="sky-dark"
            size="sm"
            class="w-32 lg:w-36 ml-2 mb-2"
            @click="doAction(status)"
          >
            {{ $t(status) }}
          </BalBtn>
          <BalBtn
            v-if="!upToLargeBreakpoint || status.startsWith('harvest')"
            outline
            rounded
            color="sky-dark"
            size="sm"
            class="w-32 lg:w-36 ml-2 mb-2"
            @click="init()"
          >
            {{ $t('cancel') }}
          </BalBtn>
          <div
            v-if="status === 'unstake' || status === 'deposit'"
            class="flex items-center"
          >
            <BalToggle
              :initValue="isAll"
              @onChange="isAll = !isAll"
              size="sm"
              class="ml-auto"
            />
            <span class="ml-1 lg:ml-2 text-sssm lg:text-sm font-medium">
              {{
                status === 'unstake'
                  ? $t('and_withdraw_all')
                  : $t('and_stake_all')
              }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="
          status === 'stake' || status === 'harvest' || status === 'unstake'
        "
        class="transaction-info text-gray-450"
        v-html="farmVault.farmTransactionInfo || defaultFarmTransactionInfo"
      ></div>
      <div
        v-if="status === 'deposit' || status === 'withdraw'"
        class="transaction-info"
        :class="
          farmVault.vault?.title === 'TGW Vault'
            ? 'text-yellow-550'
            : 'text-gray-450'
        "
        v-html="farmVault.vaultTransactionInfo || defaultVaultTransactionInfo"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { bn, fw, tw } from '@/lib/vault/bn';
import { Config } from '@/lib/vault/config';
import { showTokenBalance, triggerUpdate } from '@/lib/vault/farmVaultUtils';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';
import useBuyLpModal from '@/composables/useBuyLpModal';

export default defineComponent({
  props: {
    farmVault: { type: Object }
  },

  setup(props) {
    const { t } = useI18n();
    const toast = useToast();
    const { explorerLinks } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { toggleBuyLpModal } = useBuyLpModal();

    // COMPUTED
    const defaultVaultTransactionInfo = computed(
      () =>
        `<a class="wallet-info" href="https://docs.acryptos.com/fees#all-vaults-except-acs-and-acsi-vault" target="_blank"><span>${t(
          'withdrawal_fee'
        )}</span>: 0.1%</a>`
    );

    const defaultFarmTransactionInfo = computed(
      () =>
        `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms" target="_blank"><span>${t(
          'harvest_fee'
        )}</span>: ${Config.acsHarvestFee} ACS</a>`
    );

    const buttonList = computed(() => [
      // {
      //   name: 'harvest_to_vault',
      //   isVisible: !props.farmVault?.farmRewardsEmpty
      // },
      {
        name: 'harvest',
        isVisible: !props.farmVault?.farmRewardsEmpty
      },
      {
        name: 'deposit',
        isVisible:
          !props.farmVault?.walletEmpty &&
          !(props.farmVault?.farm && props.farmVault.farm.deprecated) &&
          props.farmVault?.vault &&
          !props.farmVault.vault.showFarmDeprecatedOnly &&
          !props.farmVault.vault.deprecated
      },
      {
        name: 'stake',
        isVisible:
          props.farmVault?.farm &&
          !props.farmVault.farm.deprecated &&
          (!props.farmVault.vaultEmpty || !props.farmVault.walletFarmEmpty)
      },
      {
        name: 'unstake',
        isVisible: !props.farmVault?.farmEmpty
      },
      {
        name: 'withdraw',
        isVisible: !props.farmVault?.vaultEmpty
      }
    ]);

    const visibleButtonList = computed(() =>
      buttonList.value.filter(item => item.isVisible)
    );

    const walletInfoList = computed(() => {
      if (!props.farmVault?.walletInfo) {
        return [];
      }
      const splited = props.farmVault?.walletInfo.split('a> <a');
      let label = '';
      let labelEnd = 0;
      const labelStart = splited[0].indexOf('> ') + 2;
      if (splited.length === 1) {
        labelEnd = splited[0].indexOf('</a');
      } else {
        labelEnd = splited[0].indexOf(': <');
      }
      label = splited[0].slice(labelStart, labelEnd);

      return splited.map((item, index) => {
        const linkStart = item.indexOf('href="') + 6;
        const linkEnd = item.indexOf('" target="_blank"');
        const link = item.slice(linkStart, linkEnd);
        let reLabel = label;
        if (splited.length === 2 && index === 0) {
          reLabel = label + ' Acsi.Finance';
        }
        if (splited.length === 2 && index === 1) {
          reLabel = label + ' 1inch';
        }
        if (link.includes('app.acsi.finance')) {
          const splitedLink = link.split('/');
          poolId.value = splitedLink[splitedLink.length - 1];
        }

        return {
          label: reLabel,
          link
        };
      });
    });

    // DATA
    const status = ref('');
    const amount = ref('');
    const isAll = ref(true);
    const isGettingMax = ref(false);
    const poolId = ref('');

    // WATCHERS
    watch(
      () => props.farmVault?.id,
      () => {
        init();
      }
    );

    // METHODS
    const doAction = s => {
      if (!amount.value && s !== 'harvest') {
        toast.warning(t('alert_input_correct_amount'));
        return;
      }
      let formatedAmount = null;
      let response = null;
      status.value = 'loading';
      if (props.farmVault?.vault) {
        if (s !== 'harvest') {
          formatedAmount = props.farmVault.vault.unapplyTokenDecimals(
            bn(tw(`${amount.value}`))
          );
        }
        if (s === 'deposit') {
          response = props.farmVault.vault.deposit(formatedAmount).then(() => {
            if (props.farmVault?.farm && isAll.value) {
              props.farmVault.farm.depositAll().then(() => {
                triggerUpdate(props.farmVault).finally(() => {
                  init();
                });
              });
            } else {
              triggerUpdate(props.farmVault).finally(() => {
                init();
              });
            }
          });
        } else if (s === 'stake') {
          response = props.farmVault.farm
            .depositInVaultToken(formatedAmount)
            .then(() => {
              triggerUpdate(props.farmVault).finally(() => {
                init();
              });
            });
        } else if (s === 'unstake') {
          response = props.farmVault.farm
            .withdrawInVaultToken(formatedAmount)
            .then(() => {
              if (isAll.value) {
                triggerUpdate(props.farmVault).then(() => {
                  const withdrawAmount = props.farmVault?.vault.stats
                    .userBalanceVaultInTokenNormalized
                    ? fw(
                        props.farmVault.vault.stats
                          .userBalanceVaultInTokenNormalized
                      )
                    : 0;
                  const formatedWithdrawAmount = props.farmVault?.vault.unapplyTokenDecimals(
                    bn(tw(`${withdrawAmount}`))
                  );
                  props.farmVault?.vault
                    .withdrawInToken(formatedWithdrawAmount)
                    .then(() => {
                      triggerUpdate(props.farmVault).finally(() => {
                        init();
                      });
                    });
                });
              } else {
                triggerUpdate(props.farmVault).finally(() => {
                  init();
                });
              }
            });
        } else if (s === 'withdraw') {
          response = props.farmVault.vault
            .withdrawInToken(formatedAmount)
            .then(() => {
              triggerUpdate(props.farmVault).finally(() => {
                init();
              });
            });
        }
      } else {
        if (s !== 'harvest') {
          formatedAmount = bn(tw(`${amount.value}`));
        }
        if (s === 'unstake') {
          response = props.farmVault?.farm.withdraw(formatedAmount).then(() => {
            triggerUpdate(props.farmVault).finally(() => {
              init();
            });
          });
        } else if (s === 'stake') {
          response = props.farmVault?.farm.deposit(formatedAmount).then(() => {
            triggerUpdate(props.farmVault).finally(() => {
              init();
            });
          });
        }
      }
      if (s === 'harvest') {
        response = props.farmVault?.farm.harvest().then(() => {
          triggerUpdate(props.farmVault).finally(() => {
            init();
          });
        });
      }
      // @ts-ignore
      response.catch(error => {
        console.log(error);
        toast.error(t('alert_transaction_error'));
        init();
      });
    };

    const init = () => {
      status.value = '';
      amount.value = '';
    };

    const gotoStatus = s => {
      if (s === 'withdraw' && props.farmVault?.vault?.withdrawAlert) {
        alert(props.farmVault?.vault.withdrawAlert);
      }
      if (s === 'harvest_to_vault') {
        toast.warning(t('alert_coming_soon'));
        return;
      }
      status.value = s;
      isAll.value = true;
      if (s === 'harvest') {
        doAction(s);
      }
    };

    const getMax = () => {
      isGettingMax.value = true;
      triggerUpdate(props.farmVault)
        .then(() => {
          let temp = null;
          if (props.farmVault?.vault) {
            if (status.value === 'deposit') {
              temp = props.farmVault.vault.stats.userBalanceNormalized;
            } else if (
              status.value === 'stake' ||
              status.value === 'withdraw'
            ) {
              temp =
                props.farmVault.vault.stats.userBalanceVaultInTokenNormalized;
            } else {
              // unstake
              temp =
                props.farmVault.farm.stats
                  .userBalanceFarmInVaultTokenNormalized;
            }
          } else {
            if (status.value === 'stake') {
              temp = props.farmVault?.farm.stats.userBalance;
            } else {
              // unstake
              temp = props.farmVault?.farm.stats.userBalanceFarm;
            }
          }
          amount.value = temp ? fw(temp) : 0;
        })
        .catch(error => {
          console.log(error);
          toast.error(t('alert_get_max_error'));
        })
        .finally(() => {
          isGettingMax.value = false;
        });
    };

    const getBalance = () => {
      if (status.value === 'deposit') {
        return showTokenBalance(
          props.farmVault?.vault.stats.userBalanceNormalized
        );
      } else if (status.value === 'stake' || status.value === 'withdraw') {
        return props.farmVault?.vault
          ? showTokenBalance(
              props.farmVault.vault.stats.userBalanceVaultInTokenNormalized
            )
          : showTokenBalance(props.farmVault?.farm.stats.userBalance);
      } else {
        // unstake
        return showTokenBalance(
          props.farmVault?.farm.stats &&
            props.farmVault.farm.stats.userBalanceFarmInVaultTokenNormalized
            ? props.farmVault.farm.stats.userBalanceFarmInVaultTokenNormalized
            : props.farmVault?.farm.stats.userBalanceFarm
        );
      }
    };

    const goTo = link => {
      if (link.includes('app.acsi.finance')) {
        toggleBuyLpModal(true, poolId.value);
      } else {
        window.open(link, '_blank')?.focus();
      }
    };

    return {
      explorerLinks,
      upToLargeBreakpoint,
      showTokenBalance,
      // computed
      walletInfoList,
      defaultVaultTransactionInfo,
      defaultFarmTransactionInfo,
      buttonList,
      visibleButtonList,
      // data
      poolId,
      isAll,
      isGettingMax,
      status,
      amount,
      // methods
      goTo,
      getBalance,
      gotoStatus,
      init,
      getMax,
      doAction
    };
  }
});
</script>

<style scoped>
@media (max-width: 640px) {
  .vaults-detail-actions-link-label {
    width: calc(100% - 12px);
  }
  .vaults-detail-actions-button {
    width: 100%;
  }
}
@media (min-width: 640px) {
  .vaults-detail-actions-link-item {
    max-width: 160px;
  }
  .vaults-detail-actions-button {
    width: calc(100% - 104px);
  }
}
@media (min-width: 1024px) {
  .vaults-detail-actions-link-label {
    width: calc(100% - 18px);
  }
  .vaults-detail-actions-link-item {
    max-width: 100%;
  }
  .vaults-detail-actions-button {
    width: 100%;
  }
}
@media (min-width: 1536px) {
  .vaults-detail-actions-link-item {
    max-width: 192px;
  }
  .vaults-detail-actions-button {
    width: calc(100% - 132px);
  }
}
.vaults-detail-actions-main {
  @apply sm:flex lg:block 2xl:flex justify-between items-end;
}
.vaults-detail-actions-link {
  @apply pl-4;
}
.vaults-detail-actions-link-item {
  @apply italic text-sssm lg:text-sm font-light flex;
  width: fit-content;
}
.vaults-detail-actions-link-label {
  @apply truncate;
}
.vaults-detail-actions-button {
  @apply flex justify-end flex-wrap-reverse;
}
.action-input {
  @apply bg-transparent rounded-full border-2 border-gray-130 dark:border-gray-450 px-3 h-8 w-36 box-border text-sssm lg:text-sm;
}
.transaction-info {
  @apply underline text-right mt-2 text-sssm lg:text-sm italic font-normal;
}
</style>
