
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useBreakpoints from '@/composables/useBreakpoints';
import useNfts from '@/services/useNfts';
import useVaults from '@/services/useVaults';
import { sortArray } from '@/lib/utils/index';
import { showApr } from '@/lib/utils/web3';
import { NFT_TYPE, NFT_DEFAULT_TYPE } from '@/constants';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { allNfts, totalSupply, nftTypesData } = useNfts();
    const { formatedFarmVaults } = useVaults();

    // DATA
    const sortBy = ref('rank');
    const sortOrder = ref(false);

    // COMPUTED
    const nftType = computed(() => route.query.type || NFT_DEFAULT_TYPE);
    const nftTypeData = computed(
      () => nftTypesData.value[Number(nftType.value)] || {}
    );
    const typeNfts = computed(() =>
      //sortArray(allNfts.value, 'stakedWvp', false).filter(
      // nft => nft.tokenType === nftType.value
      //)
      sortArray(allNfts.value, 'stakedTgw', false).filter(
        nft => nft.tokenType === nftType.value
      )
    );
    const showedTypeNfts = computed(() =>
      sortArray(typeNfts.value, sortBy.value, sortOrder.value)
    );

    const totalStaked = computed(() =>
      /*allNfts.value.length
        ? allNfts.value.reduce(
            (total, item) => (item.stakedWvp || 0) + total,
            0
          )
        : 0
        */
      allNfts.value.length
        ? allNfts.value.reduce(
            (total, item) => (item.stakedTgw || 0) + total,
            0
          )
        : 0
    );

    const currentApy = computed(
      () =>
        formatedFarmVaults.value.find(item => item.name === 'TGW Vault')
          ?.apyShown || '--'
    );

    // Watchers
    watch(nftTypeData, newVal => {
      if (!newVal.id) {
        router.push({
          path: '/leaderboard',
          query: { type: NFT_DEFAULT_TYPE }
        });
      }
    });

    // METHODS
    const sort = key => {
      sortBy.value = key;
      sortOrder.value = !sortOrder.value;
    };
    const gotoNftViewer = nftId => {
      router.push({ path: `/nft-viewer/${nftId}` });
    };

    return {
      showApr,
      NFT_TYPE,
      totalSupply,
      upToLargeBreakpoint,
      // computed
      currentApy,
      totalStaked,
      showedTypeNfts,
      // methods
      gotoNftViewer,
      sort
    };
  }
});
