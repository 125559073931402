
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { configService } from '@/services/config/config.service';
import InvestForm from '@/components/forms/pool_actions/InvestForm/InvestForm.vue';
import WithdrawForm from '@/components/forms/pool_actions/WithdrawForm/WithdrawForm.vue';
import TradeSettingsPopover, {
  TradeSettingsContext
} from '@/components/popovers/TradeSettingsPopover.vue';
// Composables
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
import { usePool } from '@/composables/usePool';
import { forChange } from '@/lib/utils';
import useInvestState from '@/components/forms/pool_actions/InvestForm/composables/useInvestState';
import { EXTERNAL_LINKS } from '@/constants/links';

export default defineComponent({
  emits: ['close'],

  components: {
    TradeSettingsPopover,
    InvestForm,
    WithdrawForm
  },

  props: {
    poolId: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n();
    /**
     * STATE
     */
    const { network } = configService;
    const { pool, loadingPool, transfersAllowed } = usePoolTransfers(
      props.poolId
    );
    const { isStablePhantomPool } = usePool(pool);
    const { sor, sorReady } = useInvestState();
    const tabs = [
      { value: 'invest', label: t('invest') },
      { value: 'withdraw', label: t('withdraw.label') }
    ];
    const activeTab = ref(tabs[0].value);

    /**
     * CALLBACKS
     */
    onBeforeMount(async () => {
      await forChange(loadingPool, false);

      if (pool.value && isStablePhantomPool.value) {
        // Initialise SOR for batch swap queries
        sorReady.value = await sor.fetchPools();
      } else {
        sorReady.value = true;
      }
    });
    return {
      pool,
      network,
      sorReady,
      loadingPool,
      transfersAllowed,
      TradeSettingsContext,
      EXTERNAL_LINKS,
      // data
      tabs,
      activeTab
    };
  }
});
