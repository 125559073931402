<template>
  <div class="tgw-boost">
    <div class="tgw-boost-label">{{ $t('tgw_boost') }}:</div>
    <div class="tgw-boost-divider"></div>
    <div class="tgw-boost-items-con">
      <div class="mr-10 mt-3 lg:my-3 tgw-boost-item">
        <p class="tgw-boost-item-label">{{ $t('vault_boosting_tgw') }}:</p>
        <p class="tgw-boost-item-value">
          {{
            wavVault?.stats?.vaultBoostingTgw?.gtn(0)
              ? `${showTokenBalance(
                  wavVault.stats.vaultBoostingTgw
                )} WAV-ACS-BNB`
              : '--'
          }}
        </p>
      </div>
      <div class="mr-10 mt-3 lg:my-3 tgw-boost-item">
        <p class="tgw-boost-item-label">{{ $t('nft_tgw_effective_wvp') }}:</p>
        <p class="tgw-boost-item-value">--</p>
      </div>
      <div class="mr-10 mt-3 lg:my-3 tgw-boost-item">
        <p class="tgw-boost-item-label">{{ $t('total_wvp_stake_in_nft') }}:</p>
        <p class="tgw-boost-item-value">--</p>
      </div>
      <div class="my-3 tgw-boost-item">
        <p class="tgw-boost-item-label">{{ $t('overall_boosting_tgw') }}:</p>
        <p class="tgw-boost-item-value">
          {{
            wavVault?.stats?.vaultBoostingTgw?.gtn(0)
              ? `${showTokenBalance(
                  wavVault.stats.vaultBoostingTgw
                )} WAV-ACS-BNB`
              : '--'
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import useVaults from '@/services/useVaults';
import { fwp } from '@/lib/utils/bn';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';

export default defineComponent({
  components: {},

  setup() {
    const { farmVaults } = useVaults();

    // COMPUTED
    const wavVault = computed(
      () =>
        farmVaults.value.find(
          // @ts-ignore
          farmVault => farmVault.vault && farmVault.vault.title == 'TGW Vault'
          // @ts-ignore
        )?.vault
    );

    return {
      fwp,
      showTokenBalance,
      wavVault
    };
  }
});
</script>
<style scoped>
@media (max-width: 1024px) {
  .tgw-boost-item {
    @apply w-36;
  }
}
@media (min-width: 1024px) {
  .tgw-boost-item {
    width: fit-content;
  }
}
.tgw-boost {
  @apply flex items-center bg-white dark:bg-gray-730 px-4 rounded-2xl text-sssm lg:text-sm;
  min-height: 64px;
}
.tgw-boost-label {
  @apply font-medium text-purple-650 lg:text-black dark:text-white w-16;
  min-width: 56px;
}
.tgw-boost-divider {
  @apply w-0 border border-gray-370;
  height: calc(100% - 16px);
}
.tgw-boost-items-con {
  @apply flex flex-wrap pl-6;
}
.tgw-boost-item-label {
  @apply font-normal text-gray-390;
}
.tgw-boost-item-value {
  @apply font-bold;
}
</style>
