
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import StatsCard from './StatsCard.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useWeb3 from '@/services/web3/useWeb3';
import useNfts from '@/services/useNfts';
import useVaults from '@/services/useVaults';
import { sortArray } from '@/lib/utils/index';
import { showApr } from '@/lib/utils/web3';
import { NFT_TYPE } from '@/constants';

export default defineComponent({
  components: {
    StatsCard
  },

  setup() {
    const router = useRouter();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { account } = useWeb3();
    const { allNfts, totalSupply } = useNfts();
    const { formatedFarmVaults } = useVaults();

    // DATA
    const sortBy = ref('rank');
    const sortOrder = ref(false);

    // COMPUTED
    const myNfts = computed(() =>
      //sortArray(allNfts.value, 'stakedWvp', false).filter(
      //  item => item.owner?.toLowerCase() === account.value?.toLowerCase()
      //)
      sortArray(allNfts.value, 'stakedTgw', false).filter(
        item => item.owner?.toLowerCase() === account.value?.toLowerCase()
      )
    );

    const showedMyNfts = computed(() =>
      sortArray(myNfts.value, sortBy.value, sortOrder.value)
    );

    const totalStaked = computed(() =>
      /*allNfts.value.length
        ? allNfts.value.reduce(
            (total, item) => (item.stakedWvp || 0) + total,
            0
          )
        : 0
      */
      allNfts.value.length
        ? allNfts.value.reduce(
            (total, item) => (item.stakedTgw || 0) + total,
            0
          )
        : 0
    );

    const currentApy = computed(
      () =>
        formatedFarmVaults.value.find(item => item.name === 'TGW Vault')
          ?.apyShown || '--'
    );

    // METHODS
    const sort = key => {
      sortBy.value = key;
      sortOrder.value = !sortOrder.value;
    };
    const gotoNftViewer = nftId => {
      router.push({ path: `/nft-viewer/${nftId}` });
    };

    return {
      NFT_TYPE,
      showApr,
      totalSupply,
      upToLargeBreakpoint,
      // computed
      currentApy,
      totalStaked,
      showedMyNfts,
      // methods
      gotoNftViewer,
      sort
    };
  }
});
