<template>
  <BalBtn
    v-if="connectStatus !== 'connected'"
    :loading="connectStatus === 'connecting'"
    :loading-label="$t('connecting')"
    color="sky-dark"
    :size="upToLargeBreakpoint ? 'xs' : 'sm'"
    @click="connectWallet"
    rounded
  >
    <span v-text="$t('connect_wallet')" />
  </BalBtn>
  <AppNavAccountBtn v-else />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';
import AppNavAccountBtn from './AppNavAccountBtn.vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  name: 'AppNavActions',

  components: {
    AppNavAccountBtn
  },

  setup() {
    const { connectWallet, connectStatus } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      upToLargeBreakpoint,
      connectWallet,
      connectStatus
    };
  }
});
</script>
