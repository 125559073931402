<template>
  <div class="mynfts-stats-card">
    <div class="mynfts-stats-content">
      <p class="mynfts-stats-title">
        <slot name="title" />
      </p>
      <img
        class="mynfts-stats-icon"
        src="~@/assets/images/arrow-right-sky.svg"
      />
      <div class="mynfts-stats-detail"><slot name="detail" /></div>
    </div>
    <img src="~@/assets/images/stats-wave.svg" />
  </div>
</template>

<style scoped>
.mynfts-stats-card {
  @apply bg-white bg-opacity-80 overflow-hidden;
  border-radius: 30px;
  max-width: 538px;
}
.mynfts-stats-content {
  @apply flex justify-between mt-6 lg:mt-10 px-6 items-center;
}
.mynfts-stats-title {
  @apply text-2xl lg:text-3xl font-black;
  font-family: 'Exo 2';
  color: #201a3f;
  max-width: 150px;
}
.mynfts-stats-icon {
  @apply w-3 lg:w-5 mx-1;
}
.mynfts-stats-detail {
  @apply text-black font-bold text-sm lg:text-md grid grid-cols-1 gap-4 lg:gap-6;
  font-family: 'Fira Sans';
}
</style>
