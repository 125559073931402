<template>
  <div class="my-tvl">
    <span class="my-tvl-label">{{ $t('my_tvl') }}:</span>
    <span class="my-tvl-value">
      {{
        acsStats && acsStats.userTvl && acsStats.userTvl !== '0'
          ? `$${acsStats.userTvl}`
          : '--'
      }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useVaults from '@/services/useVaults';

export default defineComponent({
  components: {},

  setup() {
    const { acsStats } = useVaults();

    return {
      acsStats
    };
  }
});
</script>
<style scoped>
.my-tvl {
  @apply flex items-center bg-white dark:bg-gray-730 px-4 rounded-2xl h-16;
  max-width: 360px;
  min-width: 288px;
}
.my-tvl-label {
  @apply font-medium text-sssm lg:text-sm text-purple-650 lg:text-black dark:text-white;
}
.my-tvl-value {
  @apply ml-6 text-lg lg:text-xl font-semibold text-green-530;
}
</style>
