<template>
  <div class="w-72">
    <div class="p-4 border-b dark:border-gray-900">
      <div class="flex justify-between items-center mb-4">
        <h5 v-text="$t('account')" class="leading-none" />
        <div>
          <BalBtn outline color="gray" size="xs" @click="disconnectWallet">
            {{ $t('disconnect') }}
          </BalBtn>
        </div>
      </div>
      <div class="flex mt-1">
        <div class="flex">
          <div class="relative">
            <Avatar :address="connector.account" size="36" />
            <div class="connector-icon-wrapper">
              <img
                :src="connectorLogo"
                class="p-0.5 w-5 h-5 absolute bottom-0 right-0 flex items-center justify-center bg-white rounded-full"
              />
            </div>
          </div>
          <div class="ml-2">
            <div class="address flex items-baseline">
              <div v-text="_shorten(connector.account)" />
              <div class="ml-3 flex">
                <BalTooltip>
                  <template v-slot:activator>
                    <BalBtn
                      circle
                      color="gray"
                      size="xs"
                      @click="copyAddress"
                      class="mr-2"
                    >
                      <BalIcon
                        v-if="copiedAddress"
                        name="akar-icons:check"
                        size="xs"
                      />
                      <BalIcon
                        v-else
                        name="fluent:clipboard-16-regular"
                        size="xs"
                      />
                    </BalBtn>
                  </template>
                  <div
                    v-text="copiedAddress ? $t('copied') : $t('copy_address')"
                    class="w-20 text-center"
                  />
                </BalTooltip>
                <BalBtn
                  circle
                  color="gray"
                  size="xs"
                  tag="a"
                  :href="explorer.addressLink(connector.account)"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BalIcon name="akar-icons:arrow-up-right" size="xs" />
                </BalBtn>
              </div>
            </div>
            <div class="text-sm">{{ connectorName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="network p-4 text-sm dark:border-gray-900 rounded-b-xl">
      <div v-text="$t('network')" />
      <div class="flex items-baseline">
        <div
          :class="['w-2 h-2 mr-1 bg-green-400 rounded-full', networkColorClass]"
        ></div>
        {{ userNetworkConfig?.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { getConnectorName, getConnectorLogo } from '@/lib/utils/web3';
import useWeb3 from '@/services/web3/useWeb3';
import Avatar from '@/components/images/Avatar.vue';

export default defineComponent({
  components: {
    Avatar
  },

  setup() {
    const {
      explorerLinks,
      connector,
      userNetworkConfig,
      disconnectWallet
    } = useWeb3();

    // DATA
    const copiedAddress = ref(false);

    // COMPUTED
    const networkColorClass = computed(() => {
      switch (userNetworkConfig.value?.shortName) {
        case 'Kovan':
          return 'bg-purple-500';
        case 'Ropsten':
          return 'bg-pink-500';
        case 'Rinkeby':
          return 'bg-yellow-500';
        case 'Goerli':
          return 'bg-blue-500';
        default:
          return 'bg-green-500';
      }
    });
    const connectorName = computed(() =>
      getConnectorName(connector.value?.connectedWallet)
    );
    const connectorLogo = computed(() =>
      getConnectorLogo(connector.value?.connectedWallet)
    );

    // METHODS
    function copyAddress() {
      navigator.clipboard.writeText(connector.value.account);
      copiedAddress.value = true;

      setTimeout(() => {
        copiedAddress.value = false;
      }, 2 * 1000);
    }

    return {
      userNetworkConfig,
      connector,
      disconnectWallet,
      // data
      copiedAddress,
      // computed
      networkColorClass,
      connectorName,
      connectorLogo,
      // methods
      copyAddress,
      explorer: explorerLinks
    };
  }
});
</script>

<style scoped>
.address {
  @apply text-blue-500;
  font-variant-ligatures: no-contextual;
}
</style>
