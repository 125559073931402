<template>
  <BalLoadingBlock v-if="isLoadingData" rounded="2xl" class="h-full" />
  <div
    v-else-if="farmVault"
    class="min-h-full p-4 rounded-2xl bg-white dark:bg-gray-660"
  >
    <div class="token-name">
      <img
        :src="
          farmVault.tokenImage
            ? '/images/tokens/' + farmVault.tokenImage
            : '/images/tokens/' + farmVault.tokenSymbol.toLowerCase() + '.svg'
        "
      />
      <span>{{ farmVault.name }}</span>
    </div>
    <div class="mt-3 flex justify-between items-center">
      <Tags :farmVaultTags="farmVault.tags" />
      <span
        class="crown"
        v-if="upToLargeBreakpoint && farmVault.name === 'A2B2 (ACSI)'"
      >
        <BalIcon name="jam:crown" size="2xs" />
      </span>
    </div>
    <div class="mt-3 lg:mt-5 grid grid-cols-4 gap-2">
      <div class="detail-container">
        <div class="detail-label">{{ $t('apy') }}</div>
        <div class="detail-value">
          {{ farmVault.apyShown }}
        </div>
      </div>
      <div class="detail-container">
        <div class="detail-label">{{ $t('daily') }}</div>
        <div class="detail-value">
          {{ farmVault.dailyShown }}
        </div>
      </div>
      <div class="detail-container">
        <div class="detail-label">{{ $t('tvl') }}</div>
        <div class="detail-value">
          {{ farmVault.tvlShown }}
        </div>
      </div>
      <div class="detail-container">
        <div class="detail-label">{{ $t('balance') }}</div>
        <div
          class="detail-value"
          :class="
            farmVault.farm && !farmVault.vaultEmpty
              ? 'text-yellow-550'
              : !farmVault.walletEmpty || !farmVault.walletFarmEmpty
              ? 'text-blue-520 dark:text-green-490'
              : ''
          "
        >
          {{ farmVault.balanceShown }}
        </div>
      </div>
    </div>
    <ActionButtons :farmVault="farmVault" />
    <div class="main-container mt-2 lg:mt-6">
      <p class="main-label">
        {{ $t('investment.label') }}
      </p>
      <div class="divider"></div>
      <div class="grid grid-cols-2 3xl:gap-8">
        <div class="col-span-2 3xl:col-span-1">
          <div class="invest-detail-container mt-3">
            <div class="invest-detail-label">{{ $t('wallet') }}:</div>
            <div class="invest-detail-value balance">
              {{
                farmVault.vault
                  ? farmVault.walletEmpty
                    ? '--'
                    : `${showTokenBalance(
                        farmVault.vault.stats.userBalanceNormalized
                      )} ${farmVault.vault.tokenSymbol} ($${fwp(
                        farmVault.vault.stats.userBalanceUsd
                      )})`
                  : farmVault.walletFarmEmpty
                  ? '--'
                  : `${showTokenBalance(farmVault.farm.stats.userBalance)} ${
                      farmVault.farm.tokenSymbol
                    }${
                      farmVault.farm.stats.userBalanceUsd
                        ? ` ($${fwp(farmVault.farm.stats.userBalanceUsd)})`
                        : ''
                    }`
              }}
            </div>
          </div>
          <div
            class="invest-detail-container mt-2"
            v-if="!farmVault.vaultEmpty"
          >
            <div class="invest-detail-label">{{ $t('vault') }}:</div>
            <div class="invest-detail-value balance">
              {{
                `${showTokenBalance(
                  farmVault.vault.stats.userBalanceVaultInTokenNormalized
                )} ${farmVault.vault.tokenSymbol} ($${fwp(
                  farmVault.vault.stats.userBalanceVaultUsd
                )})`
              }}
            </div>
          </div>
          <div class="invest-detail-container mt-2" v-if="!farmVault.farmEmpty">
            <div class="invest-detail-label">
              {{ farmVault.vault ? $t('vault_farm') : $t('farm') }}:
            </div>
            <div class="invest-detail-value balance">
              {{
                `${showTokenBalance(
                  farmVault.farm.stats &&
                    farmVault.farm.stats.userBalanceFarmInVaultTokenNormalized
                    ? farmVault.farm.stats.userBalanceFarmInVaultTokenNormalized
                    : farmVault.farm.stats.userBalanceFarm
                )} ${
                  farmVault.farm.stats &&
                  farmVault.farm.stats.userBalanceFarmInVaultTokenNormalized
                    ? farmVault.vault.tokenSymbol
                    : farmVault.farm.tokenSymbol
                } ($${fwp(farmVault.farm.stats.userBalanceFarmUsd)})`
              }}
            </div>
          </div>
        </div>
        <div class="col-span-2 3xl:col-span-1">
          <div
            class="invest-detail-container mt-2 3xl:mt-3"
            v-if="!farmVault.pendingEmpty"
          >
            <div class="invest-detail-label">{{ $t('pending') }}:</div>
            <div class="invest-detail-value">
              {{
                `${showTokenBalance(farmVault.farm.stats.rewardPending)} 
                  ${farmVault.farm.masterFarm.rewardTokenSymbol} 
                  ($${fwp(farmVault.farm.stats.rewardPendingUsd)})`
              }}
            </div>
          </div>
          <div
            class="invest-detail-container mt-2"
            v-if="
              farmVault.farm &&
                farmVault.farm.additionalPoolRewards &&
                farmVault.farm.additionalPoolRewards.filter(
                  item => item.rewardPending && item.rewardPending.gtn(0)
                ).length
            "
          >
            <div class="onsen-dual-label-container">
              <span class="onsen-dual-label"
                >{{
                  farmVault.name === 'A2B2 (ACSI)' ? $t('onsen') : $t('dual')
                }}:</span
              >
            </div>
            <div class="onsen-dual-value-container overflow-hidden">
              <div
                v-for="(additionalPoolReward,
                index) in farmVault.farm.additionalPoolRewards.filter(
                  item => item.rewardPending && item.rewardPending.gtn(0)
                )"
                :key="index"
                class="onsen-dual-value"
              >
                {{
                  `${showTokenBalance(
                    additionalPoolReward.rewardPending.mul(
                      bn(
                        10 **
                          (18 -
                            (Config.tokens[additionalPoolReward.rewardToken]
                              .decimals || 18))
                      )
                    )
                  )} ${Config.tokens[additionalPoolReward.rewardToken].symbol}`
                }}
                {{
                  additionalPoolReward.rewardPendingUsd
                    ? `($${fwp(additionalPoolReward.rewardPendingUsd)})`
                    : ''
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-container mt-2 grid grid-cols-2 gap-6">
      <Rewards :farmVault="farmVault" />
      <!-- <div class="col-span-1">
        <div class="text-sm grid grid-cols-5">
          <div class="col-span-3 text-sm font-semibold flex">
            {{ $t('rewards_to_date') }}
          </div>
          <div class="col-span-2">
            <img class="float-right h-4 w-4" src="~@/assets/icons/plus.svg" />
            <img
              class="float-right mr-2 h-4 w-4"
              src="~@/assets/icons/profit.svg"
            />
          </div>
          <div class="col-span-5 divider"></div>
          <div class="col-span-1 mt-1.5 text-xs">
            {{ $t('since') }}
          </div>
          <div class="col-span-4 mt-1.5 text-right text-xs">
            -- -> --
          </div>
          <div class="col-span-2 mt-1.5 text-xs">{{ $t('all_time') }}:</div>
          <div class="mt-1.5 text-right font-normal col-span-3 text-xs">
            --
          </div>
        </div>
      </div> -->
    </div>
    <AcsBoost
      v-if="
        farmVault.account &&
          ((farmVault.farm &&
            farmVault.farm.totalWeight &&
            !farmVault.farm.deprecated) ||
            (farmVault.vault &&
              farmVault.vault.tokenSymbol === 'ACS' &&
              farmVault.vault.stats))
      "
      :farmVault="farmVault"
    />
    <!-- <div class="main-container grid grid-cols-4 gap-8">
      <div class="col-span-3">
        <div class="text-xs grid grid-cols-2">
          <div class="col-span-1 text-sm font-semibold flex">
            {{ $t('harvest_optimizer') }}
            <BalIcon color="gray" name="help-circle" size="xs" />
          </div>
          <div class="col-span-1 flex justify-end items-center">
            <span class="text-2xs text-white mr-1">
              {{ $t('off').toUpperCase() }}
            </span>
            <BalToggle size="sm" />
            <span class="text-2xs text-white ml-1">
              {{ $t('on').toUpperCase() }}
            </span>
          </div>
          <div class="col-span-2 divider mt-0.5"></div>
          <div class="col-span-2 italic mt-1.5 text-2xs">
            {{ $t('optimal_next_harvest_in') }}:
            <span class="font-semibold">14h</span>
          </div>
          <div class="col-span-2 italic mt-1.5 text-2xs">
            <div
              class="h-4 rounded-full border bg-gray-750 border-gray-450 grid grid-cols-64 gap-0.5 px-1 py-0.5"
            >
              <div
                v-for="index in 24"
                :key="index"
                class="col-span-1 bg-pink-550"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-1"></div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Tags from '../Tags.vue';
import ActionButtons from './ActionButtons.vue';
import AcsBoost from './AcsBoost.vue';
import Rewards from './Rewards.vue';
import { fwp, bn } from '@/lib/vault/bn';
import { Config } from '@/lib/vault/config';
import { showTokenBalance, showApr } from '@/lib/vault/farmVaultUtils';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  components: {
    Rewards,
    AcsBoost,
    ActionButtons,
    Tags
  },

  props: {
    farmVault: { type: Object },
    isLoadingData: { type: Boolean }
  },

  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      bn,
      fwp,
      showApr,
      showTokenBalance,
      upToLargeBreakpoint,
      // data
      Config
    };
  }
});
</script>
<style scoped>
@media (max-width: 1024px) {
  .token-name {
    height: 24px;
  }
  .detail-value {
    @apply pt-2;
  }
}
@media (min-width: 1024px) {
  /* .token-name {
    height: 72px;
  } */
}
@media (min-width: 1920px) {
  .balance {
    @apply text-right;
  }
}
.detail-container {
  @apply relative flex items-center justify-center h-12 lg:h-16 bg-gray-70 dark:bg-gray-730 rounded-xl lg:rounded-2xl col-span-1;
}
.detail-label {
  @apply absolute top-1 lg:top-2 left-2 lg:left-4 text-gray-390 text-2xs lg:text-sm font-medium;
}
.detail-value {
  @apply text-sssm lg:text-base font-bold mt-1.5;
}
.invest-detail-container {
  @apply flex;
}
.invest-detail-label {
  @apply pr-2 text-gray-390 text-sssm lg:text-sm font-medium leading-4;
  width: 88px;
}
.invest-detail-value {
  @apply text-sssm lg:text-base font-bold leading-4;
  width: calc(100% - 88px);
}
.onsen-dual-label-container {
  width: 88px;
}
.onsen-dual-value-container {
  width: calc(100% - 88px);
}
.onsen-dual-label {
  @apply h-4 lg:h-5 w-16 bg-green-530 rounded-full;
  @apply flex items-center justify-center pt-0.5;
  @apply text-sssm lg:text-sm font-medium;
}
.onsen-dual-value {
  @apply text-green-530 mb-1 text-sssm lg:text-base font-bold leading-4;
}
.token-name {
  @apply flex items-center text-sssm lg:text-base font-bold;
}
.token-name img {
  @apply h-6 w-6 lg:h-10 lg:w-10 mr-2 lg:mr-4;
}
</style>
<style>
.main-container {
  @apply bg-gray-70 dark:bg-gray-730 py-2 px-4;
  border-radius: 14px;
}
.main-label {
  @apply text-sssm lg:text-base font-bold flex items-center;
}
.divider {
  @apply h-0 border border-sky-320 mt-2;
}
</style>
