<template>
  <div class="nft-viewer-type-owner">
    <div class="nft-viewer-type">
      <img v-if="nft && nft.image" :src="nft.image" class="nft-viewer-avatar" />
      <BalLoadingBlock v-else rounded="2xl" class="nft-viewer-avatar" />
      <span class="nft-viewer-type-label">{{
        nft?.tokenType ? `${$t('tgw_s1')} ${NFT_TYPE[nft.tokenType]}` : '--'
      }}</span>
      <BalIcon
        name="bi:patch-check-fill"
        class="text-blue-390"
        :size="upToLargeBreakpoint ? 'xs' : 'sm'"
      />
    </div>
    <div class="nft-viewer-owner">
      <BalIcon
        name="bi:person-circle"
        class="nft-viewer-icon"
        :size="upToLargeBreakpoint ? 'lg' : '2xl'"
      />
      <a
        v-if="nft && nft.owner"
        class="nft-viewer-owner-label"
        :href="explorerLinks.addressLink(nft.owner)"
        target="_blank"
      >
        {{ $t('view_owners_on_bscscan') }}
      </a>
      <BalLoadingBlock v-else rounded="md" class="nft-viewer-owner-label" />
    </div>
    <!-- <div class="nft-viewer-owner">
      <img src="~@/assets/images/artist.svg" class="nft-viewer-avatar" />
      <span class="nft-viewer-owner-label">
        {{ $t('visual_artist_andy_yang') }}
      </span>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';
import { NFT_TYPE } from '@/constants';

export default defineComponent({
  props: {
    nft: { type: Object }
  },

  setup() {
    const { explorerLinks } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      NFT_TYPE,
      explorerLinks,
      upToLargeBreakpoint
    };
  }
});
</script>

<style scoped>
@media (max-width: 1024px) {
  .nft-viewer-owner-value {
    width: calc(100% - 90px);
  }
}
@media (min-width: 1024px) {
  .nft-viewer-owner-value {
    width: calc(100% - 124px);
  }
}
.nft-viewer-type-owner {
  @apply mt-6 lg:mt-11;
  font-family: 'Fira Sans';
}
.nft-viewer-type,
.nft-viewer-owner {
  @apply flex items-center mb-3;
}
.nft-viewer-avatar {
  @apply h-6 w-6 lg:h-8 lg:w-8 rounded-full mr-0.5 lg:mr-1;
}
.nft-viewer-icon {
  @apply text-sky-420 mr-0.5 lg:mr-1;
}
.nft-viewer-type-label,
.nft-viewer-owner-label {
  @apply text-base mr-1.5 lg:mr-2 font-semibold mt-0.5;
}
.nft-viewer-owner-label {
  @apply h-5;
  min-width: 200px;
}
</style>
