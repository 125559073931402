import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Config } from '@/lib/vault/config';
import { vaultsController } from '@/lib/vault/vaults-controller';
import {
  initFarmVault,
  account,
  walletEmpty,
  vaultEmpty,
  walletFarmEmpty,
  farmEmpty,
  farmRewardsEmpty,
  pendingEmpty,
  updateApy,
  updateTvl
} from '@/lib/vault/farmVaultUtils';

const acsStats = ref(null);
const farmVaults = ref([]);
const farmVaultsDeprecated = ref([]);

vaultsController.addEventListener('update', event => {
  // @ts-ignore
  acsStats.value = { ...event.target.acsStats };
  // @ts-ignore
  farmVaults.value = [...event.target.farmVaults];
  // @ts-ignore
  farmVaultsDeprecated.value = [...event.target.farmVaultsDeprecated];
});

export default function useVaults() {
  const { t } = useI18n();

  // METHODS
  const formatFarmVault = (item, initialFarmVault) => {
    let farmVault = {
      ...initialFarmVault,
      ...(item as Record<string, unknown>)
    };
    farmVault.account = account(item);
    farmVault.walletEmpty = walletEmpty(farmVault);
    farmVault.vaultEmpty = vaultEmpty(farmVault);
    farmVault.walletFarmEmpty = walletFarmEmpty(farmVault);
    farmVault.farmEmpty = farmEmpty(farmVault);
    farmVault.farmRewardsEmpty = farmRewardsEmpty(farmVault);
    farmVault.pendingEmpty = pendingEmpty(farmVault);

    if (
      (farmVault.vault && farmVault.vault.stats) ||
      (farmVault.farm && farmVault.farm.stats)
    ) {
      farmVault = updateApy(farmVault);
      farmVault = updateTvl(farmVault);
    }
    return farmVault;
  };

  // COMPUTED
  const initialFarmVaults = computed(() =>
    Config.farmVaults
      .filter(item => item.tags.includes('wav'))
      .map((item, i) => {
        return initFarmVault(item, i, t);
      })
  );

  const initialFarmVaultsDeprecated = computed(() =>
    Config.farmVaultsDeprecated.map((item, i) => {
      return initFarmVault(item, i, t);
    })
  );

  const formatedFarmVaults = computed(() => {
    const temp = farmVaults.value;
    if (temp.length) {
      return temp.map((item, i) => {
        const farmVault = formatFarmVault(item, initialFarmVaults.value[i]);
        return farmVault;
      });
    } else {
      return initialFarmVaults.value;
    }
  });

  const formatedFarmVaultsDeprecated = computed(() => {
    const temp = farmVaultsDeprecated.value;
    if (temp.length) {
      return temp.map((item, i) =>
        formatFarmVault(item, initialFarmVaultsDeprecated.value[i])
      );
    } else {
      return initialFarmVaults.value;
    }
  });

  // METHODS
  const init = () => {
    farmVaults.value = [];
    farmVaultsDeprecated.value = [];
  };
  const resolveVaults = async connector => {
    await vaultsController.resolve(connector);
  };

  return {
    // datas
    vaultsController,
    acsStats,
    farmVaults,
    formatedFarmVaults,
    formatedFarmVaultsDeprecated,
    // methods
    init,
    resolveVaults
  };
}
