
import { defineComponent, watch, ref } from 'vue';

export default defineComponent({
  emits: ['close'],

  props: {
    link: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const timeout = ref();

    watch(
      () => props.isVisible,
      newVal => {
        clearTimeout(timeout.value);
        if (newVal) {
          timeout.value = window.setTimeout(function() {
            window.location.href = props.link;
          }, 5000);
        }
      }
    );
  }
});
