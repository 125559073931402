import { Config } from './config';
import { bn, fw, np } from '@/lib/vault/bn';
import { StableSwap } from '@/lib/vault/stable-swap';
import { Pair } from '@/lib/vault/pair';
import { Blp } from '@/lib/vault/blp';

export const walletEmpty = farmVault => {
  if (
    farmVault.vault &&
    farmVault.vault.stats &&
    farmVault.vault.stats.userBalance &&
    !farmVault.vault.stats.userBalance.lten(0)
  ) {
    return false;
  }
  return true;
};

export const vaultEmpty = farmVault => {
  if (
    farmVault.vault &&
    farmVault.vault.stats &&
    farmVault.vault.stats.userBalanceVault &&
    !farmVault.vault.stats.userBalanceVault.lten(0)
  ) {
    return false;
  }
  return true;
};

export const walletFarmEmpty = farmVault => {
  if (
    farmVault.farm &&
    farmVault.farm.stats &&
    farmVault.farm.stats.userBalance &&
    !farmVault.farm.stats.userBalance.lten(0)
  ) {
    return false;
  }
  return true;
};

export const farmEmpty = farmVault => {
  if (
    farmVault.farm &&
    farmVault.farm.stats &&
    farmVault.farm.stats.userBalanceFarm &&
    !farmVault.farm.stats.userBalanceFarm.lten(0)
  ) {
    return false;
  }
  return true;
};

export const pendingEmpty = farmVault => {
  if (
    farmVault.farm &&
    farmVault.farm.stats &&
    farmVault.farm.stats.rewardPending &&
    !farmVault.farm.stats.rewardPending.lten(0)
  ) {
    return false;
  }
  return true;
};

export const farmRewardsEmpty = farmVault => {
  if (
    farmVault.farm &&
    farmVault.farm.masterFarm &&
    farmVault.farm.masterFarm.rewardTokenSymbol &&
    farmVault.farm.stats &&
    farmVault.farm.stats.rewardPending
  ) {
    if (
      (farmVault.farm.masterFarm.rewardTokenSymbol == 'WAV' &&
        farmVault.farm.stats.rewardPending.lte(
          bn(Config.wavHarvestFee * 1e18)
        )) ||
      (farmVault.farm.masterFarm.rewardTokenSymbol == 'ACSI' &&
        farmVault.farm.stats.rewardPending.lte(
          bn(Config.acsiHarvestFee * 1e18)
        )) ||
      (farmVault.farm.masterFarm.rewardTokenSymbol == 'ACS' &&
        farmVault.farm.stats.rewardPending.lte(bn(Config.acsHarvestFee * 1e18)))
    ) {
      return true;
    }
    return false;
  }
  return true;
};

export const showApr = i => {
  return i < 10
    ? np(i, 2)
    : i < 100
    ? np(i, 1)
    : i < 1000
    ? np(i, 0)
    : i < 10000
    ? `${np(i / 1000, 2)}K`
    : i < 100000
    ? `${np(i / 1000, 1)}K`
    : i < 1000000
    ? `${np(i / 1000, 0)}K`
    : i < 10000000
    ? `${np(i / 1000000, 2)}M`
    : i < 100000000
    ? `${np(i / 1000000, 1)}M`
    : `${np(i / 1000000, 0)}M`;
};

export const updateApy = item => {
  const token = (item.vault && item.vault.token) || item.farm.token;
  let swapApy =
    StableSwap.stableSwapsByLpToken[token] ||
    Pair.pairs[token] ||
    Blp.blps[token];
  swapApy = swapApy && swapApy.apyDay > 0.001 && swapApy;

  // const isBoostedApy = item.farm && item.farm.stats.roiDayBoosted;
  const daily =
    ((swapApy && swapApy.roiDay) || 0) +
    ((item.vault && item.vault.stats?.roiDay) || 0) +
    ((item.farm &&
      (item.farm.stats?.roiDayBoosted || item.farm.stats?.roiDay)) ||
      0);
  item.apy = ((daily + 1) ** 365 - 1) * 100;
  item.daily = daily * 100;
  item.apr = daily * 365 * 100;
  if (item.apy) {
    item.apyShown = showApr(item.apy) + '%';
  }
  if (item.apr) {
    item.aprShown = item.apr.toFixed(2) + '%';
  }
  if (item.daily) {
    item.dailyShown = item.daily.toFixed(2) + '%';
  }

  if (item.farm && item.farm.stats.roiDayMin) {
    // let dailyMin =
    //   ((swapApy && swapApy.roiDay) || 0) +
    //   ((item.vault && item.vault.stats.roiDay) || 0) +
    //   ((item.farm && item.farm.stats.roiDayMin) || 0);
    // let dailyMax =
    //   ((swapApy && swapApy.roiDay) || 0) +
    //   ((item.vault && item.vault.stats.roiDay) || 0) +
    //   ((item.farm && item.farm.stats.roiDayMax) || 0);
    // const apyMin = ((dailyMin + 1) ** 365 - 1) * 100;
    // const apyMax = ((dailyMax + 1) ** 365 - 1) * 100;
    // dailyMin = dailyMin * 100;
    // dailyMax = dailyMax * 100;
  }

  item.aprTotal = 0;
  item.aprTotalMin = 0;
  item.aprTotalMax = 0;

  item.apyTotal = 0;
  item.apyTotalMin = 0;
  item.apyTotalMax = 0;

  item.dailyTotal = 0;
  item.dailyTotalMin = 0;
  item.dailyTotalMax = 0;

  if (swapApy && swapApy.aprDay) {
    item.swapApr = swapApy.aprDay * 100;
    item.swapApy = swapApy.apyDay * 100;
    item.swapDaily = swapApy.roiDay * 100;

    item.aprTotal += item.swapApr;
    item.aprTotalMin += item.swapApr;
    item.aprTotalMax += item.swapApr;

    item.apyTotal += item.swapApy;
    item.apyTotalMin += item.swapApy;
    item.apyTotalMax += item.swapApy;

    item.dailyTotal += item.swapDaily;
    item.dailyTotalMin += item.swapDaily;
    item.dailyTotalMax += item.swapDaily;
  }
  if (item.vault && item.vault.stats?.apyDay && item.vault.stats.apyDay > 0) {
    item.vaultApr = item.vault.stats.aprDay * 100;
    item.vaultApy = item.vault.stats.apyDay * 100;
    item.vaultDaily = item.vault.stats.roiDay * 100;

    item.aprTotal += item.vaultApr;
    item.aprTotalMin += item.vaultApr;
    item.aprTotalMax += item.vaultApr;

    item.apyTotal += item.vaultApy;
    item.apyTotalMin += item.vaultApy;
    item.apyTotalMax += item.vaultApy;

    item.dailyTotal += item.vaultDaily;
    item.dailyTotalMin += item.vaultDaily;
    item.dailyTotalMax += item.vaultDaily;
  }
  if (item.farm && item.farm.stats?.apyDay) {
    item.farmApy = item.farm.stats.apyDay * 100;
    item.farmApr = item.farm.stats.aprDay * 100;
    item.farmDaily = item.farm.stats.roiDay * 100;
    item.farmApyMin =
      item.farm.stats.apyDayMin && item.farm.stats.apyDayMin * 100;
    item.farmAprMin =
      item.farm.stats.aprDayMin && item.farm.stats.aprDayMin * 100;
    item.farmDailyMin =
      item.farm.stats.roiDayMin && item.farm.stats.roiDayMin * 100;
    item.farmApyMax =
      item.farm.stats.apyDayMax && item.farm.stats.apyDayMax * 100;
    item.farmAprMax =
      item.farm.stats.aprDayMax && item.farm.stats.aprDayMax * 100;
    item.farmDailyMax =
      item.farm.stats.roiDayMax && item.farm.stats.roiDayMax * 100;
    item.farmApyBoosted =
      item.farm.stats.apyDayBoosted && item.farm.stats.apyDayBoosted * 100;
    item.farmAprBoosted =
      item.farm.stats.aprDayBoosted && item.farm.stats.aprDayBoosted * 100;
    item.farmDailyBoosted =
      item.farm.stats.roiDayBoosted && item.farm.stats.roiDayBoosted * 100;

    item.aprTotal += item.farmAprBoosted ? item.farmAprBoosted : item.farmApr;
    item.aprTotalMin += item.farmAprMin;
    item.aprTotalMax += item.farmAprMax;

    item.apyTotal += item.farmApyBoosted ? item.farmApyBoosted : item.farmApy;
    item.apyTotalMin += item.farmApyMin;
    item.apyTotalMax += item.farmApyMax;

    item.dailyTotal += item.farmDailyBoosted
      ? item.farmDailyBoosted
      : item.farmDaily;
    item.dailyTotalMin += item.farmDailyMin;
    item.dailyTotalMax += item.farmDailyMax;
  }
  return item;
};

export const updateTvl = item => {
  // const tvl =
  //   item.vault && !item.vault.showFarmDeprecatedOnly
  //     ? item.vault.stats.vaultBalance
  //     : item.farm.stats.farmBalance;
  const tvlUsd =
    item.vault && !item.vault.showFarmDeprecatedOnly
      ? item.vault.stats?.vaultBalanceUsd
      : item.farm.stats?.farmBalanceUsd;

  // calculate header tvls
  if (tvlUsd) {
    const __tvl = parseFloat(fw(tvlUsd));
    item.tvl = parseFloat(fw(tvlUsd));
    const _tvl =
      __tvl < 100000
        ? `${np(__tvl / 1000, 1)}K`
        : __tvl < 1000000
        ? `${np(__tvl / 1000, 0)}K`
        : `${np(__tvl / 1000000, 2)}M`;
    if (_tvl) {
      item.tvlShown = '$' + _tvl;
    }

    let userBalance = bn(0);
    if (item.vault) {
      item.vault.stats.userBalanceUsd &&
        userBalance.iadd(item.vault.stats.userBalanceUsd);
      item.vault.stats.userBalanceVaultUsd &&
        userBalance.iadd(item.vault.stats.userBalanceVaultUsd);
    } else {
      item.farm.stats.userBalanceUsd &&
        userBalance.iadd(item.farm.stats.userBalanceUsd);
    }
    if (item.farm) {
      item.farm.stats.userBalanceFarmUsd &&
        userBalance.iadd(item.farm.stats.userBalanceFarmUsd);
    }
    userBalance = parseFloat(fw(userBalance));
    item.balance = userBalance;
    item.balanceShown =
      userBalance <= 0
        ? '--'
        : userBalance < 1000
        ? `$${np(userBalance, 0)}`
        : userBalance < 100000
        ? `$${np(userBalance / 1000, 1)}K`
        : userBalance < 1000000
        ? `$${np(userBalance / 1000, 0)}K`
        : `$${np(userBalance / 1000000, 2)}M`;
  }

  item.rewardPending = 0;
  if (item.farm?.stats?.rewardPendingUsd?.gtn(0)) {
    item.rewardPending = Number.parseFloat(
      fw(item.farm.stats.rewardPendingUsd || item.farm.stats.rewardPending)
    );
  }
  if (item.rewardPending) {
    item.rewardPendingShown = `$${item.rewardPending.toLocaleString('en-US', {
      maximumFractionDigits: 2
    })}`;
  }
  return item;
};

export const account = farmVault => {
  return (farmVault.vault || farmVault.farm)?.connect?.account || null;
};

export const initFarmVault = (item, i, t) => {
  const farmVault = {
    ...item,
    id: i,
    tokenImage: item.vault ? item.vault.tokenImage : item.farm.tokenImage,
    tokenSymbol: item.vault ? item.vault.tokenSymbol : item.farm.tokenSymbol,
    title: item.vault ? item.vault.title : item.farm.title,
    aprShown: '--',
    apyShown: '--',
    dailyShown: '--',
    tvlShown: '--',
    rewardPendingShown: '--',
    balanceShown: '--',
    walletEmpty: true,
    vaultEmpty: true,
    walletFarmEmpty: true,
    farmEmpty: true,
    farmRewardsEmpty: true,
    pendingEmpty: true
  };
  farmVault.name = farmVault.title || farmVault.tokenSymbol;

  if (farmVault.vault && farmVault.vault.transactionInfo) {
    farmVault.vaultTransactionInfo = farmVault.vault.transactionInfo
      .replace('Withdrawal fee', t('withdrawal_fee'))
      .replace('is non-transferrable', t('is_non_transferrable'));
  }
  if (farmVault.farm && farmVault.farm.transactionInfo) {
    farmVault.farmTransactionInfo = farmVault.farm.transactionInfo.replace(
      'Harvest fee',
      t('harvest_fee')
    );
  }
  if (farmVault.vault && farmVault.vault.walletInfo) {
    farmVault.walletInfo = farmVault.vault.walletInfo.replace(
      '>Get<',
      `>${t('get')}<`
    );
  }
  if (farmVault.farm && farmVault.farm.walletInfo) {
    farmVault.walletInfo = farmVault.farm.walletInfo.replace(
      '>Get<',
      `>${t('get')}<`
    );
  }

  return farmVault;
};

export const showTokenBalance = i => {
  if (!i) return;
  i = parseFloat(fw(i));
  return i > 10000
    ? i.toLocaleString(undefined, { maximumFractionDigits: 2 })
    : i.toLocaleString(undefined, { maximumSignificantDigits: 5 });
};

export const triggerUpdate = async farmVault => {
  await (farmVault?.vault || farmVault?.farm).connect.poller.poll();
};

export const plantLevel = farmVault => {
  const harvestFee =
    farmVault.farm && farmVault.farm.masterFarm.rewardTokenSymbol == 'ACS'
      ? Config.acsHarvestFee
      : Config.acsiHarvestFee;
  if (
    farmVault &&
    farmVault.farm &&
    farmVault.farm.stats &&
    farmVault.farm.stats.rewardPending &&
    !farmVault.farm.stats.rewardPending.lt(bn(harvestFee * 4 * 1e18))
  ) {
    return farmVault.farm.stats.rewardPending.lt(bn(harvestFee * 8 * 1e18))
      ? 1
      : farmVault.farm.stats.rewardPending.lt(bn(harvestFee * 16 * 1e18))
      ? 2
      : farmVault.farm.stats.rewardPending.lt(bn(harvestFee * 32 * 1e18))
      ? 3
      : 4;
  }
  return 0;
};
