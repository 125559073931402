<template>
  <div
    v-if="!upToLargeBreakpoint"
    class="px-6 py-8 flex items-center justify-between"
  >
    <div class="w-1/3 text-xl font-semibold">
      {{
        router.currentRoute.value.name && showNavTitle()
          ? $t(router.currentRoute.value.name.replace('-', '_'))
          : ''
      }}
    </div>
    <div class="w-2/3 flex justify-end">
      <!-- <BuyBNB /> -->
      <DarkModeToggle class="mr-4" />
      <SetLocale class="mr-4" />
      <AppNavActions />
    </div>
  </div>
  <div v-else class="p-4 flex items-center">
    <div class="w-1/5 flex">
      <BalIcon
        v-if="selectedVaultId === -1"
        name="line-md:menu-fold-right"
        size="lg"
        @click="setMenuShow(true)"
        :class="{ 'text-gray-650': !showNavTitle() }"
      />
      <BalBtn
        v-else
        circle
        outline
        color="gray"
        border="thin"
        size="xs"
        @click="setSelectedVaultId(-1)"
      >
        <BalIcon name="akar-icons:chevron-left" size="sm" />
      </BalBtn>
    </div>
    <div v-if="showNavTitle()" class="text-center text-lg font-semibold w-3/5">
      {{
        router.currentRoute.value.name && showNavTitle()
          ? $t(router.currentRoute.value.name)
          : ''
      }}
    </div>
    <div class="flex justify-end" :class="showNavTitle() ? 'w-1/5' : 'w-4/5'">
      <DarkModeToggle class="mr-2" />
      <SetLocale class="mr-2" />
      <AppNavActions v-if="!showNavTitle()" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import AppNavActions from './AppNavActions.vue';
// import BuyBNB from './BuyBNB.vue';
import DarkModeToggle from './DarkModeToggle.vue';
import SetLocale from './SetLocale.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useVaultsList from '@/composables/useVaultsList';
import useMenuCollapsed from '@/composables/useMenuCollapsed';

export default defineComponent({
  components: {
    AppNavActions,
    // BuyBNB,
    DarkModeToggle,
    SetLocale
  },

  setup() {
    const router = useRouter();
    const { setMenuShow } = useMenuCollapsed();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { selectedVaultId, setSelectedVaultId } = useVaultsList();

    // METHODS
    const showNavTitle = () => {
      return (
        router.currentRoute.value.name !== 'buy-nft' &&
        router.currentRoute.value.name !== 'my-nfts' &&
        router.currentRoute.value.name !== 'leaderboard' &&
        router.currentRoute.value.name !== 'nft-viewer'
      );
    };

    return {
      selectedVaultId,
      setSelectedVaultId,
      upToLargeBreakpoint,
      setMenuShow,
      router,
      // methods
      showNavTitle
    };
  }
});
</script>
