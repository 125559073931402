<template>
  <div
    class="toggle-btn w-9 h-9 flex justify-center items-center cursor-pointer rounded-full bg-gray-50 dark:bg-gray-900 shadow group"
    @click="toggle"
  >
    <div
      class="ease-in-out duration-300"
      :class="{ 'transform rotate-180': isRotated }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 18"
        class="icon-trade-toggle w-5 h-3"
      >
        <path
          d="M6.26693 16.2421L6.26693 3.5252L8.70412 5.96238C9.03943 6.29801 9.58349 6.29801 9.91943 5.96238C10.2554 5.62676 10.2551 5.08301 9.91943 4.74707L6.01537 0.843008C5.97568 0.80332 5.93193 0.767695 5.88537 0.736133C5.87349 0.728633 5.86037 0.72332 5.84849 0.716445C5.81287 0.694883 5.77693 0.67332 5.73787 0.657383C5.72005 0.650195 5.70099 0.646758 5.68287 0.640195C5.64818 0.628633 5.6138 0.615508 5.57724 0.608008C5.52162 0.596758 5.46505 0.59082 5.40724 0.59082C5.34943 0.59082 5.29287 0.596758 5.23724 0.608008C5.20068 0.615195 5.1663 0.62832 5.13162 0.640195C5.11349 0.646758 5.09443 0.649883 5.07662 0.657383C5.03787 0.673633 5.00193 0.694883 4.96599 0.716445C4.95412 0.72332 4.94099 0.728633 4.92912 0.736133C4.88255 0.767695 4.8388 0.80332 4.79912 0.843008L0.895679 4.74645C0.727866 4.91426 0.643804 5.13395 0.643804 5.35395C0.643804 5.57395 0.727866 5.79363 0.895679 5.96145C1.23099 6.29707 1.77505 6.29707 2.11099 5.96145L4.54787 3.52488L4.54787 16.2418C4.54787 16.7164 4.93255 17.1011 5.40724 17.1011C5.88193 17.1011 6.26693 16.7168 6.26693 16.2421Z"
          class="text-blue-500 group-hover:text-pink-500 fill-current"
        />
        <path
          d="M15.7625 17.2669C15.7987 17.2597 15.8328 17.2469 15.8672 17.235C15.8856 17.2284 15.905 17.2253 15.9231 17.2175C15.9612 17.2013 15.9966 17.1806 16.0322 17.1591C16.0447 17.1519 16.0584 17.1462 16.0706 17.1384C16.1172 17.1069 16.1609 17.0713 16.2006 17.0316L20.1037 13.1284C20.4394 12.7928 20.4394 12.2491 20.1037 11.9131C19.7684 11.5775 19.2244 11.5775 18.8884 11.9131L16.4516 14.3497V1.63281C16.4516 1.15813 16.0669 0.773438 15.5922 0.773438C15.1175 0.773438 14.7328 1.15813 14.7328 1.63281V14.3497L12.2956 11.9125C11.9603 11.5769 11.4163 11.5769 11.0803 11.9125C10.9125 12.0803 10.8284 12.3 10.8284 12.52C10.8284 12.74 10.9125 12.9597 11.0803 13.1275L14.9841 17.0316C15.0238 17.0713 15.0675 17.1069 15.1141 17.1384C15.1262 17.1462 15.14 17.1519 15.1525 17.1591C15.1878 17.1803 15.2234 17.2013 15.2616 17.2175C15.2797 17.225 15.2991 17.2284 15.3175 17.235C15.3519 17.2466 15.3859 17.2594 15.4222 17.2669C15.4778 17.2778 15.5344 17.2841 15.5922 17.2841C15.65 17.2841 15.7069 17.2778 15.7625 17.2669Z"
          class="dark:text-gray-700 fill-current"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  emits: ['toggle'],
  setup(_props, { emit }) {
    const isRotated = ref(false);

    function toggle(): void {
      isRotated.value = !isRotated.value;
      emit('toggle');
    }

    return {
      isRotated,
      toggle
    };
  }
});
</script>
<style scoped>
.icon-trade-toggle {
  @apply transition-transform ease-out duration-200;
}

.toggle-btn:hover .icon-trade-toggle {
  transform: scale(1.2);
}
</style>
