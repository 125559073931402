import { createApp } from 'vue';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import store from '@/store';
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';
import mixins from '@/plugins/mixins';
import { registerGlobalComponents } from '@/plugins/components';
import blocknative from '@/plugins/blocknative';
import vueQuery from '@/plugins/vueQuery';
import VueVirtualScroller from 'vue3-virtual-scroller';
import registerDirectives from '@/plugins/directives';
import '@/assets/css/tailwind.css';
import '@/assets/css/index.css';
import 'vue3-virtual-scroller/dist/vue3-virtual-scroller.css';
import Root from './Root';

const options = {
  position: POSITION.BOTTOM_LEFT,
  hideProgressBar: true
};

const app = createApp(Root)
  .use(i18n)
  .use(router)
  .use(store)
  .use(Toast, options)
  .use(blocknative)
  .use(vueQuery)
  .mixin(mixins)
  .use(VueVirtualScroller);

registerDirectives(app);
registerGlobalComponents(app);

app.mount('#app');

export default app;
