
import { Icon } from '@iconify/vue';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BalIcon',

  components: {
    Icon
  },

  props: {
    name: { type: String, required: true },
    size: {
      type: String,
      default: 'md',
      validator: (val: string): boolean => {
        return [
          '2xs',
          'xs',
          'xsd',
          'sm',
          'smd',
          'md',
          'lg',
          'xl',
          '2xl'
        ].includes(val);
      }
    }
  },

  setup(props) {
    const iconSize = computed(() => {
      switch (props.size) {
        case '2xs':
          return '10';
        case 'xs':
          return '12';
        case 'xsd':
          return '14';
        case 'sm':
          return '16';
        case 'smd':
          return '18';
        case 'md':
          return '20';
        case 'lg':
          return '24';
        case 'xl':
          return '28';
        case '2xl':
          return '32';
        default:
          return '20';
      }
    });

    return { iconSize };
  }
});
