<template>
  <div id="modal" />
  <div class="flex m-auto main relative">
    <AppSideNav />
    <main class="flex-1 w-full relative">
      <AppNav class="absolute top-0 w-full z-10" />
      <router-view :key="$route.path" />
    </main>
  </div>
  <BuyLpModal
    v-if="isBuyLpModalVisible"
    :poolId="poolId"
    :isVisible="isBuyLpModalVisible"
    @close="toggleBuyLpModal(false)"
  />
  <BuyTokenModal
    :isVisible="isBuyTokenModalVisible"
    @close="toggleBuyTokenModal(false)"
  />
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import AppNav from '@/components/navs/AppNav/AppNav.vue';
import AppSideNav from '@/components/navs/AppSideNav/AppSideNav.vue';
import BuyLpModal from '@/components/modals/BuyLpModal.vue';
import BuyTokenModal from '@/components/modals/BuyTokenModal.vue';
import useWeb3 from '@/services/web3/useWeb3';
import useBuyTokenModal from '@/composables/useBuyTokenModal';
import useBuyLpModal from '@/composables/useBuyLpModal';

export default defineComponent({
  components: {
    BuyTokenModal,
    BuyLpModal,
    AppNav,
    AppSideNav
  },

  setup() {
    const store = useStore();
    const { init } = useWeb3();
    const { isBuyTokenModalVisible, toggleBuyTokenModal } = useBuyTokenModal();
    const { isBuyLpModalVisible, toggleBuyLpModal, poolId } = useBuyLpModal();

    // BEFORE MOUNT
    onBeforeMount(async () => {
      store.dispatch('app/init');
      init();
    });

    return {
      poolId,
      isBuyLpModalVisible,
      toggleBuyLpModal,
      isBuyTokenModalVisible,
      toggleBuyTokenModal
    };
  }
});
</script>
<style scoped>
.main {
  max-width: 1920px;
}
</style>
