
import { defineComponent, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { bn, fw, tw } from '@/lib/vault/bn';
import { Config } from '@/lib/vault/config';
import { showTokenBalance, triggerUpdate } from '@/lib/vault/farmVaultUtils';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';
import useBuyLpModal from '@/composables/useBuyLpModal';

export default defineComponent({
  props: {
    farmVault: { type: Object }
  },

  setup(props) {
    const { t } = useI18n();
    const toast = useToast();
    const { explorerLinks } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { toggleBuyLpModal } = useBuyLpModal();

    // COMPUTED
    const defaultVaultTransactionInfo = computed(
      () =>
        `<a class="wallet-info" href="https://docs.acryptos.com/fees#all-vaults-except-acs-and-acsi-vault" target="_blank"><span>${t(
          'withdrawal_fee'
        )}</span>: 0.1%</a>`
    );

    const defaultFarmTransactionInfo = computed(
      () =>
        `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms" target="_blank"><span>${t(
          'harvest_fee'
        )}</span>: ${Config.acsHarvestFee} ACS</a>`
    );

    const buttonList = computed(() => [
      // {
      //   name: 'harvest_to_vault',
      //   isVisible: !props.farmVault?.farmRewardsEmpty
      // },
      {
        name: 'harvest',
        isVisible: !props.farmVault?.farmRewardsEmpty
      },
      {
        name: 'deposit',
        isVisible:
          !props.farmVault?.walletEmpty &&
          !(props.farmVault?.farm && props.farmVault.farm.deprecated) &&
          props.farmVault?.vault &&
          !props.farmVault.vault.showFarmDeprecatedOnly &&
          !props.farmVault.vault.deprecated
      },
      {
        name: 'stake',
        isVisible:
          props.farmVault?.farm &&
          !props.farmVault.farm.deprecated &&
          (!props.farmVault.vaultEmpty || !props.farmVault.walletFarmEmpty)
      },
      {
        name: 'unstake',
        isVisible: !props.farmVault?.farmEmpty
      },
      {
        name: 'withdraw',
        isVisible: !props.farmVault?.vaultEmpty
      }
    ]);

    const visibleButtonList = computed(() =>
      buttonList.value.filter(item => item.isVisible)
    );

    const walletInfoList = computed(() => {
      if (!props.farmVault?.walletInfo) {
        return [];
      }
      const splited = props.farmVault?.walletInfo.split('a> <a');
      let label = '';
      let labelEnd = 0;
      const labelStart = splited[0].indexOf('> ') + 2;
      if (splited.length === 1) {
        labelEnd = splited[0].indexOf('</a');
      } else {
        labelEnd = splited[0].indexOf(': <');
      }
      label = splited[0].slice(labelStart, labelEnd);

      return splited.map((item, index) => {
        const linkStart = item.indexOf('href="') + 6;
        const linkEnd = item.indexOf('" target="_blank"');
        const link = item.slice(linkStart, linkEnd);
        let reLabel = label;
        if (splited.length === 2 && index === 0) {
          reLabel = label + ' Acsi.Finance';
        }
        if (splited.length === 2 && index === 1) {
          reLabel = label + ' 1inch';
        }
        if (link.includes('app.acsi.finance')) {
          const splitedLink = link.split('/');
          poolId.value = splitedLink[splitedLink.length - 1];
        }

        return {
          label: reLabel,
          link
        };
      });
    });

    // DATA
    const status = ref('');
    const amount = ref('');
    const isAll = ref(true);
    const isGettingMax = ref(false);
    const poolId = ref('');

    // WATCHERS
    watch(
      () => props.farmVault?.id,
      () => {
        init();
      }
    );

    // METHODS
    const doAction = s => {
      if (!amount.value && s !== 'harvest') {
        toast.warning(t('alert_input_correct_amount'));
        return;
      }
      let formatedAmount = null;
      let response = null;
      status.value = 'loading';
      if (props.farmVault?.vault) {
        if (s !== 'harvest') {
          formatedAmount = props.farmVault.vault.unapplyTokenDecimals(
            bn(tw(`${amount.value}`))
          );
        }
        if (s === 'deposit') {
          response = props.farmVault.vault.deposit(formatedAmount).then(() => {
            if (props.farmVault?.farm && isAll.value) {
              props.farmVault.farm.depositAll().then(() => {
                triggerUpdate(props.farmVault).finally(() => {
                  init();
                });
              });
            } else {
              triggerUpdate(props.farmVault).finally(() => {
                init();
              });
            }
          });
        } else if (s === 'stake') {
          response = props.farmVault.farm
            .depositInVaultToken(formatedAmount)
            .then(() => {
              triggerUpdate(props.farmVault).finally(() => {
                init();
              });
            });
        } else if (s === 'unstake') {
          response = props.farmVault.farm
            .withdrawInVaultToken(formatedAmount)
            .then(() => {
              if (isAll.value) {
                triggerUpdate(props.farmVault).then(() => {
                  const withdrawAmount = props.farmVault?.vault.stats
                    .userBalanceVaultInTokenNormalized
                    ? fw(
                        props.farmVault.vault.stats
                          .userBalanceVaultInTokenNormalized
                      )
                    : 0;
                  const formatedWithdrawAmount = props.farmVault?.vault.unapplyTokenDecimals(
                    bn(tw(`${withdrawAmount}`))
                  );
                  props.farmVault?.vault
                    .withdrawInToken(formatedWithdrawAmount)
                    .then(() => {
                      triggerUpdate(props.farmVault).finally(() => {
                        init();
                      });
                    });
                });
              } else {
                triggerUpdate(props.farmVault).finally(() => {
                  init();
                });
              }
            });
        } else if (s === 'withdraw') {
          response = props.farmVault.vault
            .withdrawInToken(formatedAmount)
            .then(() => {
              triggerUpdate(props.farmVault).finally(() => {
                init();
              });
            });
        }
      } else {
        if (s !== 'harvest') {
          formatedAmount = bn(tw(`${amount.value}`));
        }
        if (s === 'unstake') {
          response = props.farmVault?.farm.withdraw(formatedAmount).then(() => {
            triggerUpdate(props.farmVault).finally(() => {
              init();
            });
          });
        } else if (s === 'stake') {
          response = props.farmVault?.farm.deposit(formatedAmount).then(() => {
            triggerUpdate(props.farmVault).finally(() => {
              init();
            });
          });
        }
      }
      if (s === 'harvest') {
        response = props.farmVault?.farm.harvest().then(() => {
          triggerUpdate(props.farmVault).finally(() => {
            init();
          });
        });
      }
      // @ts-ignore
      response.catch(error => {
        console.log(error);
        toast.error(t('alert_transaction_error'));
        init();
      });
    };

    const init = () => {
      status.value = '';
      amount.value = '';
    };

    const gotoStatus = s => {
      if (s === 'withdraw' && props.farmVault?.vault?.withdrawAlert) {
        alert(props.farmVault?.vault.withdrawAlert);
      }
      if (s === 'harvest_to_vault') {
        toast.warning(t('alert_coming_soon'));
        return;
      }
      status.value = s;
      isAll.value = true;
      if (s === 'harvest') {
        doAction(s);
      }
    };

    const getMax = () => {
      isGettingMax.value = true;
      triggerUpdate(props.farmVault)
        .then(() => {
          let temp = null;
          if (props.farmVault?.vault) {
            if (status.value === 'deposit') {
              temp = props.farmVault.vault.stats.userBalanceNormalized;
            } else if (
              status.value === 'stake' ||
              status.value === 'withdraw'
            ) {
              temp =
                props.farmVault.vault.stats.userBalanceVaultInTokenNormalized;
            } else {
              // unstake
              temp =
                props.farmVault.farm.stats
                  .userBalanceFarmInVaultTokenNormalized;
            }
          } else {
            if (status.value === 'stake') {
              temp = props.farmVault?.farm.stats.userBalance;
            } else {
              // unstake
              temp = props.farmVault?.farm.stats.userBalanceFarm;
            }
          }
          amount.value = temp ? fw(temp) : 0;
        })
        .catch(error => {
          console.log(error);
          toast.error(t('alert_get_max_error'));
        })
        .finally(() => {
          isGettingMax.value = false;
        });
    };

    const getBalance = () => {
      if (status.value === 'deposit') {
        return showTokenBalance(
          props.farmVault?.vault.stats.userBalanceNormalized
        );
      } else if (status.value === 'stake' || status.value === 'withdraw') {
        return props.farmVault?.vault
          ? showTokenBalance(
              props.farmVault.vault.stats.userBalanceVaultInTokenNormalized
            )
          : showTokenBalance(props.farmVault?.farm.stats.userBalance);
      } else {
        // unstake
        return showTokenBalance(
          props.farmVault?.farm.stats &&
            props.farmVault.farm.stats.userBalanceFarmInVaultTokenNormalized
            ? props.farmVault.farm.stats.userBalanceFarmInVaultTokenNormalized
            : props.farmVault?.farm.stats.userBalanceFarm
        );
      }
    };

    const goTo = link => {
      if (link.includes('app.acsi.finance')) {
        toggleBuyLpModal(true, poolId.value);
      } else {
        window.open(link, '_blank')?.focus();
      }
    };

    return {
      explorerLinks,
      upToLargeBreakpoint,
      showTokenBalance,
      // computed
      walletInfoList,
      defaultVaultTransactionInfo,
      defaultFarmTransactionInfo,
      buttonList,
      visibleButtonList,
      // data
      poolId,
      isAll,
      isGettingMax,
      status,
      amount,
      // methods
      goTo,
      getBalance,
      gotoStatus,
      init,
      getMax,
      doAction
    };
  }
});
