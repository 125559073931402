
import { defineComponent, computed } from 'vue';
import useVaults from '@/services/useVaults';
import { fwp } from '@/lib/utils/bn';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';

export default defineComponent({
  components: {},

  setup() {
    const { farmVaults } = useVaults();

    // COMPUTED
    const wavVault = computed(
      () =>
        farmVaults.value.find(
          // @ts-ignore
          farmVault => farmVault.vault && farmVault.vault.title == 'TGW Vault'
          // @ts-ignore
        )?.vault
    );

    return {
      fwp,
      showTokenBalance,
      wavVault
    };
  }
});
