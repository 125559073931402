import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Vaults from '@/pages/Vaults.vue';
import BuyNft from '@/pages/BuyNft.vue';
import MyNfts from '@/pages/MyNfts.vue';
import Leaderboard from '@/pages/Leaderboard.vue';
import NftViewer from '@/pages/NftViewer.vue';
import { NFT_DEFAULT_TYPE } from '@/constants';

const routes: RouteRecordRaw[] = [
  { path: '/', name: 'home', redirect: `/buy-nft?type=${NFT_DEFAULT_TYPE}` },
  { path: '/wav-farm', name: 'wav-farm', component: Vaults },
  // { path: '/deprecated', name: 'deprecated_vaults', component: Vaults },
  { path: '/buy-nft', name: 'buy-nft', component: BuyNft },
  { path: '/my-nfts', name: 'my-nfts', component: MyNfts },
  { path: '/leaderboard', name: 'leaderboard', component: Leaderboard },
  { path: '/nft-viewer/:id', name: 'nft-viewer', component: NftViewer },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: `/buy-nft?type=${NFT_DEFAULT_TYPE}`
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;
