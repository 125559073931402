import { ref } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';

/** STATE */
const tokenInAddress = ref('');
const tokenOutAddress = ref('');
const isBuyTokenModalVisible = ref(false);

export default function useBuyTokenModal() {
  const { isSupportedAcsiFinance } = useWeb3();

  // METHODS
  const toggleBuyTokenModal = (value: boolean, tokenIn = '', tokenOut = '') => {
    if (value && !isSupportedAcsiFinance.value) {
      return;
    }
    tokenInAddress.value = tokenIn;
    tokenOutAddress.value = tokenOut;
    if (value !== undefined && typeof value === 'boolean') {
      isBuyTokenModalVisible.value = value;
      return;
    }
    isBuyTokenModalVisible.value = !isBuyTokenModalVisible.value;
  };

  return {
    // refs
    tokenInAddress,
    tokenOutAddress,
    isBuyTokenModalVisible,

    // methods
    toggleBuyTokenModal
  };
}
