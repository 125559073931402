
import { defineComponent } from 'vue';
import useDarkMode from '@/composables/useDarkMode';

export default defineComponent({
  setup() {
    const { darkMode } = useDarkMode();

    return { darkMode };
  }
});
