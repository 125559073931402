
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import useBreakpoints from '@/composables/useBreakpoints';
import useNfts from '@/services/useNfts';
import useWeb3 from '@/services/web3/useWeb3';
import addresses from '@/constants/addresses';
import { shorten } from '@/lib/utils/index';
import { showApr } from '@/lib/utils/web3';
import { TGW_NFT_BASE_URI, tofunft, NFT_TYPE_FULL, NftType } from '@/constants';
import TypeOwner from './TypeOwner.vue';
import BurnAlertModal from './BurnAlertModal.vue';

export default defineComponent({
  components: {
    BurnAlertModal,
    TypeOwner
  },

  setup() {
    const route = useRoute();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { allNfts } = useNfts();
    const { explorerLinks, account } = useWeb3();

    // DATA
    const copiedLink = ref(false);
    const isOpenBurnAlertModal = ref(false);

    // COMPUTED
    const nft = computed(() =>
      allNfts.value.find(item => item && item.id === Number(route.params.id))
    );

    const isUserNft = computed(
      () =>
        nft.value?.owner &&
        account.value &&
        nft.value?.owner.toLowerCase() === account.value.toLowerCase()
    );

    // METHODS
    const downloadImage = async () => {
      if (nft.value && nft.value.image) {
        const url = nft.value.animation_url || nft.value.image;
        const splited = url.split('/');
        const fileName = splited[splited.length - 1];
        const link = document.createElement('a');
        link.href = await fetch(url)
          .then(response => response.blob())
          .then(blob => URL.createObjectURL(blob));
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    const copyLink = () => {
      if (!copiedLink.value) {
        navigator.clipboard.writeText(window.location.href);
        copiedLink.value = true;

        setTimeout(() => {
          copiedLink.value = false;
        }, 2 * 1000);
      }
    };

    const trade = () => {
      if (nft.value?.id || nft.value?.id === 0) {
        window
          .open(`${tofunft}/${addresses.tgwNft}/${nft.value.id}`, '_blank')
          ?.focus();
      }
    };

    return {
      NftType,
      showApr,
      shorten,
      addresses,
      NFT_TYPE_FULL,
      TGW_NFT_BASE_URI,
      explorerLinks,
      upToLargeBreakpoint,
      // computed
      nft,
      isUserNft,
      // data
      copiedLink,
      isOpenBurnAlertModal,
      // methods
      trade,
      copyLink,
      downloadImage
    };
  }
});
