<template>
  <div class="vaults-top">
    <div class="flex flex-wrap">
      <MyTvl class="mb-4 mr-4" />
      <PendingHarvest class="mb-4" />
    </div>
    <TgwBoost class="mb-4" />
    <!-- <div class="w-2/3 flex justify-end"> -->
    <!-- <div class="expert-mode">
        <span class="text-base text-gray-450 mr-4">{{
          $t('expert_mode')
        }}</span>
        <span class="text-sm text-white mr-2">
          {{ $t('off').toUpperCase() }}
        </span>
        <BalToggle size="lg" />
        <span class="text-sm text-white ml-2">
          {{ $t('on').toUpperCase() }}
        </span>
      </div>
      <HarvestOptimizer /> -->
    <!-- </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// import HarvestOptimizer from './HarvestOptimizer.vue';
import MyTvl from './MyTvl.vue';
import PendingHarvest from './PendingHarvest.vue';
import TgwBoost from './TgwBoost.vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  components: {
    MyTvl,
    TgwBoost,
    PendingHarvest
    // HarvestOptimizer
  },

  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();
    return {
      upToLargeBreakpoint
    };
  }
});
</script>
<style scoped>
.vaults-top {
  @apply flex justify-between flex-wrap;
}
.expert-mode {
  @apply flex items-center bg-gray-100 dark:bg-gray-730 px-5 rounded-2xl h-16 mr-5;
  min-width: 300px;
}
</style>
