
import useTailwind from '@/composables/useTailwind';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StarsIcon',

  setup() {
    const { theme } = useTailwind();

    return { theme };
  }
});
