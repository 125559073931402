import { ref } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';

/** STATE */
const isBuyLpModalVisible = ref(false);
const poolId = ref('');

export default function useBuyLpModal() {
  const { isSupportedAcsiFinance } = useWeb3();

  // METHODS
  const toggleBuyLpModal = (value: boolean, id = '') => {
    if (value && !isSupportedAcsiFinance.value) {
      return;
    }
    poolId.value = id;
    if (value !== undefined && typeof value === 'boolean') {
      isBuyLpModalVisible.value = value;
      return;
    }
    isBuyLpModalVisible.value = !isBuyLpModalVisible.value;
  };

  return {
    // refs
    poolId,
    isBuyLpModalVisible,

    // methods
    toggleBuyLpModal
  };
}
