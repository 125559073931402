
import { defineComponent } from 'vue';
import Tags from '../Tags.vue';
import ActionButtons from './ActionButtons.vue';
import AcsBoost from './AcsBoost.vue';
import Rewards from './Rewards.vue';
import { fwp, bn } from '@/lib/vault/bn';
import { Config } from '@/lib/vault/config';
import { showTokenBalance, showApr } from '@/lib/vault/farmVaultUtils';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  components: {
    Rewards,
    AcsBoost,
    ActionButtons,
    Tags
  },

  props: {
    farmVault: { type: Object },
    isLoadingData: { type: Boolean }
  },

  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      bn,
      fwp,
      showApr,
      showTokenBalance,
      upToLargeBreakpoint,
      // data
      Config
    };
  }
});
