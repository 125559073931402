<template>
  <div class="buy-nft">
    <div class="buy-nft-bg">
      <div class="buy-nft-header">
        <img
          class="buy-nft-header-wave-top"
          src="~@/assets/images/wave-top.svg"
        />
        <div class="buy-nft-header-con">
          <div class="ml-6 lg:ml-7">
            <div class="buy-nft-header-top">
              <p class="buy-nft-header-title buy-nft-header-top-title">
                {{ $t('own_the') }}
              </p>
            </div>
            <div class="buy-nft-header-body">
              <p class="buy-nft-header-title">{{ $t('future_of_music') }}</p>
              <p class="buy-nft-header-desc">{{ $t('buy_nft_header_desc') }}</p>
              <p class="buy-nft-header-title buy-nft-header-body-s-title">
                {{ $t('be_part_of') }}
              </p>
            </div>
            <div class="buy-nft-header-bottom">
              <p class="buy-nft-header-title">{{ $t('the_great_wave') }}</p>
            </div>
          </div>
        </div>
        <img
          class="buy-nft-header-wave-bottom"
          src="~@/assets/images/wave-bottom.svg"
        />
      </div>
      <div class="buy-nft-con">
        <div class="buy-nft-body">
          <div class="buy-nft-body-top">
            <!--div class="buy-nft-body-main-top-tgw">
              {{ $t('the_great_wave') }}
            </div-->
            <div>
              <!--p class="buy-nft-body-main-top-sm">
                {{ $t('the_great_wave') }}
              </p>
              <p class="buy-nft-body-main-top-lg">{{ $t('tgw_nft') }}</p-->
              <p class="buy-nft-body-main-top-sm pt-2">
                {{ $t('governance') }}
              </p>
              <p class="buy-nft-body-main-top-lg">
                {{ $t('token').toUpperCase() }}
              </p>
            </div>
          </div>
          <div
            class="buy-nft-body-img"
            v-if="nftTypeData.image"
            :style="{
              'background-image': `url(${nftTypeData.image})`
            }"
          >
            <div class="buy-nft-body-img-mint-fee">
              <div class="buy-nft-body-img-mint-fee-bnb">
                <span class="buy-nft-body-img-mint-fee-bnb-value">{{
                  minBnbAmount.gtn(0) ? fw(minBnbAmount) : '--'
                }}</span>
                <span class="buy-nft-body-img-mint-fee-bnb-unit">BNB</span>
              </div>
              <div class="mt-1 lg:mt-4">
                <p class="buy-nft-body-img-mint-fee-usd-label">
                  {{ $t('min_mint_fee') }}
                </p>
                <p class="buy-nft-body-img-mint-fee-usd-value">
                  ~{{ minMintFeeUsd > 0 ? minMintFeeUsd.toFixed(2) : '--' }} USD
                </p>
              </div>
            </div>
          </div>
          <div class="buy-nft-body-main">
            <div class="buy-nft-body-main-top">
              <!--div class="buy-nft-body-main-top-tgw">
                {{ $t('the_great_wave') }}
              </div>
              <div>
                <p class="buy-nft-body-main-top-sm">
                  {{ $t('the_great_wave') }}
                </p>
                <p class="buy-nft-body-main-top-lg">{{ $t('tgw_nft') }}</p>
              </div-->
              <div>
                <p class="buy-nft-body-main-top-sm">{{ $t('governance') }}</p>
                <p class="buy-nft-body-main-top-lg">
                  {{ $t('token').toUpperCase() }}
                </p>
              </div>
            </div>
            <!--div class="buy-nft-body-main-released">
              {{ $t('released_date_time') }}
            </div-->
            <div class="buy-nft-body-main-season">
              {{ nftTypeData.name || '' }}
            </div>
            <div
              v-if="nftTypeData.description"
              class="buy-nft-body-main-desc"
              v-html="nftTypeData.description.replaceAll('\n', '<br />')"
            />
            <div class="buy-nft-body-main-balance">
              {{ $t('balance') }}:
              <span class="cursor-pointer" @click="setMax()">{{
                userBnbBalance
                  ? `${showTokenBalance(userBnbBalance)} BNB`
                  : '--'
              }}</span>
            </div>
            <div class="buy-nft-body-main-action-con">
              <input
                type="number"
                :min="minBnbAmount.gtn(0) ? fw(minBnbAmount) : 0.1"
                v-model="stakeMintAmount"
                :placeholder="
                  `${minBnbAmount.gtn(0) ? fw(minBnbAmount) : 0.1} BNB`
                "
                class="buy-nft-body-main-action-input"
              />
              <BalBtn
                rounded
                color="sky-dark"
                class="buy-nft-body-main-action-btn"
                @click="stakeMintAction"
                :loading="stakeMinting"
                :loading-label="$t('minting')"
              >
                <span class="buy-nft-body-main-action-btn-label">
                  {{ $t('stake_mint') }}
                </span>
              </BalBtn>
            </div>
            <div class="buy-nft-body-main-bottom">
              <template v-if="nftTypeData._mint_message">
                <p>{{ nftTypeData._mint_message }}</p>
                <br
              /></template>
              <p
                v-html="
                  $t('nft_mint_fee_desc', [
                    minBnbAmount.gtn(0) ? fw(minBnbAmount) : 0.1,
                    typeMintFeesDesc.gtn(0) ? fw(typeMintFeesDesc) : 0.012
                  ])
                "
              />
              <br />
              <p>{{ $t('connect_your_wallet_to_mint') }}</p>
              <p>{{ $t('tgw_nft_desc') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="buy-nft-footer">
        <div class="buy-nft-con">
          <div class="buy-nft-footer-notes">{{ $t('notes') }}</div>
          <div class="buy-nft-footer-detail">
            <div class="buy-nft-footer-detail-tgw-token-desc">
              {{ $t('tgw_token_desc') }}
            </div>
            <div class="buy-nft-footer-detail-nft">
              <p class="font-bold">
                {{ $t('the_governance_nft') }}
              </p>
              <p class="buy-nft-footer-detail-nft-desc">
                {{ $t('the_governance_nft_desc1') }}
              </p>
              <p class="buy-nft-footer-detail-nft-desc">
                {{ $t('the_governance_nft_desc2') }}
              </p>
              <p class="buy-nft-footer-detail-nft-desc">
                {{ $t('the_governance_nft_desc3') }}
              </p>
              <p class="buy-nft-footer-detail-nft-desc">
                {{ $t('the_governance_nft_desc4') }}
              </p>
              <p
                class="buy-nft-footer-detail-nft-desc"
                v-html="
                  $t('the_governance_nft_desc5', [
                    typeMintFeesDesc.gtn(0) ? fw(typeMintFeesDesc) : 0.012
                  ])
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <StakeMintRedirectModal
    :isVisible="showModal"
    :link="link"
    :image="image"
    @close="showModal = false"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useNfts from '@/services/useNfts';
import useVaults from '@/services/useVaults';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';
import { fw, bn } from '@/lib/utils/bn';
import { NFT_DEFAULT_TYPE } from '@/constants';
import StakeMintRedirectModal from '@/components/modals/StakeMintRedirectModal.vue';

export default defineComponent({
  components: { StakeMintRedirectModal },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      stakeMint,
      minBnbAmount,
      userBnbBalance,
      nftTypesData,
      typeMintFeesDesc
    } = useNfts();
    const { acsStats } = useVaults();

    // COMPUTED
    // const balance = computed(() => {
    //   const acsVaultFarm = formatedFarmVaults.value.find(
    //     item => item.name === 'ACS'
    //   );
    //   if (acsVaultFarm.walletEmpty) {
    //     return null;
    //   } else {
    //     return acsVaultFarm.vault.stats.userBalanceNormalized;
    //   }
    // });
    const minMintFeeUsd = computed(() =>
      // @ts-ignore
      acsStats.value?.bnbPriceUsd
        ? // @ts-ignore
          Number(acsStats.value.bnbPriceUsd) * Number(fw(minBnbAmount.value))
        : 0
    );
    const nftType = computed(() => route.query.type || NFT_DEFAULT_TYPE);
    const nftTypeData = computed(
      () => nftTypesData.value[Number(nftType.value)] || {}
    );

    // DATA
    const showModal = ref(false);
    const stakeMinting = ref(false);
    const stakeMintAmount = ref('0.1');
    const link = ref('');
    const image = ref('');

    // Watchers
    watch(minBnbAmount, (newVal, oldVal) => {
      if (
        Number(fw(oldVal)) !== Number(fw(newVal)) &&
        (fw(oldVal) === '0' ||
          Number(fw(oldVal)) === Number(stakeMintAmount.value) ||
          Number(fw(newVal)) > Number(stakeMintAmount.value))
      ) {
        stakeMintAmount.value = fw(newVal);
      }
    });
    watch(nftTypeData, newVal => {
      if (!newVal.id) {
        router.push({
          path: '/buy-nft',
          query: { type: NFT_DEFAULT_TYPE }
        });
      }
    });

    // METHODS
    const stakeMintAction = async () => {
      stakeMinting.value = true;
      const res: any = await stakeMint(stakeMintAmount.value, nftType.value);
      stakeMinting.value = false;

      //TODO remove hardcode replace
      if (res && nftTypeData.value._post_mint_redirect) {
        link.value = nftTypeData.value._post_mint_redirect.replace(
          '{{tx}}',
          res.transactionHash
        );
        image.value = res.image;
        showModal.value = true;
      }
    };
    const goTo = des => {
      router.push({ path: `/${des}` });
    };
    const setMax = () => {
      if (userBnbBalance.value) {
        stakeMintAmount.value = fw(userBnbBalance.value);
      }
    };

    return {
      bn,
      fw,
      minBnbAmount,
      typeMintFeesDesc,
      userBnbBalance,
      showTokenBalance,
      // computed
      nftTypeData,
      // balanceShown,
      minMintFeeUsd,
      // data
      link,
      image,
      showModal,
      stakeMinting,
      stakeMintAmount,
      // methods
      goTo,
      setMax,
      stakeMintAction
    };
  }
});
</script>

<style scoped>
@media (max-width: 1024px) {
  .buy-nft-header-title {
    font-size: 42px;
    line-height: 42px;
  }
  .buy-nft-body-img {
    @apply mx-auto;
    border-radius: 22px;
    max-width: 488px;
  }
  .buy-nft-body-img-mint-fee {
    margin-top: calc(100% - 45px);
  }
  .buy-nft-body-main-season {
    font-size: 32px;
  }
  .buy-nft-bg {
    background-size: 1024px;
  }
  .buy-nft-header {
    @apply overflow-hidden;
  }
  .buy-nft-header-wave-bottom {
    bottom: 80px;
  }
}
@media (min-width: 1024px) {
  .buy-nft-header-title {
    @apply text-4xl;
  }
  .buy-nft-body-img {
    border-radius: 28px;
  }
  .buy-nft-body-img-mint-fee {
    margin-top: calc(100% - 74px);
  }
  .buy-nft-body-main-season {
    font-size: 40px;
  }
  .buy-nft-bg {
    background-size: cover;
  }
  .buy-nft-header-wave-bottom {
    bottom: -80px;
  }
}
.buy-nft {
  @apply h-screen overflow-auto;
}
.buy-nft-bg {
  @apply w-full;
  background-color: #070709;
  background-image: url('~@/assets/images/buy-nft-bg.png');
  background-position: left top;
  background-repeat: no-repeat;
}
.buy-nft-con,
.buy-nft-header-con {
  @apply mx-auto px-5 relative;
  max-width: 1136px;
  z-index: 1;
}
.buy-nft-header {
  @apply text-white relative;
}
.buy-nft-header-con {
  @apply pt-40 lg:pt-32 pb-32;
}
.buy-nft-header-wave-top,
.buy-nft-header-wave-bottom {
  max-width: calc(max(1280px, 100%));
}
.buy-nft-header-wave-top {
  @apply absolute lg:w-full;
}
.buy-nft-header-top-title {
  width: 41%;
}
.buy-nft-header-title {
  @apply font-bold;
  font-family: 'Exo 2';
}
.buy-nft-header-body {
  @apply mt-4 lg:mt-8 mb-4 lg:mb-9;
  margin-left: calc((100% - 328px) * 0.09 + 58px);
}
.buy-nft-header-desc {
  @apply text-base leading-8 mt-8 lg:mt-12 mb-9 lg:mb-14;
  width: calc((100% - 328px) * 0.23 + 276px);
  font-family: 'Fira Sans';
}
.buy-nft-header-body-s-title {
  width: calc((100% - 328px) * 0.25 + 150px);
}
.buy-nft-header-bottom {
  margin-left: calc((100% - 328px) * 0.19 + 122px);
  width: min((100% - 328px) * 0.37 + 243px), 100%;
}
.buy-nft-header-wave-bottom {
  @apply absolute lg:w-full z-0;
}
.buy-nft-body {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-10 p-4 lg:p-10 mb-14 text-black;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
}
.buy-nft-body-top {
  @apply grid grid-cols-2 gap-8 my-2 items-center block lg:hidden mx-auto;
  font-family: 'Exo 2';
  max-width: 488px;
}
.buy-nft-body-img {
  @apply w-full overflow-hidden text-white;
  height: fit-content;
  background-image: url('~@/assets/images/buy-nft-body-img.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.buy-nft-body-img-mint-fee {
  @apply flex items-center bg-slate-520;
  @apply rounded-tr-2xl pr-5 pl-6 -ml-2.5 py-0.5 lg:py-1.5;
  transform: skewX(calc(28deg)) translate3d(0, 0, 0);
  width: fit-content;
  font-family: 'Fira Sans';
}
.buy-nft-body-img-mint-fee > * {
  display: block;
  transform: skewX(calc(-28deg));
}
.buy-nft-body-img-mint-fee-bnb {
  @apply mr-3 text-sky-420;
}
.buy-nft-body-img-mint-fee-bnb-value {
  @apply text-2xl lg:text-4xl font-semibold mr-2;
}
.buy-nft-body-img-mint-fee-bnb-unit {
  @apply text-xl lg:text-2xl;
}
.buy-nft-body-img-mint-fee-usd-label {
  @apply text-3xs lg:text-xs font-medium;
  line-height: 10px;
}
.buy-nft-body-img-mint-fee-usd-value {
  @apply text-sssm lg:text-base text-gray-310 leading-4 mt-0 lg:mt-0.5;
}
.buy-nft-body-main-top {
  @apply grid-cols-3 gap-5 mb-16 hidden lg:grid;
  font-family: 'Exo 2';
}
.buy-nft-body-main-top-tgw {
  @apply font-extrabold text-2xl leading-8;
}
.buy-nft-body-main-top-sm {
  @apply font-normal text-base;
}
.buy-nft-body-main-top-lg {
  @apply font-semibold text-xl leading-7;
}
.buy-nft-body-main-released {
  @apply font-medium text-ssm lg:text-smd mt-8 mb-3.5;
  font-family: 'Fira Sans';
}
.buy-nft-body-main-season {
  @apply font-extrabold leading-8 mb-3.5 lg:mb-5;
  font-family: 'Exo 2';
  line-height: 1.1;
}
.buy-nft-body-main-desc {
  @apply mb-10 lg:mb-7 text-sm leading-6;
  font-family: 'Fira Sans';
}
.buy-nft-body-main-balance {
  @apply italic pl-4 font-medium text-sm;
  font-family: 'Exo 2';
}
.buy-nft-body-main-action-con {
  @apply grid grid-cols-1 sm:grid-cols-2 gap-2 lg:gap-4;
  font-family: 'Exo 2';
}
.buy-nft-body-main-action-input {
  @apply h-12 box-border border-2 border-sky-420 rounded-full flex items-center justify-center px-4;
  @apply w-full bg-transparent text-right text-lg font-bold;
  letter-spacing: 0.01em;
}
.buy-nft-body-main-action-btn {
  @apply mb-4 w-full;
  line-height: 1 !important;
}
.buy-nft-body-main-action-btn-label {
  @apply font-bold;
  font-family: 'Exo 2';
}
.buy-nft-body-main-bottom {
  @apply text-sm leading-6 font-semibold;
}
.buy-nft-footer {
  @apply py-20 text-white;
  background: rgba(0, 0, 0, 0.8);
}
.buy-nft-footer-notes {
  @apply mx-7 font-extrabold text-center lg:text-left;
  font-size: 24px;
  line-height: 29px;
  font-family: 'Exo 2';
}
.buy-nft-footer-detail {
  @apply grid grid-cols-1 lg:grid-cols-3 gap-5 mx-7 mt-5 lg:mt-4;
  font-family: 'Fira Sans';
}
.buy-nft-footer-detail-tgw-token-desc {
  font-size: 24px;
  line-height: 32px;
}
.buy-nft-footer-detail-nft {
  @apply text-sm leading-6;
}
.buy-nft-footer-detail-nft-desc {
  @apply mt-6;
}
</style>
