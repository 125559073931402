<template>
  <Leaderboard />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Leaderboard from '@/components/leaderboard/index.vue';

export default defineComponent({
  components: {
    Leaderboard
  }
});
</script>
