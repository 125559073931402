require('promise.any').shim(); // needed for android 4 - browserslist doesn't work for this

import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { networkId } from '@/composables/useNetwork';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

class Connect extends EventTarget {
  web3Ago;
  web3Modal;
  account;
  publicWeb3;
  web3;
  connectedWallet;
  chainId;
  networkConfig;
  connectStatus;

  constructor() {
    super();
  }

  async init(networkConfig) {
    // this.init = async () => {};
    this.networkConfig = networkConfig;
    this._initWeb3Modal();
    this.web3Ago = new Web3('https://arc2.acryptos.com/');
    await this._initPublicRpcs();
    this._initUserRpc(); // note this may error out, so no await
  }

  _initWeb3Modal() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          rpc: {
            56: 'https://bsc-dataseed.binance.org/'
          },
          network: 'binance',
          chainId: 56
        }
      },
      'custom-binancechainwallet': {
        display: {
          logo: '/images/connectors/binance.svg',
          name: 'Binance Chain Wallet',
          description: 'Connect to your Binance Chain Wallet'
        },
        package: true,
        connector: async () => {
          let provider = null;
          // @ts-ignore
          if (typeof window.BinanceChain !== 'undefined') {
            // @ts-ignore
            provider = window.BinanceChain;
            try {
              // @ts-ignore
              await provider.request({ method: 'eth_requestAccounts' });
            } catch (error) {
              throw new Error('User Rejected');
            }
          } else {
            throw new Error('No Binance Chain Wallet found');
          }
          return provider;
        }
      }
    };
    this.web3Modal = new Web3Modal({
      theme: {
        background: fullConfig.theme.colors.gray['750'],
        main: 'rgb(199, 199, 199)',
        secondary: 'rgb(136, 136, 136)',
        border: 'rgba(195, 195, 195, 0.14)',
        hover: fullConfig.theme.colors.gray['730']
      },
      network: 'binance', // optional
      cacheProvider: true, // optional
      providerOptions
    });
  }

  handleAccountsChanged(accounts) {
    if (accounts.length === 0 && this.account) {
      this.account = '';
      this.setConnectStatus('disconnected');
    } else if (this.account !== accounts[0]) {
      this.account = accounts[0];
      this.setConnectStatus('connected');
    }
    this.dispatchEvent(new CustomEvent('accountChanged'));
  }

  async _initPublicRpcs() {
    const web3 = await Promise.any(
      shuffleArray([
        'https://bsc-dataseed.binance.org/',
        'https://bsc-dataseed1.defibit.io/',
        'https://bsc-dataseed1.ninicoin.io/',
        'https://bsc-dataseed2.defibit.io/',
        'https://bsc-dataseed3.defibit.io/',
        'https://bsc-dataseed4.defibit.io/',
        'https://bsc-dataseed2.ninicoin.io/',
        'https://bsc-dataseed3.ninicoin.io/',
        'https://bsc-dataseed4.ninicoin.io/',
        'https://bsc-dataseed1.binance.org/',
        'https://bsc-dataseed2.binance.org/',
        'https://bsc-dataseed3.binance.org/',
        'https://bsc-dataseed4.binance.org/'
      ])
        .slice(0, 3)
        .map(async rpc => {
          const web3 = new Web3(rpc);
          const validProvider = await checkProvider(web3);
          if (validProvider) {
            return web3;
          } else {
            throw new Error('invalid');
          }
        })
    );
    this.publicWeb3 = web3;
    this.web3 = this.web3 || web3;
  }

  async _initUserRpc() {
    if (this.web3Modal.cachedProvider) {
      this.setUserProvider(await this.web3Modal.connect());
    }
  }

  setConnectStatus(value) {
    this.connectStatus = value;
    this.dispatchEvent(new CustomEvent('updateConnectStatus'));
  }

  async connect() {
    try {
      await this.web3Modal.clearCachedProvider();
      const provider = await this.web3Modal.connect();
      const isSucceed = await this.setUserProvider(provider);
      if (!isSucceed) {
        throw new Error('invalid');
      }
    } catch (error) {
      this.setConnectStatus('disconnected');
      throw new Error();
    }
  }

  disconnect = async () => {
    await this.web3Modal.clearCachedProvider();
    this.handleAccountsChanged([]);
  };

  async setUserProvider(provider) {
    try {
      this.setConnectStatus('connecting');
      if (parseInt(provider.chainId) !== networkId.value) {
        const switchSuccess = await switchToAppNetwork(
          provider,
          this.networkConfig
        );
        if (!switchSuccess) {
          this.setConnectStatus('disconnected');
          return false;
        }
      }
      this.connectedWallet = this.web3Modal.cachedProvider;

      //provider.autoRefreshOnNetworkChange = false;
      provider.on('chainChanged', async chainId => {
        if (parseInt(chainId) !== networkId.value) {
          window.location.reload();
        }
      });

      if (this.web3) {
        this.web3.setProvider(provider);
      } else {
        this.web3 = new Web3(provider);
      }

      /***********************************************************/
      /* Handle user accounts and accountsChanged (per EIP-1193) */
      /***********************************************************/
      await provider
        .request({ method: 'eth_accounts' })
        .then(accounts => this.handleAccountsChanged(accounts))
        .catch(err => {
          // Some unexpected error.
          // For backwards compatibility reasons, if no accounts are available,
          // eth_accounts will return an empty array.
          console.error(err);
        });

      provider.on('accountsChanged', accounts => {
        this.handleAccountsChanged(accounts);
        // if (provider === this.web3.currentProvider) {
        // }
      });
      // provider.on('disconnect', () => {
      //   if (provider === this.web3.currentProvider) {
      //     this.handleAccountsChanged([]);
      //   }
      // });
      return true;
    } catch (error) {
      this.setConnectStatus('disconnected');
      console.error(error);
      return false;
    }
  }

  async send(tx, options = {}) {
    const gasPrice = parseInt(await this.web3.eth.getGasPrice()) + 1;
    // console.log('Sending tx', tx);
    return new Promise((resolve, reject) => {
      tx.send({
        from: this.account,
        // gasPrice: 5e9,
        gasPrice: gasPrice,
        ...options
      })
        .on('transactionHash', function() {
          // console.log('tx hash', hash);
        })
        .catch(e => {
          console.error(e);
          //alert(JSON.stringify(e, null, 2));
          reject(e);
        })
        .then(receipt => {
          // console.log('tx receipt', receipt);
          // if(!resolveOnSent)
          resolve(receipt);
        });
    });
  }
}

async function checkProvider(web3) {
  const acsC = new web3.eth.Contract(
    [
      {
        constant: !0,
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: !1,
        stateMutability: 'view',
        type: 'function'
      }
    ],
    '0x4197C6EF3879a08cD51e5560da5064B773aa1d29'
  ); // ACS token
  const validProvider =
    (await acsC.methods
      .name()
      .call()
      .catch(e => e)) === 'ACryptoS';
  return validProvider;
}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

async function switchToAppNetwork(provider, networkConfig) {
  const hexChainId = `0x${networkConfig.chainId.toString(16)}`;
  try {
    if (provider.request) {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }]
      });
      return true;
    }
  } catch (error) {
    console.error(error);
    // user rejected request
    // @ts-ignore
    if (error.code === 4001) {
      return false;
    }
    // chain does not exist, let's add it
    // @ts-ignore
    if (error.code === 4902) {
      return importNetworkDetailsToWallet(provider, networkConfig);
    }
  }
  return false;
}

async function importNetworkDetailsToWallet(provider, networkConfig) {
  const hexChainId = `0x${networkConfig.chainId.toString(16)}`;
  try {
    const request = {
      id: '1',
      jsonrpc: '2.0',
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: hexChainId,
          chainName: networkConfig.name,
          rpcUrls: [networkConfig.rpc],
          iconUrls: [networkConfig.nativeAsset.logoURI],
          nativeCurrency: {
            name: networkConfig.nativeAsset.name,
            symbol: networkConfig.nativeAsset.symbol,
            decimals: networkConfig.nativeAsset.decimals
          },
          blockExplorerUrls: [networkConfig.explorer]
        }
      ]
    };
    if (provider?.request) {
      const response = await provider.request(request);
      if (response?.error) {
        throw new Error(
          `Failed to add network information to wallet. ${response.error.code}:${response.error.message}`
        );
      }
      return true;
    } else {
      throw new Error(`Could not find an external provider with 'request'`);
    }
  } catch (err) {
    console.error(
      `An error occurred while attempting to add network information to wallet. ${
        (err as Error).message
      }`
    );
    return false;
  }
}

export { Connect };
