<template>
  <div class="relative" @mouseleave="showLocaleMenu = false">
    <BalBtn
      circle
      color="sky-dark"
      outline
      :size="upToLargeBreakpoint ? 'xs' : 'sm'"
      @mouseover="showLocaleMenu = true"
    >
      {{ appLocale.split('-')[1] }}
    </BalBtn>
    <ul v-if="showLocaleMenu" class="locale-container">
      <li
        v-for="(locale, localeKey) in locales"
        :key="localeKey"
        class="locale-item"
        :class="{ active: appLocale === localeKey }"
        @click="setLocale(localeKey)"
      >
        {{ locales[localeKey] }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  name: 'SetLocale',

  setup() {
    const store = useStore();
    const { upToLargeBreakpoint } = useBreakpoints();

    // COMPUTED
    const appLocale = computed(() => store.state.app.locale);

    // DATA
    const showLocaleMenu = ref(false);
    const locales = ref({
      'en-US': 'English',
      'zh-CN': '简体中文',
      'vi-VN': 'Tiếng Việt',
      'es-ES': 'Español',
      'de-DE': 'Deutsch',
      'tr-TR': 'Türk',
      'it-IT': 'Italiano',
      'pt-PT': 'Português',
      'fr-FR': 'Français'
      // 'ru-RU': 'Россия',
      // 'ko-KR': '한국어',
      // 'ja-JP': '日本語',
      // 'hi-IN': 'हिंदी',
      // 'ar-AE': 'عربى'
    });

    // METHODS
    const setLocale = locale => {
      store.commit('app/setLocale', locale);
      showLocaleMenu.value = false;
    };

    return {
      upToLargeBreakpoint,
      // data
      locales,
      showLocaleMenu,
      // computed
      appLocale,
      //methods
      setLocale
    };
  }
});
</script>
<style scoped>
.locale-container {
  @apply absolute z-10 rounded-2xl bg-white bg-gray-780 -left-20 lg:-left-16;
}
.locale-item {
  @apply w-28 flex justify-center items-center cursor-pointer bg-gray-780 text-yellow-550 hover:bg-yellow-550 hover:text-gray-780 rounded-2xl h-8 lg:h-9 text-sm;
}
</style>
