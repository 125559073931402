<template>
  <div class="toggle" :class="[{ on: toggled }, size]" @click="toggle"></div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {},

  props: {
    size: {
      type: String,
      default: 'sm'
    },
    initValue: {
      type: Boolean,
      default: false
    }
  },

  emits: ['onChange'],

  setup(props, { emit }) {
    // DATA
    const toggled = ref(props.initValue);
    // METHOD
    const toggle = () => {
      toggled.value = !toggled.value;
      emit('onChange', toggled.value);
    };
    return {
      // data
      toggled,
      // method
      toggle
    };
  }
});
</script>

<style scoped>
.sm {
  @apply w-10 h-4 border;
}
.lg {
  @apply border-2 h-8;
  width: 82px;
}
.toggle {
  @apply rounded-full box-border border-gray-450 relative p-0.5;
  transition: border-color 0.25s;
}

.sm.toggle:before {
  @apply w-5;
  height: 10px;
}
.lg.toggle:before {
  @apply w-10 h-6;
}
.toggle:before {
  content: '';
  @apply rounded-full block bg-gray-450;
  transition: background-color 0.25s, transform 0.25s;
}

.toggle.on {
  @apply border-blue-520 dark:border-green-490;
}

.sm.toggle.on:before {
  transform: translateX(13px);
}
.lg.toggle.on:before {
  transform: translateX(33px);
}
.toggle.on:before {
  @apply bg-blue-520 dark:bg-green-490;
}
</style>
