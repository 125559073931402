<template>
  <BalModal
    :show="isVisible"
    width="sm"
    rounded="top-left-to-bottom-right"
    @close="$emit('close')"
  >
    <div class="stake-mint-redirect-popup-title">
      {{ $t('nft_mint_success') }}
    </div>
    <img :src="image" class="my-4" />
    <div class="stake-mint-redirect-popup-label">
      Your NFT is successfully minted. You are being redirected to
      <a :href="link" class="underline">here</a>
    </div>
  </BalModal>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue';

export default defineComponent({
  emits: ['close'],

  props: {
    link: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const timeout = ref();

    watch(
      () => props.isVisible,
      newVal => {
        clearTimeout(timeout.value);
        if (newVal) {
          timeout.value = window.setTimeout(function() {
            window.location.href = props.link;
          }, 5000);
        }
      }
    );
  }
});
</script>

<style scoped>
.stake-mint-redirect-popup-title {
  @apply my-2 text-base font-bold text-center;
  line-height: 140%;
}
.stake-mint-redirect-popup-label {
  @apply text-sm font-medium;
}
</style>
