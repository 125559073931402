<template>
  <div class="leaderboard-bg">
    <div class="leaderboard-con">
      <div class="leaderboard-title">
        {{ $t('leaderboard').toUpperCase() }}
      </div>
      <div class="leaderboard-list-header">
        <div @click="sort('name')">
          {{ $t('leaderboard').toUpperCase() }}
        </div>
        <div v-if="!upToLargeBreakpoint" @click="sort('id')">
          {{ $t('nos').toUpperCase() }}
        </div>
        <div @click="sort('stakedWvp')">
          {{ $t('price').toUpperCase() }}
        </div>
        <div @click="sort('stakedTgw')">
          {{ $t('staked').toUpperCase() }}
        </div>
        <!--div @click="sort('stakedWvp')">
          {{ $t('staked').toUpperCase() }}
        </div-->
        <div>
          {{ $t('type') }}
        </div>
      </div>
      <div class="leaderboard-list">
        <div
          v-for="(item, index) in showedTypeNfts"
          :key="index"
          class="leaderboard-list-item"
          @click="gotoNftViewer(item.id)"
        >
          <div>
            {{ item.name || '--' }}
          </div>
          <div v-if="!upToLargeBreakpoint">#{{ item.id }}</div>
          <div>
            {{ item.stakedWvpUsd ? `$${showApr(item.stakedWvpUsd)}` : '--' }}
          </div>
          <div>
            {{ item.stakedTgw ? `${showApr(item.stakedTgw)} TGW` : '--' }}
          </div>
          <!--div>
            {{ item.stakedWvp ? `${showApr(item.stakedWvp)} WVP` : '--' }}
          </div-->
          <div>{{ item.tokenType ? NFT_TYPE[item.tokenType] : '--' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useBreakpoints from '@/composables/useBreakpoints';
import useNfts from '@/services/useNfts';
import useVaults from '@/services/useVaults';
import { sortArray } from '@/lib/utils/index';
import { showApr } from '@/lib/utils/web3';
import { NFT_TYPE, NFT_DEFAULT_TYPE } from '@/constants';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { allNfts, totalSupply, nftTypesData } = useNfts();
    const { formatedFarmVaults } = useVaults();

    // DATA
    const sortBy = ref('rank');
    const sortOrder = ref(false);

    // COMPUTED
    const nftType = computed(() => route.query.type || NFT_DEFAULT_TYPE);
    const nftTypeData = computed(
      () => nftTypesData.value[Number(nftType.value)] || {}
    );
    const typeNfts = computed(() =>
      //sortArray(allNfts.value, 'stakedWvp', false).filter(
      // nft => nft.tokenType === nftType.value
      //)
      sortArray(allNfts.value, 'stakedTgw', false).filter(
        nft => nft.tokenType === nftType.value
      )
    );
    const showedTypeNfts = computed(() =>
      sortArray(typeNfts.value, sortBy.value, sortOrder.value)
    );

    const totalStaked = computed(() =>
      /*allNfts.value.length
        ? allNfts.value.reduce(
            (total, item) => (item.stakedWvp || 0) + total,
            0
          )
        : 0
        */
      allNfts.value.length
        ? allNfts.value.reduce(
            (total, item) => (item.stakedTgw || 0) + total,
            0
          )
        : 0
    );

    const currentApy = computed(
      () =>
        formatedFarmVaults.value.find(item => item.name === 'TGW Vault')
          ?.apyShown || '--'
    );

    // Watchers
    watch(nftTypeData, newVal => {
      if (!newVal.id) {
        router.push({
          path: '/leaderboard',
          query: { type: NFT_DEFAULT_TYPE }
        });
      }
    });

    // METHODS
    const sort = key => {
      sortBy.value = key;
      sortOrder.value = !sortOrder.value;
    };
    const gotoNftViewer = nftId => {
      router.push({ path: `/nft-viewer/${nftId}` });
    };

    return {
      showApr,
      NFT_TYPE,
      totalSupply,
      upToLargeBreakpoint,
      // computed
      currentApy,
      totalStaked,
      showedTypeNfts,
      // methods
      gotoNftViewer,
      sort
    };
  }
});
</script>

<style scoped>
.leaderboard-bg {
  @apply w-full bg-transparent overflow-auto;
  background-image: url('~@/assets/images/nft-main-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.leaderboard-con {
  @apply mx-auto py-32 px-5;
  max-width: 1136px;
}
.leaderboard-title {
  @apply text-2xl lg:text-4xl;
  @apply text-white font-bold text-center;
  font-family: 'Exo 2';
}
.leaderboard-stats {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-5 mt-6;
}
.leaderboard-list-header {
  @apply bg-sky-420 grid gap-2 text-white font-bold pt-4 pb-2 px-8 text-sssm lg:text-md items-baseline mt-12;
  @apply grid-cols-5 lg:grid-cols-6;
  border-radius: 30px 30px 0px 0px;
}
.leaderboard-list-header > * {
  @apply cursor-pointer;
}
.leaderboard-list-header div:first-child {
  @apply text-lg lg:text-2xl col-span-2;
  font-family: 'Exo 2';
}
.leaderboard-list-header div:not(:first-child) {
  font-family: 'Fira Sans';
}
.leaderboard-list {
  @apply text-white box-border border-2 border-sky-420 bg-black bg-opacity-60 pt-4 pb-6 px-8;
  border-radius: 0px 0px 30px 30px;
  font-family: 'Fira Sans';
  min-height: 200px;
}
.leaderboard-list-item {
  @apply grid grid-cols-5 lg:grid-cols-6 gap-5 py-4 text-sssm lg:text-base cursor-pointer items-center;
  border-bottom: 1px dashed #cde6e9;
}
.leaderboard-list-item div:first-child {
  @apply col-span-2;
}
.leaderboard-list::-webkit-scrollbar {
  @apply h-3 w-3 rounded-2xl;
  background: rgba(180, 179, 183, 0.2);
}
.leaderboard-list::-webkit-scrollbar-thumb {
  @apply bg-gray-450 rounded-2xl border-solid;
  border-width: 3px;
  border-color: rgba(180, 179, 183, 0.2);
}
.leaderboard-list-no-nft {
  @apply py-16 text-center;
}
</style>
