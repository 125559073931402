<template>
  <div class="pending-harvest">
    <div class="pending-harvest-label">{{ $t('pending_harvest') }}:</div>
    <div class="pending-harvest-value">
      <p>
        {{
          acsStats?.wavTotalHarvestPending?.gtn(0)
            ? `${showTokenBalance(acsStats.wavTotalHarvestPending)} 
                  WAV 
                  ($${fwp(acsStats.wavTotalHarvestPendingUsd)})`
            : '--'
        }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useVaults from '@/services/useVaults';
import { fwp } from '@/lib/utils/bn';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';

export default defineComponent({
  components: {},

  setup() {
    const { acsStats } = useVaults();

    return {
      fwp,
      showTokenBalance,
      acsStats
    };
  }
});
</script>
<style scoped>
.pending-harvest {
  @apply flex items-center bg-white dark:bg-gray-730 px-4 rounded-2xl h-16;
  max-width: 360px;
  min-width: 288px;
}
.pending-harvest-label {
  @apply font-medium text-sssm lg:text-sm text-purple-650 lg:text-black dark:text-white w-16;
}
.pending-harvest-value {
  @apply ml-4 text-sm lg:text-base font-semibold text-green-530;
}
</style>
