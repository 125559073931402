
import useDarkMode from '@/composables/useDarkMode';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'BalLoadingBlock',

  props: {
    white: { type: Boolean, default: false },
    darker: { type: Boolean, default: false },
    rounded: {
      type: String,
      default: 'lg',
      validator: (val: string): boolean =>
        ['sm', 'md', 'lg', '2xl'].includes(val)
    }
  },

  setup(props) {
    const { darkMode } = useDarkMode();

    const bgClass = computed(() => {
      if (props.white) return 'shimmer-white';
      if (darkMode.value) {
        return props.darker ? 'shimmer-dark-mode-darker' : 'shimmer-dark-mode';
      }
      return props.darker ? 'shimmer-light-mode-darker' : 'shimmer-light-mode';
    });

    const classes = computed(() => {
      return {
        [`rounded-${props.rounded}`]: true,
        [bgClass.value]: true
      };
    });

    return { classes };
  }
});
