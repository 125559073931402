
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},

  props: {
    farmVaultTags: { type: Array }
  },

  setup() {
    // DATA
    const tags = {
      core: 'Core',
      venusProtocol: 'Venus',
      channels: 'Channels',
      swipeswap: 'Swip',
      mdex: 'MDEX',
      unifi: 'Unifi',
      wav: '🌊',
      atlantis: 'Atlantis',
      dsg: 'DSG',
      biswap: 'Biswap',
      pancakeV2: 'PancakeV2',
      stableswap: 'StableSwap',
      acsfi: 'ACS',
      acsifi: 'ACSI',
      new: 'New',
      newFees: 'New Fees',
      beta: 'Beta',
      v2: 'V2',
      boost: '🔥',
      onsen: '♨️',
      test: 'Test',
      deprecated: 'Deprecated',
      upgrading: 'Upgrading',
      pancakeV1: 'PancakeV1',
      acsV1: 'AcsFarmV1',
      acsiV1: 'AcsiFarmV1'
    };

    return {
      // data
      tags
    };
  }
});
