<template>
  <BalModal
    noPad
    :show="isVisible"
    width="lg"
    @close="$emit('close')"
    style="z-index: 39;"
  >
    <BalLoadingBlock
      v-if="loadingPool || !transfersAllowed || !sorReady"
      class="h-96"
    />
    <BalCard v-else shadow="xl" exposeOverflow noBorder>
      <template #header>
        <div class="w-full">
          <div class="text-xs text-gray-500  leading-none">
            {{ network.chainName }}
          </div>
          <BalTabs
            v-model="activeTab"
            :tabs="tabs"
            class="mt-4 text-md"
            no-pad
          />
          <div class="flex mt-4 items-center justify-between">
            <h4>
              {{
                activeTab === 'invest'
                  ? $t('investInPool')
                  : $t('withdrawFromPool')
              }}
            </h4>
            <TradeSettingsPopover :context="TradeSettingsContext.invest" />
          </div>
        </div>
      </template>
      <template v-if="activeTab === 'invest'">
        <InvestForm :pool="pool" />
      </template>
      <template v-if="activeTab === 'withdraw'">
        <WithdrawForm :pool="pool" />
      </template>
      <div class="text-right px-6 mt-4 text-smd text-gray-390 underline">
        <a target="_blank" :href="EXTERNAL_LINKS.AcsiFinance.Pool(pool.id)">{{
          $t('powered_by_acsi_finance')
        }}</a>
      </div>
    </BalCard>
  </BalModal>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { configService } from '@/services/config/config.service';
import InvestForm from '@/components/forms/pool_actions/InvestForm/InvestForm.vue';
import WithdrawForm from '@/components/forms/pool_actions/WithdrawForm/WithdrawForm.vue';
import TradeSettingsPopover, {
  TradeSettingsContext
} from '@/components/popovers/TradeSettingsPopover.vue';
// Composables
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
import { usePool } from '@/composables/usePool';
import { forChange } from '@/lib/utils';
import useInvestState from '@/components/forms/pool_actions/InvestForm/composables/useInvestState';
import { EXTERNAL_LINKS } from '@/constants/links';

export default defineComponent({
  emits: ['close'],

  components: {
    TradeSettingsPopover,
    InvestForm,
    WithdrawForm
  },

  props: {
    poolId: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n();
    /**
     * STATE
     */
    const { network } = configService;
    const { pool, loadingPool, transfersAllowed } = usePoolTransfers(
      props.poolId
    );
    const { isStablePhantomPool } = usePool(pool);
    const { sor, sorReady } = useInvestState();
    const tabs = [
      { value: 'invest', label: t('invest') },
      { value: 'withdraw', label: t('withdraw.label') }
    ];
    const activeTab = ref(tabs[0].value);

    /**
     * CALLBACKS
     */
    onBeforeMount(async () => {
      await forChange(loadingPool, false);

      if (pool.value && isStablePhantomPool.value) {
        // Initialise SOR for batch swap queries
        sorReady.value = await sor.fetchPools();
      } else {
        sorReady.value = true;
      }
    });
    return {
      pool,
      network,
      sorReady,
      loadingPool,
      transfersAllowed,
      TradeSettingsContext,
      EXTERNAL_LINKS,
      // data
      tabs,
      activeTab
    };
  }
});
</script>
