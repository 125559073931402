<template>
  <MyNfts />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MyNfts from '@/components/mynfts/index.vue';

export default defineComponent({
  components: {
    MyNfts
  }
});
</script>
