// function oneinchLink() {
//   return 'https://app.1inch.io/#/r/0x5bd97307a40dfbfdbaef4b3d997adb816f2dadcc';
// }

// let newFees = '<a class="wallet-info" href="https://docs.acryptos.com/fees#all-vaults-except-acs-and-acsi-vault" target="_blank"><span data-i18n="withdrawFee">Withdrawal fee</span>: 0.1%</a>'
const zeroFarmV1Fees =
  '<a class="wallet-info" href="https://docs.acryptos.com/fees" target="_blank">Transaction fee: 0 ACS</a>';
const zeroAcsiFarmV1Fees =
  '<a class="wallet-info" href="https://docs.acryptos.com/fees" target="_blank">Transaction fee: 0 ACSI</a>';
// let venusPauseNotice = '<div class="wallet-info" style="max-width:16em;display:inline-block"><span class="text-warning">⚠️</span> Venus Vault <a href="https://t.me/acryptos9/107091" target="_blank">withdrawals paused</a> until 23 Apr 12PM UTC</div><br />'

const Config = {
  acsHarvestFee: 0.03,
  acsiHarvestFee: 0.06,
  wavHarvestFee: 0.8,
  // acsHarvestFee: 0,
  // acsiHarvestFee: 0,
  erc20abi: [
    {
      inputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Approval',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        }
      ],
      name: 'MinterAdded',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        }
      ],
      name: 'MinterRemoved',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Transfer',
      type: 'event'
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'addMinter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' }
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'isMinter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'mint',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceMinter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  maxuint: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  wbnb: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  busd: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  acs: '0x4197C6EF3879a08cD51e5560da5064B773aa1d29',
  acsi: '0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389',
  timelockLong: '0xfd6e996C8960D521E3D2624cc4c6648cFA1217b7',
  timelockShort: '0x3595D94a7AA78292b4283fd541ce3ea45AFeC1bc',
  proxyAdmin: '0xA54Fcd71BFd83Ee06B359F986Fc5dA97AA90156C',
  vaultsController: '0xeb8f15086274586f95c551890A29077a5b6e5e55',
  // vaultsControllerTest: '0x148679fd0befc4ae266bbbd02799cdcd869aaf63',
  vaultsControllerV2: '0xa4752C6af04a73058bd407FB346CD8CC120b4ADd',
  vaultsControllerV2_2: '0xD95B9C08285045393862607D6e5FC2D95b9CE129',
  vaultsControllerV2_3: '0x8b1A3357B51ee5132EB552FA596587fD13BE0E24',
  vaultsControllerV2_4: '0x00606230D318fa47A7c270844c9d24fA1d38F955',
  acsMasterFarm: '0xeaE1425d8ed46554BF56968960e2E567B49D0BED',
  acsMasterFarmV2: '0xb1fa5d3c0111d8E9ac43A19ef17b281D5D4b474E',
  acsiMasterFarm: '0x96c8390BA28eB083A784280227C37b853bc408B7',
  acsiMasterFarmV2b: '0x0C3B6058c25205345b8f22578B27065a7506671C',
  wavFarm: '0x308474E30326A1bbaA97d099A85bC12D2BBebA28',
  contractsFromBlock: {
    // new Balancer V2 Pools and Uni Pairs
    // '0x49b823Bec2d4E89Cf4368bD743de1bC74871af22': 9280888,
    // '0xa22F3E51939488962AA26d42799dF75F72816cE0': 9339888,
    // '0xFafF81515aC376b1E07F068f527ed8de31437A9F': 9342734,
    // '0xd8a01598399b4D90DFD73a78581Ca26bb09Da32B': 10988888,
    // '0x62672E4496D870ECfE88706d0e02D4f8f9566f38': 11069888,
    // '0x43FE71651928C01306c1941b574f3e2342bfe477': 11269888,
    // '0xEa20aC7B5E6379cEB44ab0E18C5e8A09Da40EaA0': 11298888,
    // '0xb338a4ABD2e1f7066810ef0a8cdAdBdA3CEF0B92': 11325888,
    // '0x1f01eEc228f2295858EB60E0C9C59d74D3A927BF': 11385888,
    '0xaEcf01c5a659d74Dc33C9C922a4458eAB0b13DeA': 11475888,
    '0x79f819fD095F77c595cd892346dD35763Dc63166': 11503988,
    '0x37BF06Fc8F3aF767Cc02448460ef5048bDfEEfFd': 11674889,
    '0x292B9e9e1D2B3F30BFc06aBD79a98F3e11665419': 11755888,
    '0x16f057CFcd681F754e27cb98ac7Ef7B1099F216C': 11871888,
    '0x40e030d42f2417624E950E86a6A94195b2D69f54': 11904889,
    '0xAa40Dc3EC6aD76Db3254b54443C4531e3dfe6bDb': 11968888,
    '0xa6df26f8d0Fc91CfD22DF8443b7F2F8586E4c391': 12072644,
    '0x4B067c22A4eCb993f8E0499deabD4E8455948481': 12443169,
    '0x51441Efb4bEeB1694FcC985d522c120286400061': 14038888
  },
  additionalPricingPairs: [
    '0xFb72D7C0f1643c96c197A98E5f36eBcf7597d0E3', // bakery beth-eth
    '0x3720DAD44398f745067b54ea786BB66CB97f68e9', // mdex bch-busd
    '0xfd1ef328A17A8e8Eeaf7e4Ea1ed8a108E1F2d096', // mdex doge-bnb
    '0xCAABda10a3ac99Fc15f5B636Aa18E6B4Fd8db16D', // mdex fil-usdt
    '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489', // pcs busd-dai
    '0x05faf555522Fa3F93959F86B41A3808666093210', // pcs busd-ust
    '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1', // pcs busd-usdc
    '0x29A4A3D77c010CE100A45831BF7e798f0f0B325D', // apeswap matic-bnb
    '0xf13e007e181A8F57eD3a4D4CcE0A9ff9E7241CEf' // apeswap aave-bnb
  ],
  additionalPricingPairsDeprecated: [
    '0x699c58d77027Ae44608808B3863C394A194d76f6', // pcs v1 acs-acsi
    '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489', // pcs busd-dai
    '0x05faf555522Fa3F93959F86B41A3808666093210', // pcs busd-ust
    '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1', // pcs busd-usdc
    '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082' // pcs btc-bnb
  ],
  stableSwaps: [
    {
      address: '0xb3F0C9ea1F05e312093Fdb031E789A756659B0AC',
      lpToken: '0x83D69Ef5c9837E21E2389D47d791714F5771F29b',
      lpTokenSymbol: 'ACS4USD',
      baseSymbol: 'USD',
      sd: 'BUSD/USDT/DAI/USDC',
      coins: [
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        '0x55d398326f99059fF775485246999027B3197955',
        '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
      ],
      fee: 0.0001,
      adminFee: 0.5,
      abi: [
        {
          name: 'TokenExchange',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'AddLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[4]', name: 'token_amounts', indexed: false },
            { type: 'uint256[4]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[4]', name: 'token_amounts', indexed: false },
            { type: 'uint256[4]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityOne',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256', name: 'token_amount', indexed: false },
            { type: 'uint256', name: 'coin_amount', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityImbalance',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[4]', name: 'token_amounts', indexed: false },
            { type: 'uint256[4]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewAdmin',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'address', name: 'admin', indexed: true }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewAdmin',
          inputs: [{ type: 'address', name: 'admin', indexed: true }],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewFee',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewFee',
          inputs: [
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RampA',
          inputs: [
            { type: 'uint256', name: 'old_A', indexed: false },
            { type: 'uint256', name: 'new_A', indexed: false },
            { type: 'uint256', name: 'initial_time', indexed: false },
            { type: 'uint256', name: 'future_time', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'StopRampA',
          inputs: [
            { type: 'uint256', name: 'A', indexed: false },
            { type: 'uint256', name: 't', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_owner' },
            { type: 'address[4]', name: '_coins' },
            { type: 'address', name: '_pool_token' },
            { type: 'uint256', name: '_A' },
            { type: 'uint256', name: '_fee' },
            { type: 'uint256', name: '_admin_fee' },
            { type: 'address', name: '_admin_fee_address' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5199,
          constant: true
        },
        {
          name: 'A_precise',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5161,
          constant: true
        },
        {
          name: 'get_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1346183,
          constant: true
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[4]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 5357629,
          constant: true
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[4]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 8287631
        },
        {
          name: 'get_dy',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3099381,
          constant: true
        },
        {
          name: 'exchange',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 3262485
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[4]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[4]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 255557
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[4]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 8286723
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 1489,
          constant: true
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 4478633
        },
        {
          name: 'ramp_A',
          outputs: [],
          inputs: [
            { type: 'uint256', name: '_future_A' },
            { type: 'uint256', name: '_future_time' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 151774
        },
        {
          name: 'stop_ramp_A',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 148535
        },
        {
          name: 'commit_new_fee',
          outputs: [],
          inputs: [
            { type: 'uint256', name: 'new_fee' },
            { type: 'uint256', name: 'new_admin_fee' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 110371
        },
        {
          name: 'apply_new_fee',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 97152
        },
        {
          name: 'revert_new_parameters',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 21805
        },
        {
          name: 'commit_transfer_ownership',
          outputs: [],
          inputs: [{ type: 'address', name: '_owner' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74543
        },
        {
          name: 'apply_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 60620
        },
        {
          name: 'revert_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 21895
        },
        {
          name: 'admin_balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'i' }],
          stateMutability: 'view',
          type: 'function',
          gas: 3391,
          constant: true
        },
        {
          name: 'withdraw_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 31898
        },
        {
          name: 'donate_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 147723
        },
        {
          name: 'kill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 37908
        },
        {
          name: 'unkill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22045
        },
        {
          name: 'set_admin_fee_address',
          outputs: [],
          inputs: [{ type: 'address', name: '_admin_fee_address' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 37178
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2160,
          constant: true
        },
        {
          name: 'balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2190,
          constant: true
        },
        {
          name: 'fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2111,
          constant: true
        },
        {
          name: 'admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2141,
          constant: true
        },
        {
          name: 'admin_fee_address',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2171,
          constant: true
        },
        {
          name: 'owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2201,
          constant: true
        },
        {
          name: 'lp_token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2231,
          constant: true
        },
        {
          name: 'initial_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2261,
          constant: true
        },
        {
          name: 'future_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2291,
          constant: true
        },
        {
          name: 'initial_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2321,
          constant: true
        },
        {
          name: 'future_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2351,
          constant: true
        },
        {
          name: 'admin_actions_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2381,
          constant: true
        },
        {
          name: 'transfer_ownership_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2411,
          constant: true
        },
        {
          name: 'future_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2441,
          constant: true
        },
        {
          name: 'future_admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2471,
          constant: true
        },
        {
          name: 'future_owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2501,
          constant: true
        }
      ]
    },
    {
      address: '0x191409D5A4EfFe25b0f4240557BA2192D18a191e',
      basePool: '0xb3F0C9ea1F05e312093Fdb031E789A756659B0AC',
      metaDeposit: '0xC8036DeF32920A4686b81ADC30e76244da087960',
      lpToken: '0xEb7Dc7b3bfF60A450EfF31Edf1330355361EA5ad',
      lpTokenSymbol: 'ACS4VAI',
      baseSymbol: 'USD',
      sd: 'VAI/[ACS4USD]',
      coins: [
        '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        '0x55d398326f99059fF775485246999027B3197955',
        '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
      ],
      fee: 0.0006,
      adminFee: 0.5,
      metaDepositAbi: [
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_pool' },
            { type: 'address', name: '_token' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 40368
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[5]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[5]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 39062
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 14467
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 44404
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3147
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 4856
        },
        {
          name: 'pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1241
        },
        {
          name: 'token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1271
        },
        {
          name: 'base_pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1301
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 1440
        },
        {
          name: 'base_coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 1470
        }
      ],
      abi: [
        {
          name: 'TokenExchange',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'TokenExchangeUnderlying',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'AddLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityOne',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256', name: 'token_amount', indexed: false },
            { type: 'uint256', name: 'coin_amount', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityImbalance',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewAdmin',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'address', name: 'admin', indexed: true }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewAdmin',
          inputs: [{ type: 'address', name: 'admin', indexed: true }],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewFee',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewFee',
          inputs: [
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RampA',
          inputs: [
            { type: 'uint256', name: 'old_A', indexed: false },
            { type: 'uint256', name: 'new_A', indexed: false },
            { type: 'uint256', name: 'initial_time', indexed: false },
            { type: 'uint256', name: 'future_time', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'StopRampA',
          inputs: [
            { type: 'uint256', name: 'A', indexed: false },
            { type: 'uint256', name: 't', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_owner' },
            { type: 'address[2]', name: '_coins' },
            { type: 'address', name: '_pool_token' },
            { type: 'address', name: '_base_pool' },
            { type: 'uint256', name: '_A' },
            { type: 'uint256', name: '_fee' },
            { type: 'uint256', name: '_admin_fee' },
            { type: 'address', name: '_admin_fee_address' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5199
        },
        {
          name: 'A_precise',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5161
        },
        {
          name: 'get_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 992460
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3938567
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 6136485
        },
        {
          name: 'get_dy',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 2390018
        },
        {
          name: 'get_dy_underlying',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 2393245
        },
        {
          name: 'exchange',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 2617039
        },
        {
          name: 'exchange_underlying',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 2631869
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[2]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[2]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 163158
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 6136334
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 4389
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 3826262
        },
        {
          name: 'ramp_A',
          outputs: [],
          inputs: [
            { type: 'uint256', name: '_future_A' },
            { type: 'uint256', name: '_future_time' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 151894
        },
        {
          name: 'stop_ramp_A',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 148655
        },
        {
          name: 'commit_new_fee',
          outputs: [],
          inputs: [
            { type: 'uint256', name: 'new_fee' },
            { type: 'uint256', name: 'new_admin_fee' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 110491
        },
        {
          name: 'apply_new_fee',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 97272
        },
        {
          name: 'revert_new_parameters',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 21925
        },
        {
          name: 'commit_transfer_ownership',
          outputs: [],
          inputs: [{ type: 'address', name: '_owner' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74663
        },
        {
          name: 'apply_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 60740
        },
        {
          name: 'revert_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22015
        },
        {
          name: 'admin_balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'i' }],
          stateMutability: 'view',
          type: 'function',
          gas: 3511
        },
        {
          name: 'withdraw_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 11681
        },
        {
          name: 'donate_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74995
        },
        {
          name: 'kill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 38028
        },
        {
          name: 'unkill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22165
        },
        {
          name: 'set_admin_fee_address',
          outputs: [],
          inputs: [{ type: 'address', name: '_admin_fee_address' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 37298
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2280
        },
        {
          name: 'balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2310
        },
        {
          name: 'fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2231
        },
        {
          name: 'admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2261
        },
        {
          name: 'admin_fee_address',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2291
        },
        {
          name: 'owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2321
        },
        {
          name: 'token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2351
        },
        {
          name: 'base_pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2381
        },
        {
          name: 'base_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2411
        },
        {
          name: 'base_cache_updated',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2441
        },
        {
          name: 'base_coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2580
        },
        {
          name: 'initial_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2501
        },
        {
          name: 'future_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2531
        },
        {
          name: 'initial_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2561
        },
        {
          name: 'future_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2591
        },
        {
          name: 'admin_actions_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2621
        },
        {
          name: 'transfer_ownership_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2651
        },
        {
          name: 'future_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2681
        },
        {
          name: 'future_admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2711
        },
        {
          name: 'future_owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2741
        }
      ]
    },
    {
      address: '0x3919874C7bc0699cF59c981C5eb668823FA4f958',
      basePool: '0xb3F0C9ea1F05e312093Fdb031E789A756659B0AC',
      metaDeposit: '0x7f66F5788b9b488E339F68104bA630E6bC210b9f',
      lpToken: '0x49440376254290b3264183807A16450457f02B28',
      lpTokenSymbol: 'ACS4QUSD',
      baseSymbol: 'USD',
      sd: 'QUSD/[ACS4USD]',
      coins: [
        '0xb8C540d00dd0Bf76ea12E4B4B95eFC90804f924E',
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        '0x55d398326f99059fF775485246999027B3197955',
        '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
      ],
      fee: 0.0006,
      adminFee: 0.5,
      metaDepositAbi: [
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_pool' },
            { type: 'address', name: '_token' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 40368
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[5]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[5]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 39062
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 14467
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 44404
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3147
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 4856
        },
        {
          name: 'pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1241
        },
        {
          name: 'token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1271
        },
        {
          name: 'base_pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1301
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 1440
        },
        {
          name: 'base_coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 1470
        }
      ],
      abi: [
        {
          name: 'TokenExchange',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'TokenExchangeUnderlying',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'AddLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityOne',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256', name: 'token_amount', indexed: false },
            { type: 'uint256', name: 'coin_amount', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityImbalance',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewAdmin',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'address', name: 'admin', indexed: true }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewAdmin',
          inputs: [{ type: 'address', name: 'admin', indexed: true }],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewFee',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewFee',
          inputs: [
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RampA',
          inputs: [
            { type: 'uint256', name: 'old_A', indexed: false },
            { type: 'uint256', name: 'new_A', indexed: false },
            { type: 'uint256', name: 'initial_time', indexed: false },
            { type: 'uint256', name: 'future_time', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'StopRampA',
          inputs: [
            { type: 'uint256', name: 'A', indexed: false },
            { type: 'uint256', name: 't', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_owner' },
            { type: 'address[2]', name: '_coins' },
            { type: 'address', name: '_pool_token' },
            { type: 'address', name: '_base_pool' },
            { type: 'uint256', name: '_A' },
            { type: 'uint256', name: '_fee' },
            { type: 'uint256', name: '_admin_fee' },
            { type: 'address', name: '_admin_fee_address' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5199
        },
        {
          name: 'A_precise',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5161
        },
        {
          name: 'get_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 992460
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3938567
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 6136485
        },
        {
          name: 'get_dy',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 2390018
        },
        {
          name: 'get_dy_underlying',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 2393245
        },
        {
          name: 'exchange',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 2617039
        },
        {
          name: 'exchange_underlying',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 2631869
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[2]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[2]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 163158
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 6136334
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 4389
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 3826262
        },
        {
          name: 'ramp_A',
          outputs: [],
          inputs: [
            { type: 'uint256', name: '_future_A' },
            { type: 'uint256', name: '_future_time' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 151894
        },
        {
          name: 'stop_ramp_A',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 148655
        },
        {
          name: 'commit_new_fee',
          outputs: [],
          inputs: [
            { type: 'uint256', name: 'new_fee' },
            { type: 'uint256', name: 'new_admin_fee' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 110491
        },
        {
          name: 'apply_new_fee',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 97272
        },
        {
          name: 'revert_new_parameters',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 21925
        },
        {
          name: 'commit_transfer_ownership',
          outputs: [],
          inputs: [{ type: 'address', name: '_owner' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74663
        },
        {
          name: 'apply_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 60740
        },
        {
          name: 'revert_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22015
        },
        {
          name: 'admin_balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'i' }],
          stateMutability: 'view',
          type: 'function',
          gas: 3511
        },
        {
          name: 'withdraw_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 11681
        },
        {
          name: 'donate_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74995
        },
        {
          name: 'kill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 38028
        },
        {
          name: 'unkill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22165
        },
        {
          name: 'set_admin_fee_address',
          outputs: [],
          inputs: [{ type: 'address', name: '_admin_fee_address' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 37298
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2280
        },
        {
          name: 'balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2310
        },
        {
          name: 'fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2231
        },
        {
          name: 'admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2261
        },
        {
          name: 'admin_fee_address',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2291
        },
        {
          name: 'owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2321
        },
        {
          name: 'token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2351
        },
        {
          name: 'base_pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2381
        },
        {
          name: 'base_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2411
        },
        {
          name: 'base_cache_updated',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2441
        },
        {
          name: 'base_coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2580
        },
        {
          name: 'initial_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2501
        },
        {
          name: 'future_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2531
        },
        {
          name: 'initial_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2561
        },
        {
          name: 'future_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2591
        },
        {
          name: 'admin_actions_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2621
        },
        {
          name: 'transfer_ownership_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2651
        },
        {
          name: 'future_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2681
        },
        {
          name: 'future_admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2711
        },
        {
          name: 'future_owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2741
        }
      ]
    },
    {
      address: '0x99c92765EfC472a9709Ced86310D64C4573c4b77',
      basePool: '0xb3F0C9ea1F05e312093Fdb031E789A756659B0AC',
      metaDeposit: '0x4deb9077E49269B04Fd0324461aF301dD6600216',
      lpToken: '0xD3DEBe4a971e4492d0D61aB145468A5B2c23301b',
      lpTokenSymbol: 'ACS4UST',
      baseSymbol: 'USD',
      sd: 'UST/[ACS4USD]',
      coins: [
        '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        '0x55d398326f99059fF775485246999027B3197955',
        '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
      ],
      fee: 0.0006,
      adminFee: 0.5,
      metaDepositAbi: [
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_pool' },
            { type: 'address', name: '_token' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 40368
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[5]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[5]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 39062
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 14467
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 44404
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3147
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 4856
        },
        {
          name: 'pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1241
        },
        {
          name: 'token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1271
        },
        {
          name: 'base_pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1301
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 1440
        },
        {
          name: 'base_coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 1470
        }
      ],
      abi: [
        {
          name: 'TokenExchange',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'TokenExchangeUnderlying',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'AddLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityOne',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256', name: 'token_amount', indexed: false },
            { type: 'uint256', name: 'coin_amount', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityImbalance',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewAdmin',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'address', name: 'admin', indexed: true }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewAdmin',
          inputs: [{ type: 'address', name: 'admin', indexed: true }],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewFee',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewFee',
          inputs: [
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RampA',
          inputs: [
            { type: 'uint256', name: 'old_A', indexed: false },
            { type: 'uint256', name: 'new_A', indexed: false },
            { type: 'uint256', name: 'initial_time', indexed: false },
            { type: 'uint256', name: 'future_time', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'StopRampA',
          inputs: [
            { type: 'uint256', name: 'A', indexed: false },
            { type: 'uint256', name: 't', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_owner' },
            { type: 'address[2]', name: '_coins' },
            { type: 'address', name: '_pool_token' },
            { type: 'address', name: '_base_pool' },
            { type: 'uint256', name: '_A' },
            { type: 'uint256', name: '_fee' },
            { type: 'uint256', name: '_admin_fee' },
            { type: 'address', name: '_admin_fee_address' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5199
        },
        {
          name: 'A_precise',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5161
        },
        {
          name: 'get_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 992460
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3938567
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 6136485
        },
        {
          name: 'get_dy',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 2390018
        },
        {
          name: 'get_dy_underlying',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 2393245
        },
        {
          name: 'exchange',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 2617039
        },
        {
          name: 'exchange_underlying',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 2631869
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[2]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[2]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 163158
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 6136334
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 4389
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 3826262
        },
        {
          name: 'ramp_A',
          outputs: [],
          inputs: [
            { type: 'uint256', name: '_future_A' },
            { type: 'uint256', name: '_future_time' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 151894
        },
        {
          name: 'stop_ramp_A',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 148655
        },
        {
          name: 'commit_new_fee',
          outputs: [],
          inputs: [
            { type: 'uint256', name: 'new_fee' },
            { type: 'uint256', name: 'new_admin_fee' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 110491
        },
        {
          name: 'apply_new_fee',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 97272
        },
        {
          name: 'revert_new_parameters',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 21925
        },
        {
          name: 'commit_transfer_ownership',
          outputs: [],
          inputs: [{ type: 'address', name: '_owner' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74663
        },
        {
          name: 'apply_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 60740
        },
        {
          name: 'revert_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22015
        },
        {
          name: 'admin_balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'i' }],
          stateMutability: 'view',
          type: 'function',
          gas: 3511
        },
        {
          name: 'withdraw_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 11681
        },
        {
          name: 'donate_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74995
        },
        {
          name: 'kill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 38028
        },
        {
          name: 'unkill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22165
        },
        {
          name: 'set_admin_fee_address',
          outputs: [],
          inputs: [{ type: 'address', name: '_admin_fee_address' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 37298
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2280
        },
        {
          name: 'balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2310
        },
        {
          name: 'fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2231
        },
        {
          name: 'admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2261
        },
        {
          name: 'admin_fee_address',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2291
        },
        {
          name: 'owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2321
        },
        {
          name: 'token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2351
        },
        {
          name: 'base_pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2381
        },
        {
          name: 'base_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2411
        },
        {
          name: 'base_cache_updated',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2441
        },
        {
          name: 'base_coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2580
        },
        {
          name: 'initial_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2501
        },
        {
          name: 'future_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2531
        },
        {
          name: 'initial_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2561
        },
        {
          name: 'future_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2591
        },
        {
          name: 'admin_actions_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2621
        },
        {
          name: 'transfer_ownership_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2651
        },
        {
          name: 'future_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2681
        },
        {
          name: 'future_admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2711
        },
        {
          name: 'future_owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2741
        }
      ],
      fromBlock: 4492094
    },
    {
      address: '0xc61639E5626EcfB0788b5308c67CBbBD1cAecBF0',
      basePool: '0xb3F0C9ea1F05e312093Fdb031E789A756659B0AC',
      metaDeposit: '0xd487c84fc49616a7ca619AAC75d0AbD0053939b7',
      lpToken: '0x9e31F49c22Bf8C2B9FFD3645e4203B9B8E606b98',
      lpTokenSymbol: 'ACS4IRON',
      baseSymbol: 'USD',
      sd: 'IRON/[ACS4USD]',
      coins: [
        '0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8',
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        '0x55d398326f99059fF775485246999027B3197955',
        '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
      ],
      fee: 0.0006,
      adminFee: 0.5,
      metaDepositAbi: [
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_pool' },
            { type: 'address', name: '_token' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 40368
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[5]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[5]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 39062
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 14467
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 44404
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3147
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[5]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 4856
        },
        {
          name: 'pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1241
        },
        {
          name: 'token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1271
        },
        {
          name: 'base_pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 1301
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 1440
        },
        {
          name: 'base_coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 1470
        }
      ],
      abi: [
        {
          name: 'TokenExchange',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'TokenExchangeUnderlying',
          inputs: [
            { type: 'address', name: 'buyer', indexed: true },
            { type: 'int128', name: 'sold_id', indexed: false },
            { type: 'uint256', name: 'tokens_sold', indexed: false },
            { type: 'int128', name: 'bought_id', indexed: false },
            { type: 'uint256', name: 'tokens_bought', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'AddLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidity',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityOne',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256', name: 'token_amount', indexed: false },
            { type: 'uint256', name: 'coin_amount', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RemoveLiquidityImbalance',
          inputs: [
            { type: 'address', name: 'provider', indexed: true },
            { type: 'uint256[2]', name: 'token_amounts', indexed: false },
            { type: 'uint256[2]', name: 'fees', indexed: false },
            { type: 'uint256', name: 'invariant', indexed: false },
            { type: 'uint256', name: 'token_supply', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewAdmin',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'address', name: 'admin', indexed: true }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewAdmin',
          inputs: [{ type: 'address', name: 'admin', indexed: true }],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'CommitNewFee',
          inputs: [
            { type: 'uint256', name: 'deadline', indexed: true },
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'NewFee',
          inputs: [
            { type: 'uint256', name: 'fee', indexed: false },
            { type: 'uint256', name: 'admin_fee', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'RampA',
          inputs: [
            { type: 'uint256', name: 'old_A', indexed: false },
            { type: 'uint256', name: 'new_A', indexed: false },
            { type: 'uint256', name: 'initial_time', indexed: false },
            { type: 'uint256', name: 'future_time', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          name: 'StopRampA',
          inputs: [
            { type: 'uint256', name: 'A', indexed: false },
            { type: 'uint256', name: 't', indexed: false }
          ],
          anonymous: false,
          type: 'event'
        },
        {
          outputs: [],
          inputs: [
            { type: 'address', name: '_owner' },
            { type: 'address[2]', name: '_coins' },
            { type: 'address', name: '_pool_token' },
            { type: 'address', name: '_base_pool' },
            { type: 'uint256', name: '_A' },
            { type: 'uint256', name: '_fee' },
            { type: 'uint256', name: '_admin_fee' },
            { type: 'address', name: '_admin_fee_address' }
          ],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          name: 'A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5199
        },
        {
          name: 'A_precise',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 5161
        },
        {
          name: 'get_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 992460
        },
        {
          name: 'calc_token_amount',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'bool', name: 'is_deposit' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 3938567
        },
        {
          name: 'add_liquidity',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'uint256', name: 'min_mint_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 6136485
        },
        {
          name: 'get_dy',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 2390018
        },
        {
          name: 'get_dy_underlying',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 2393245
        },
        {
          name: 'exchange',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 2617039
        },
        {
          name: 'exchange_underlying',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'int128', name: 'i' },
            { type: 'int128', name: 'j' },
            { type: 'uint256', name: 'dx' },
            { type: 'uint256', name: 'min_dy' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 2631869
        },
        {
          name: 'remove_liquidity',
          outputs: [{ type: 'uint256[2]', name: '' }],
          inputs: [
            { type: 'uint256', name: '_amount' },
            { type: 'uint256[2]', name: 'min_amounts' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 163158
        },
        {
          name: 'remove_liquidity_imbalance',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256[2]', name: 'amounts' },
            { type: 'uint256', name: 'max_burn_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 6136334
        },
        {
          name: 'calc_withdraw_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' }
          ],
          stateMutability: 'view',
          type: 'function',
          gas: 4389
        },
        {
          name: 'remove_liquidity_one_coin',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [
            { type: 'uint256', name: '_token_amount' },
            { type: 'int128', name: 'i' },
            { type: 'uint256', name: '_min_amount' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 3826262
        },
        {
          name: 'ramp_A',
          outputs: [],
          inputs: [
            { type: 'uint256', name: '_future_A' },
            { type: 'uint256', name: '_future_time' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 151894
        },
        {
          name: 'stop_ramp_A',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 148655
        },
        {
          name: 'commit_new_fee',
          outputs: [],
          inputs: [
            { type: 'uint256', name: 'new_fee' },
            { type: 'uint256', name: 'new_admin_fee' }
          ],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 110491
        },
        {
          name: 'apply_new_fee',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 97272
        },
        {
          name: 'revert_new_parameters',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 21925
        },
        {
          name: 'commit_transfer_ownership',
          outputs: [],
          inputs: [{ type: 'address', name: '_owner' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74663
        },
        {
          name: 'apply_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 60740
        },
        {
          name: 'revert_transfer_ownership',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22015
        },
        {
          name: 'admin_balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'i' }],
          stateMutability: 'view',
          type: 'function',
          gas: 3511
        },
        {
          name: 'withdraw_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 11681
        },
        {
          name: 'donate_admin_fees',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 74995
        },
        {
          name: 'kill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 38028
        },
        {
          name: 'unkill_me',
          outputs: [],
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 22165
        },
        {
          name: 'set_admin_fee_address',
          outputs: [],
          inputs: [{ type: 'address', name: '_admin_fee_address' }],
          stateMutability: 'nonpayable',
          type: 'function',
          gas: 37298
        },
        {
          name: 'coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2280
        },
        {
          name: 'balances',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2310
        },
        {
          name: 'fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2231
        },
        {
          name: 'admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2261
        },
        {
          name: 'admin_fee_address',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2291
        },
        {
          name: 'owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2321
        },
        {
          name: 'token',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2351
        },
        {
          name: 'base_pool',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2381
        },
        {
          name: 'base_virtual_price',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2411
        },
        {
          name: 'base_cache_updated',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2441
        },
        {
          name: 'base_coins',
          outputs: [{ type: 'address', name: '' }],
          inputs: [{ type: 'uint256', name: 'arg0' }],
          stateMutability: 'view',
          type: 'function',
          gas: 2580
        },
        {
          name: 'initial_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2501
        },
        {
          name: 'future_A',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2531
        },
        {
          name: 'initial_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2561
        },
        {
          name: 'future_A_time',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2591
        },
        {
          name: 'admin_actions_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2621
        },
        {
          name: 'transfer_ownership_deadline',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2651
        },
        {
          name: 'future_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2681
        },
        {
          name: 'future_admin_fee',
          outputs: [{ type: 'uint256', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2711
        },
        {
          name: 'future_owner',
          outputs: [{ type: 'address', name: '' }],
          inputs: [],
          stateMutability: 'view',
          type: 'function',
          gas: 2741
        }
      ],
      fromBlock: 7568888
    },
    {
      address: '0xbE7CAa236544d1B9A0E7F91e94B9f5Bfd3B5ca81',
      lpToken: '0x2d00C55b93a03830D19eb97A5e48F77aCaac2D63',
      lpTokenSymbol: 'ACS3BTC',
      baseSymbol: 'BTC',
      sd: 'BTCB/renBTC/pBTC',
      coins: [
        '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        '0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c',
        '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C'
      ],
      fee: 0.0004,
      adminFee: 0.5,
      abi: [
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: 'buyer', type: 'address' },
            { indexed: false, name: 'sold_id', type: 'int128' },
            { indexed: false, name: 'tokens_sold', type: 'uint256' },
            { indexed: false, name: 'bought_id', type: 'int128' },
            { indexed: false, name: 'tokens_bought', type: 'uint256' }
          ],
          name: 'TokenExchange',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: 'provider', type: 'address' },
            { indexed: false, name: 'token_amounts', type: 'uint256[3]' },
            { indexed: false, name: 'fees', type: 'uint256[3]' },
            { indexed: false, name: 'invariant', type: 'uint256' },
            { indexed: false, name: 'token_supply', type: 'uint256' }
          ],
          name: 'AddLiquidity',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: 'provider', type: 'address' },
            { indexed: false, name: 'token_amounts', type: 'uint256[3]' },
            { indexed: false, name: 'fees', type: 'uint256[3]' },
            { indexed: false, name: 'token_supply', type: 'uint256' }
          ],
          name: 'RemoveLiquidity',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: 'provider', type: 'address' },
            { indexed: false, name: 'token_amount', type: 'uint256' },
            { indexed: false, name: 'coin_amount', type: 'uint256' },
            { indexed: false, name: 'token_supply', type: 'uint256' }
          ],
          name: 'RemoveLiquidityOne',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: 'provider', type: 'address' },
            { indexed: false, name: 'token_amounts', type: 'uint256[3]' },
            { indexed: false, name: 'fees', type: 'uint256[3]' },
            { indexed: false, name: 'invariant', type: 'uint256' },
            { indexed: false, name: 'token_supply', type: 'uint256' }
          ],
          name: 'RemoveLiquidityImbalance',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: 'deadline', type: 'uint256' },
            { indexed: true, name: 'admin', type: 'address' }
          ],
          name: 'CommitNewAdmin',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [{ indexed: true, name: 'admin', type: 'address' }],
          name: 'NewAdmin',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: 'deadline', type: 'uint256' },
            { indexed: false, name: 'fee', type: 'uint256' },
            { indexed: false, name: 'admin_fee', type: 'uint256' }
          ],
          name: 'CommitNewFee',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: false, name: 'fee', type: 'uint256' },
            { indexed: false, name: 'admin_fee', type: 'uint256' }
          ],
          name: 'NewFee',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: false, name: 'old_A', type: 'uint256' },
            { indexed: false, name: 'new_A', type: 'uint256' },
            { indexed: false, name: 'initial_time', type: 'uint256' },
            { indexed: false, name: 'future_time', type: 'uint256' }
          ],
          name: 'RampA',
          type: 'event'
        },
        {
          anonymous: false,
          inputs: [
            { indexed: false, name: 'A', type: 'uint256' },
            { indexed: false, name: 't', type: 'uint256' }
          ],
          name: 'StopRampA',
          type: 'event'
        },
        {
          inputs: [
            { name: '_owner', type: 'address' },
            { name: '_coins', type: 'address[3]' },
            { name: '_pool_token', type: 'address' },
            { name: '_A', type: 'uint256' },
            { name: '_fee', type: 'uint256' },
            { name: '_admin_fee', type: 'uint256' },
            { name: '_admin_fee_address', type: 'address' }
          ],
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor'
        },
        {
          gas: 5199,
          inputs: [],
          name: 'A',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 5161,
          inputs: [],
          name: 'A_precise',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 1167850,
          inputs: [],
          name: 'get_virtual_price',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 4646428,
          inputs: [
            { name: 'amounts', type: 'uint256[3]' },
            { name: 'is_deposit', type: 'bool' }
          ],
          name: 'calc_token_amount',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 7177827,
          inputs: [
            { name: 'amounts', type: 'uint256[3]' },
            { name: 'min_mint_amount', type: 'uint256' }
          ],
          name: 'add_liquidity',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 2743135,
          inputs: [
            { name: 'i', type: 'int128' },
            { name: 'j', type: 'int128' },
            { name: 'dx', type: 'uint256' }
          ],
          name: 'get_dy',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2906161,
          inputs: [
            { name: 'i', type: 'int128' },
            { name: 'j', type: 'int128' },
            { name: 'dx', type: 'uint256' },
            { name: 'min_dy', type: 'uint256' }
          ],
          name: 'exchange',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 211832,
          inputs: [
            { name: '_amount', type: 'uint256' },
            { name: 'min_amounts', type: 'uint256[3]' }
          ],
          name: 'remove_liquidity',
          outputs: [{ name: '', type: 'uint256[3]' }],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 7177198,
          inputs: [
            { name: 'amounts', type: 'uint256[3]' },
            { name: 'max_burn_amount', type: 'uint256' }
          ],
          name: 'remove_liquidity_imbalance',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 1489,
          inputs: [
            { name: '_token_amount', type: 'uint256' },
            { name: 'i', type: 'int128' }
          ],
          name: 'calc_withdraw_one_coin',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 4116746,
          inputs: [
            { name: '_token_amount', type: 'uint256' },
            { name: 'i', type: 'int128' },
            { name: '_min_amount', type: 'uint256' }
          ],
          name: 'remove_liquidity_one_coin',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 151774,
          inputs: [
            { name: '_future_A', type: 'uint256' },
            { name: '_future_time', type: 'uint256' }
          ],
          name: 'ramp_A',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 148535,
          inputs: [],
          name: 'stop_ramp_A',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 110371,
          inputs: [
            { name: 'new_fee', type: 'uint256' },
            { name: 'new_admin_fee', type: 'uint256' }
          ],
          name: 'commit_new_fee',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 97152,
          inputs: [],
          name: 'apply_new_fee',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 21805,
          inputs: [],
          name: 'revert_new_parameters',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 74543,
          inputs: [{ name: '_owner', type: 'address' }],
          name: 'commit_transfer_ownership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 60620,
          inputs: [],
          name: 'apply_transfer_ownership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 21895,
          inputs: [],
          name: 'revert_transfer_ownership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 3391,
          inputs: [{ name: 'i', type: 'uint256' }],
          name: 'admin_balances',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 24647,
          inputs: [],
          name: 'withdraw_admin_fees',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 111299,
          inputs: [],
          name: 'donate_admin_fees',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 37908,
          inputs: [],
          name: 'kill_me',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 22045,
          inputs: [],
          name: 'unkill_me',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 37178,
          inputs: [{ name: '_admin_fee_address', type: 'address' }],
          name: 'set_admin_fee_address',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function'
        },
        {
          gas: 2160,
          inputs: [{ name: 'arg0', type: 'uint256' }],
          name: 'coins',
          outputs: [{ name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2190,
          inputs: [{ name: 'arg0', type: 'uint256' }],
          name: 'balances',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2111,
          inputs: [],
          name: 'fee',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2141,
          inputs: [],
          name: 'admin_fee',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2171,
          inputs: [],
          name: 'admin_fee_address',
          outputs: [{ name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2201,
          inputs: [],
          name: 'owner',
          outputs: [{ name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2231,
          inputs: [],
          name: 'lp_token',
          outputs: [{ name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2261,
          inputs: [],
          name: 'initial_A',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2291,
          inputs: [],
          name: 'future_A',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2321,
          inputs: [],
          name: 'initial_A_time',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2351,
          inputs: [],
          name: 'future_A_time',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2381,
          inputs: [],
          name: 'admin_actions_deadline',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2411,
          inputs: [],
          name: 'transfer_ownership_deadline',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2441,
          inputs: [],
          name: 'future_fee',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2471,
          inputs: [],
          name: 'future_admin_fee',
          outputs: [{ name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        },
        {
          gas: 2501,
          inputs: [],
          name: 'future_owner',
          outputs: [{ name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
          constant: true
        }
      ],
      fromBlock: 6351888
    }
  ],
  tokens: {
    '0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389': {
      symbol: 'ACSI'
    },
    '0x4197C6EF3879a08cD51e5560da5064B773aa1d29': {
      symbol: 'ACS'
    },
    '0x888888883BF208d3b1AcD0052a88b9Fd07bA5851': {
      symbol: 'WAV'
    },
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56': {
      symbol: 'BUSD'
    },
    '0x55d398326f99059fF775485246999027B3197955': {
      symbol: 'USDT'
    },
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3': {
      symbol: 'DAI'
    },
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': {
      symbol: 'USDC'
    },
    '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7': {
      symbol: 'VAI'
    },
    '0xb8C540d00dd0Bf76ea12E4B4B95eFC90804f924E': {
      symbol: 'QUSD'
    },
    '0x23396cF899Ca06c4472205fC903bDB4de249D6fC': {
      symbol: 'UST'
    },
    '0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8': {
      symbol: 'IRON'
    },
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c': {
      symbol: 'BTCB'
    },
    '0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c': {
      symbol: 'renBTC',
      decimals: 8
    },
    '0x3d4350cD54aeF9f9b2C29435e0fa809957B3F30a': {
      symbol: 'USTw',
      decimals: 6
    },
    '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C': {
      symbol: 'pBTC'
    },
    '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B': {
      symbol: 'UNFI'
    },
    '0xFd7B3A77848f1C2D67E05E54d78d174a0C850335': {
      symbol: 'ONT'
    },
    '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8': {
      symbol: 'HELMET'
    },
    '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739': {
      symbol: 'SWINGBY'
    },
    '0xB09FE1613fE03E7361319d2a43eDc17422f36B09': {
      symbol: 'BOG'
    },
    '0x658A109C5900BC6d2357c87549B651670E5b0539': {
      symbol: 'FOR'
    },
    '0x32299c93960bB583A43c2220Dc89152391A610c5': {
      symbol: 'KALA'
    },
    '0x67d66e8Ec1Fd25d98B3Ccd3B19B7dc4b4b7fC493': {
      symbol: 'FEED'
    },
    '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6': {
      symbol: 'HOTCROSS'
    },
    '0x308bfaeAaC8BDab6e9Fc5Ead8EdCb5f95b0599d9': {
      symbol: 'ONG',
      decimals: 9
    }
  }
};

Config.farmVaults = [
  {
    vault: {
      address: '0x8888888818b6e5BBdF1F73c5F3A7F73b2454d2cA',
      tokenSymbol: 'WAV-ACS-BNB',
      title: 'TGW Vault',
      tokenImage: 'wav.png',
      harvesterRewardSymbol: 'WVP',
      withdrawAlert:
        'IMPORTANT: Please note that this is a Governance Vault with a high withdrawal fee, and carefully check that your input amount is correct.',
      walletInfo:
        '<a class="wallet-info" href="https://app.acsi.finance/#/pool/0x44a9ce69ef2a71a9630697ca5cab3f4adaf8f90d00010000000000000000001a" target="_blank"><span data-i18n="get">Get</span> WAV-ACS-BNB</a>',
      transactionInfo:
        '<a class="wallet-info text-warning" href="https://docs.thegreatwave.io/tokenomics" target="_blank"><span data-i18n="withdrawFee">Withdrawal fee</span>: 88%</a>'
    },
    tags: ['wav']
  },
  {
    farm: {
      master: Config.wavFarm,
      token: '0x44a9cE69eF2a71A9630697CA5CaB3f4aDaF8f90d',
      tokenSymbol: 'WAV-ACS-BNB',
      tokenImage: 'wav.png',
      walletInfo:
        '<a class="wallet-info" href="https://app.acsi.finance/#/pool/0x44a9ce69ef2a71a9630697ca5cab3f4adaf8f90d00010000000000000000001a" target="_blank"><span data-i18n="get">Get</span> WAV-ACS-BNB</a>',
      transactionInfo: `<a class="wallet-info" href="https://docs.thegreatwave.io/tokenomics" target="_blank">Harvest fee: ${Config.wavHarvestFee} WAV</a>`
    },
    tags: ['wav']
  },
  {
    farm: {
      master: Config.wavFarm,
      token: '0x70650826a8c0b0B3b46cd95667Df79d02b66C002',
      tokenSymbol: 'WAV-BNB',
      tokenImage: 'wav.png',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0x888888883BF208d3b1AcD0052a88b9Fd07bA5851" target="_blank"><span data-i18n="get">Get</span> WAV-BNB</a>',
      transactionInfo: `<a class="wallet-info" href="https://docs.thegreatwave.io/tokenomics" target="_blank">Harvest fee: ${Config.wavHarvestFee} WAV</a>`
    },
    tags: ['wav', 'pancake', 'pancakeV2']
  },
  {
    farm: {
      master: Config.wavFarm,
      token: '0x894eD9026De37AfD9CCe1E6C0BE7d6b510e3FfE5',
      tokenSymbol: 'ACS-ACSI-BNB-BUSD',
      title: 'A2B2 (WAV)',
      tokenImage: 'acsi-acs.svg',
      walletInfo:
        '<a class="wallet-info" href="https://app.acsi.finance/#/pool/0x894ed9026de37afd9cce1e6c0be7d6b510e3ffe5000100000000000000000001" target="_blank"><span data-i18n="get">Get</span> ACS-ACSI-BNB-BUSD</a>',
      transactionInfo: `<a class="wallet-info" href="https://docs.thegreatwave.io/tokenomics" target="_blank">Harvest fee: ${Config.wavHarvestFee} WAV</a>`
    },
    tags: ['core', 'wav']
  }
];

Config.farmVaultsDeprecated = [
  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarmV2b,
      token: '0x699c58d77027Ae44608808B3863C394A194d76f6',
      tokenSymbol: 'ACSI-ACS',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0x4197C6EF3879a08cD51e5560da5064B773aa1d29/0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389" target="_blank"><span data-i18n="get">Get</span> ACSI-ACS</a>',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acsi-farms" target="_blank">Harvest fee: ${Config.acsiHarvestFee} ACSI</a>`
    },
    tags: ['core', 'acsi']
  },
  {
    vault: {
      deprecated: true,
      address: '0x4939A789b36C058b5b96D3e24588674d81a9EFBD',
      tokenSymbol: 'NAOS-BNB',
      tokenImage: 'cake-bnb.svg',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0x758d08864fB6cCE3062667225ca10b8F00496cc2" target="_blank"><span data-i18n="get">Get</span> NAOS-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsNAOS-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0x4c6f2643001669842778D8B6C1878E3EbC1Ea377',
      tokenSymbol: 'REEF-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e" target="_blank"><span data-i18n="get">Get</span> REEF-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsREEF-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0x2883D6d514D2474B942769B981702526897D74D4',
      tokenSymbol: 'BAND-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18" target="_blank"><span data-i18n="get">Get</span> BAND-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsBAND-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0xb00B62da1cd28AB88983960487F2902c64c00bc5',
      tokenSymbol: 'sACS-SXP',
      tokenImage: 'sxp.svg',
      harvesterRewardSymbol: 'SXP',
      walletInfo:
        '<a class="wallet-info" href="https://swap.swipe.org/add-liquidity?type=add&inputCurrency=0x4197c6ef3879a08cd51e5560da5064b773aa1d29&outputCurrency=0x47bead2563dcbf3bf2c9407fea4dc236faba485a" target="_blank"><span data-i18n="get">Get</span> sACS-SXP</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acs.sACS-SXP',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['swipeswap']
  },
  {
    vault: {
      deprecated: true,
      address: '0x03f52C3612df0dB3c86a4776a20caece8A194f38',
      tokenSymbol: 'sVAI-BNB',
      tokenImage: 'sxp.svg',
      harvesterRewardSymbol: 'SXP',
      walletInfo:
        '<a class="wallet-info" href="https://swap.swipe.org/add-liquidity?type=add&inputCurrency=0x4bd17003473389a42daf6a0a729f6fdb328bbbd7&outputCurrency=0x0000000000000000000000000000000000000000" target="_blank"><span data-i18n="get">Get</span> sVAI-BNB</a>',
      fromBlock: 5788888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acs.sVAI-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['swipeswap']
  },
  {
    vault: {
      deprecated: true,
      address: '0x1DA371DC8127b0cdED8D13fF20F062Bb9e02C1a3',
      tokenSymbol: 'sSXP-BNB',
      tokenImage: 'sxp.svg',
      harvesterRewardSymbol: 'SXP',
      walletInfo:
        '<a class="wallet-info" href="https://swap.swipe.org/add-liquidity?type=add&inputCurrency=0x47bead2563dcbf3bf2c9407fea4dc236faba485a&outputCurrency=0x0000000000000000000000000000000000000000" target="_blank"><span data-i18n="get">Get</span> sSXP-BNB</a>',
      fromBlock: 5758888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acs.sSXP-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['swipeswap']
  },
  {
    vault: {
      deprecated: true,
      address: '0x49C4b95dc2198F2c4c9516834a8AcBFc4b3e4429',
      tokenSymbol: 'sSXP-BUSD',
      tokenImage: 'sxp.svg',
      harvesterRewardSymbol: 'SXP',
      walletInfo:
        '<a class="wallet-info" href="https://swap.swipe.org/add-liquidity?type=add&inputCurrency=0x47bead2563dcbf3bf2c9407fea4dc236faba485a&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56" target="_blank"><span data-i18n="get">Get</span> sSXP-BUSD</a>',
      fromBlock: 5728888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acs.sSXP-BUSD',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['swipeswap']
  },
  {
    vault: {
      deprecated: true,
      address: '0x2C39de04688D71Cd7d502297dEf53E4be0c420B3',
      tokenSymbol: 'sSXP-BTC',
      tokenImage: 'sxp.svg',
      harvesterRewardSymbol: 'SXP',
      walletInfo:
        '<a class="wallet-info" href="https://swap.swipe.org/add-liquidity?type=add&inputCurrency=0x47bead2563dcbf3bf2c9407fea4dc236faba485a&outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" target="_blank"><span data-i18n="get">Get</span> sSXP-BTC</a>',
      fromBlock: 5728888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acs.sSXP-BTC',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['swipeswap']
  },
  {
    vault: {
      deprecated: true,
      address: '0x7Cc9bc059401964E060f027a027FCF039C954C6b',
      tokenSymbol: 'sBTC-BNB',
      tokenImage: 'sxp.svg',
      harvesterRewardSymbol: 'SXP',
      walletInfo:
        '<a class="wallet-info" href="https://swap.swipe.org/add-liquidity?type=add&inputCurrency=0x0000000000000000000000000000000000000000&outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c" target="_blank"><span data-i18n="get">Get</span> sBTC-BNB</a>',
      fromBlock: 7453888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acs.sBTC-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['swipeswap']
  },

  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarmV2b,
      token: '0x49b823Bec2d4E89Cf4368bD743de1bC74871af22',
      tokenSymbol: 'BTC-renBTC-pBTC',
      tokenImage: 'acsi-acs.svg',
      walletInfo:
        '<a class="wallet-info" href="https://app.acsi.finance/#/pool/0x49b823bec2d4e89cf4368bd743de1bc74871af22000000000000000000000006" target="_blank"><span data-i18n="get">Get</span> BTC-renBTC-pBTC</a>',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acsi-farms" target="_blank">Harvest fee: ${Config.acsiHarvestFee} ACSI</a>`
    },
    tags: ['acsi', 'acsifi']
  },
  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarmV2b,
      token: '0x2d00C55b93a03830D19eb97A5e48F77aCaac2D63',
      tokenSymbol: 'ACS3BTC',
      walletInfo:
        '<a class="wallet-info" href="https://app.acryptos.com/stableswap/deposit/btc/" target="_blank"><span data-i18n="get">Get</span> ACS3BTC</a>',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acsi-farms" target="_blank">Harvest fee: ${Config.acsiHarvestFee} ACSI</a>`
    },
    tags: ['acsi', 'stableswap']
  },
  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarmV2b,
      token: '0x49440376254290b3264183807A16450457f02B28',
      tokenSymbol: 'ACS4QUSD',
      walletInfo:
        '<a class="wallet-info" href="https://app.acryptos.com/stableswap/deposit/qusd/" target="_blank"><span data-i18n="get">Get</span> ACS4QUSD</a>',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acsi-farms" target="_blank">Harvest fee: ${Config.acsiHarvestFee} ACSI</a>`
    },
    tags: ['acsi', 'stableswap']
  },
  {
    vault: {
      deprecated: true,
      address: '0xC3eF174A704aB413cA81A5eB3f7eB08B535e91C4',
      tokenSymbol: 'pBTC-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C" target="_blank"><span data-i18n="get">Get</span> pBTC-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acspBTC-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0x627E6506F26f7ae6250667f4F5A494E0e6443ED5',
      tokenSymbol: 'COMP-ETH',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8" target="_blank"><span data-i18n="get">Get</span> COMP-ETH</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsCOMP-ETH'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0xA7fE1ac962E451312208e09A7c894EBa44833E86',
      tokenSymbol: 'MIR-UST',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9" target="_blank"><span data-i18n="get">Get</span> MIR-UST</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsMIR-UST'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0x46E65055Be06AF470384BcFe140b4ec8a160d15f',
      tokenSymbol: 'CTK-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929" target="_blank"><span data-i18n="get">Get</span> CTK-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsCTK-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0x8383661eCF333FCe4Bf51d498D7c94e2a0c7d5AF',
      tokenSymbol: 'WATCH-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0" target="_blank"><span data-i18n="get">Get</span> WATCH-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsWATCH-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0xC34E0597e50A173E3bE8682512aff80D0DFd49f9',
      tokenSymbol: 'SWINGBY-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739" target="_blank"><span data-i18n="get">Get</span> SWINGBY-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsSWINGBY-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0xAd4BBa0Da4889830fa8C9c7B1b04Ab4faa791F6f',
      tokenSymbol: 'FOR-BUSD',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x658A109C5900BC6d2357c87549B651670E5b0539" target="_blank"><span data-i18n="get">Get</span> FOR-BUSD</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsFOR-BUSD'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0x3d4bECF8C867d0Ca5C40b5c9449b96C481425334',
      tokenSymbol: 'ZIL-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787" target="_blank"><span data-i18n="get">Get</span> ZIL-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsZIL-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0xAdb783Ca0eeDe47d882A8E1C656A1E681a388adA',
      tokenSymbol: 'VRT-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0x5F84ce30DC3cF7909101C69086c50De191895883" target="_blank"><span data-i18n="get">Get</span> VRT-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsVRT-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0xdFE0E5992dE7Ca8277A40C37ACCDE36dCb9c94C6',
      tokenSymbol: 'YFI-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e" target="_blank"><span data-i18n="get">Get</span> YFI-BNB</a>',
      fromBlock: 6953888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsYFI-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },
  {
    vault: {
      deprecated: true,
      address: '0xE47C98A41Ea2f48C17d6B58420CbCeD5E69F5987',
      tokenSymbol: 'UNFI-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://pancakeswap.finance/add/BNB/0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B" target="_blank"><span data-i18n="get">Get</span> UNFI-BNB</a>',
      fromBlock: 6958888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsUNFI-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV2']
  },

  {
    farm: {
      deprecated: true,
      token: '0x2366eC9dDD1eB27506Fa2Ed48Da8f2D9e99ed3c7',
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'ACS-BNB',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x4197C6EF3879a08cD51e5560da5064B773aa1d29" target="_blank"><span data-i18n="get">Get</span> ACS-BNB</a>'
    },
    tags: ['core', 'acs']
  },

  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarm,
      pid: 0,
      tokenSymbol: 'ACSI-ACS',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0x4197C6EF3879a08cD51e5560da5064B773aa1d29/0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389" target="_blank"><span data-i18n="get">Get</span> ACSI-ACS</a>',
      transactionInfo: zeroAcsiFarmV1Fees
    },
    tags: ['acsiV1']
  },
  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarm,
      pid: 1,
      tokenSymbol: 'ACS4USD',
      walletInfo:
        '<a class="wallet-info" href="https://app.acryptos.com/stableswap/deposit/" target="_blank"><span data-i18n="get">Get</span> ACS4USD</a>',
      transactionInfo: zeroAcsiFarmV1Fees
    },
    tags: ['acsiV1']
  },
  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarm,
      pid: 2,
      tokenSymbol: 'ACS4VAI',
      walletInfo:
        '<a class="wallet-info" href="https://app.acryptos.com/stableswap/deposit/vai/" target="_blank"><span data-i18n="get">Get</span> ACS4VAI</a>',
      transactionInfo: zeroAcsiFarmV1Fees
    },
    tags: ['acsiV1']
  },
  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarm,
      pid: 4,
      tokenSymbol: 'ACS4UST',
      walletInfo:
        '<a class="wallet-info" href="https://app.acryptos.com/stableswap/deposit/ust/" target="_blank"><span data-i18n="get">Get</span> ACS4UST</a>',
      transactionInfo: zeroAcsiFarmV1Fees
    },
    tags: ['acsiV1']
  },
  {
    farm: {
      deprecated: true,
      master: Config.acsiMasterFarm,
      pid: 3,
      tokenSymbol: 'ACS4QUSD',
      walletInfo:
        '<a class="wallet-info" href="https://app.acryptos.com/stableswap/deposit/qusd/" target="_blank"><span data-i18n="get">Get</span> ACS4QUSD</a>',
      transactionInfo: zeroAcsiFarmV1Fees
    },
    tags: ['acsiV1']
  },

  // PCS V1 Vault&Farms
  {
    vault: {
      deprecated: true,
      address: '0x68fdCd299E33Bee7695eFBc227Ab948Ae29BFc3c',
      tokenSymbol: 'CAKE-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82" target="_blank"><span data-i18n="get">Get</span> CAKE-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsCAKE-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xcd630D39F76C12Af11c2Ed9876ccF976C47A08C3',
      tokenSymbol: 'XVS-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63" target="_blank"><span data-i18n="get">Get</span> XVS-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsXVS-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1', 'venus']
  },
  {
    vault: {
      deprecated: true,
      address: '0xFd1EfbAe73E0BAF23fB0Fb394480405609B331b6',
      tokenSymbol: 'SXP-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A" target="_blank"><span data-i18n="get">Get</span> SXP-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsSXP-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1', 'swipe']
  },
  {
    vault: {
      deprecated: true,
      address: '0x97391c2A035bdCF537c1ce2a70D14fAA3d44317f',
      tokenSymbol: 'BUSD-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xe9e7cea3dedca5984780bafc599bd69add087d56" target="_blank"><span data-i18n="get">Get</span> BUSD-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsBUSD-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xA0753CC49EB66bb4Bc80E8f042A6dE21fc03e5cD',
      tokenSymbol: 'USDT-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x55d398326f99059fF775485246999027B3197955" target="_blank"><span data-i18n="get">Get</span> USDT-BNB</a>',
      fromBlock: 5123888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsUSDT-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xCfbB1A0522e70fA5688c023b37C58e43c9A6398E',
      tokenSymbol: 'USDT-BUSD',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x55d398326f99059fF775485246999027B3197955" target="_blank"><span data-i18n="get">Get</span> USDT-BUSD</a>',
      fromBlock: 5188888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsUSDT-BUSD',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1', 'usd']
  },
  {
    vault: {
      deprecated: true,
      address: '0x51d6b8A1d3f6b4aEf8bcaECD8EaD7ff2EFDcbB73',
      tokenSymbol: 'ETH-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x2170Ed0880ac9A755fd29B2688956BD959F933F8" target="_blank"><span data-i18n="get">Get</span> ETH-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsETH-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x161A623c27D20d3717ffe279889e50eEb23962c3',
      tokenSymbol: 'BTC-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c" target="_blank"><span data-i18n="get">Get</span> BTC-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsBTC-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x1B5ca4cBf6a2f453506fEC365dc0061d8D127Ec9',
      tokenSymbol: 'pBTC-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C" target="_blank"><span data-i18n="get">Get</span> pBTC-BNB</a>',
      fromBlock: 6168888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acs.pBTC-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x15edF148b5d43684075B77EEa866FF833a54d73c',
      tokenSymbol: 'LTC-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x4338665CBB7B2485A8855A139b75D5e34AB0DB94" target="_blank"><span data-i18n="get">Get</span> LTC-BNB</a>',
      fromBlock: 3970888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsLTC-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x55D2AE06B0904d70d091BC32608F37C5FBE375D4',
      tokenSymbol: 'YFI-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e" target="_blank"><span data-i18n="get">Get</span> YFI-BNB</a>',
      fromBlock: 4528888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsYFI-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xDAC0c9b3CaccF7e76D2F238663c98fDd9D07F323',
      tokenSymbol: 'ADA-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47" target="_blank"><span data-i18n="get">Get</span> ADA-BNB</a>',
      fromBlock: 4168888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsADA-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xed08BD00c24197f696A58CAdb3Bca9c67d8110A7',
      tokenSymbol: 'UNI-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xBf5140A22578168FD562DCcF235E5D43A02ce9B1" target="_blank"><span data-i18n="get">Get</span> UNI-BNB</a>',
      fromBlock: 4878888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsUNI-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xEC94dC055d478C97E7C434dd6BC34eAb1c527aB3',
      tokenSymbol: 'DOT-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402" target="_blank"><span data-i18n="get">Get</span> DOT-BNB</a>',
      fromBlock: 5008888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsDOT-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xA20806fB4fC6dC3434Bba7a8587f0efEB0e69584',
      tokenSymbol: 'BAND-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18" target="_blank"><span data-i18n="get">Get</span> BAND-BNB</a>',
      fromBlock: 5008888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsBAND-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xD7d38dBcC1cF9DF5f644b23eB19DdeA17105ec25',
      tokenSymbol: 'ZIL-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787" target="_blank"><span data-i18n="get">Get</span> ZIL-BNB</a>',
      fromBlock: 6098888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsZIL-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xeF015F747dC06672501Ff0F20c3b94F56FA5427F',
      tokenSymbol: 'TKO-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x9f589e3eabe42ebC94A44727b3f3531C0c877809" target="_blank"><span data-i18n="get">Get</span> TKO-BNB</a>',
      fromBlock: 6378888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsTKO-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x6200F22041bDA696D3A639aF1ddb62747E384941',
      tokenSymbol: 'TLM-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x2222227E22102Fe3322098e4CBfE18cFebD57c95" target="_blank"><span data-i18n="get">Get</span> TLM-BNB</a>',
      fromBlock: 6538888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsTLM-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xe427a9688C0d16eA5d29DB0dcC1D6E1e61Ea9908',
      tokenSymbol: 'TWT-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x4B0F1812e5Df2A09796481Ff14017e6005508003" target="_blank"><span data-i18n="get">Get</span> TWT-BNB</a>',
      fromBlock: 3710934
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsTWT-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xDB335c7c4AD429A1F53971fd4644b599DC631306',
      tokenSymbol: 'UNFI-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B" target="_blank"><span data-i18n="get">Get</span> UNFI-BNB</a>'
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsUNFI-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x93e2e1e384dC298bDEafaEe9751841EA211f2d42',
      tokenSymbol: 'BTCST-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x78650B139471520656b9E7aA7A5e9276814a38e9" target="_blank"><span data-i18n="get">Get</span> BTCST-BNB</a>',
      fromBlock: 3949888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsBTCST-BNB'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x02AaBF12f7C377916BB828BcE3d45c778d919d0e',
      tokenSymbol: 'SFP-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb" target="_blank"><span data-i18n="get">Get</span> SFP-BNB</a>',
      fromBlock: 4703888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsSFP-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x2932568487318969BE7593a27dD5d45aB521e50D',
      tokenSymbol: 'FRONT-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b" target="_blank"><span data-i18n="get">Get</span> FRONT-BNB</a>',
      fromBlock: 4948888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsFRONT-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xC109d8B9F89Bd939B81Df4Fe47951f9683207102',
      tokenSymbol: 'WATCH-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0" target="_blank"><span data-i18n="get">Get</span> WATCH-BNB</a>',
      fromBlock: 5958888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsWATCH-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x2D8483Bc2a9E2723711888532Fd542483F041137',
      tokenSymbol: 'SWINGBY-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739" target="_blank"><span data-i18n="get">Get</span> SWINGBY-BNB</a>',
      fromBlock: 5269888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsSWINGBY-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xa1125B756e0ac05ff5d07Cde4D511E1837aADc88',
      tokenSymbol: 'ALICE-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xAC51066d7bEC65Dc4589368da368b212745d63E8" target="_blank"><span data-i18n="get">Get</span> ALICE-BNB</a>',
      fromBlock: 5703888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsALICE-BNB',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x2654346a32D4233B3266AF3C5FD7BAE3C571F345',
      tokenSymbol: 'FOR-BUSD',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x658A109C5900BC6d2357c87549B651670E5b0539" target="_blank"><span data-i18n="get">Get</span> FOR-BUSD</a>',
      fromBlock: 5818888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsFOR-BUSD',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xa387beD33E0415302614545eAD370c27778B955E',
      tokenSymbol: 'TPT-BUSD',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xECa41281c24451168a37211F0bc2b8645AF45092" target="_blank"><span data-i18n="get">Get</span> TPT-BUSD</a>',
      fromBlock: 5578888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsTPT-BUSD',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x373561f3119353e50F21EE1181dd8749ae8276b9',
      tokenSymbol: 'MIR-UST',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9" target="_blank"><span data-i18n="get">Get</span> MIR-UST</a>',
      fromBlock: 6308888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsMIR-UST',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xED51b5c077B71d5B475E30C88B72632fa679fCE3',
      tokenSymbol: 'COMP-ETH',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8" target="_blank"><span data-i18n="get">Get</span> COMP-ETH</a>',
      fromBlock: 4758888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsCOMP-ETH',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0x3679d4C2752bEef8632fd12c45b005ecB2774EF0',
      tokenSymbol: 'SUSHI-ETH',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x947950BcC74888a40Ffa2593C5798F11Fc9124C4" target="_blank"><span data-i18n="get">Get</span> SUSHI-ETH</a>',
      fromBlock: 5188888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsSUSHI-ETH',
      transactionInfo: `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms-v2" target="_blank">Harvest fee: ${Config.acsHarvestFee} ACS</a>`
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },
  {
    vault: {
      deprecated: true,
      address: '0xe9d9f54Ab89F712ABBdbb3C0F63f2D6eDAa3869c',
      tokenSymbol: 'BETH-ETH',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B" target="_blank"><span data-i18n="get">Get</span> BETH-ETH</a>',
      fromBlock: 4608888
    },
    farm: {
      deprecated: true,
      master: Config.acsMasterFarmV2,
      tokenSymbol: 'acsBETH-ETH'
    },
    tags: ['acs', 'pancake', 'pancakeV1']
  },

  // ACS V1 Farms
  {
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 0,
      tokenSymbol: 'ACS-BNB',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x4197C6EF3879a08cD51e5560da5064B773aa1d29" target="_blank"><span data-i18n="get">Get</span> ACS-BNB</a>'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x6Fe6762E9314ad80803fef083c8bB16Af435a628',
      tokenSymbol: 'BNB',
      harvesterRewardSymbol: 'XVS',
      isBnbVault: true,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 10,
      tokenSymbol: 'acsBNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x0395fCC8E1a1E30A1427D4079aF6E23c805E3eeF',
      tokenSymbol: 'BTC',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 2908281,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 15,
      tokenSymbol: 'acsBTC'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x35cAdD2DAA782556B7fD90A98663BaCDb78d863e',
      tokenSymbol: 'ETH',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 2938888,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 16,
      tokenSymbol: 'acsETH'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x883A0D28dbac2E713e87aa2448595438D8016811',
      tokenSymbol: 'VAI',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 3533888
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 17,
      tokenSymbol: 'acsVAI'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x532d5775cE71Cb967B78acbc290f80DF80A9bAa5',
      tokenSymbol: 'BUSD',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 2597909,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 9,
      tokenSymbol: 'acsBUSD'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x32Fe1bE67102c10F6f5E798cb24723E738A31943',
      tokenSymbol: 'USDT',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 2645816,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 11,
      tokenSymbol: 'acsUSDT'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xA4964271b476B0730Acf86DD9f8D270b5E804126',
      tokenSymbol: 'DAI',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 3776888,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 19,
      tokenSymbol: 'acsDAI'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x4E58b693092e33e46A8734B9c4064B82afdcA14f',
      tokenSymbol: 'USDC',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 2711765,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 13,
      tokenSymbol: 'acsUSDC'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xFc698dAE6c5B7e38F8Eb8Fc240f23c598d17e5e2',
      tokenSymbol: 'SXP',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 2738000,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 14,
      tokenSymbol: 'acsSXP'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xB1Dc4FEe3248362d54E15192EA771E82dfafd5Bd',
      tokenSymbol: 'XVS',
      harvesterRewardSymbol: 'XVS',
      fromBlock: 2674259,
      showBorrowLimit: true
      // disableDeposit:true,
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 12,
      tokenSymbol: 'acsXVS'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xB6eB654FBDc697edD73174a19B074BC67c00a0C0',
      tokenSymbol: 'CAKE'
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 1,
      tokenSymbol: 'acsCAKE'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x68fdCd299E33Bee7695eFBc227Ab948Ae29BFc3c',
      tokenSymbol: 'CAKE-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82" target="_blank"><span data-i18n="get">Get</span> CAKE-BNB</a>'
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 7,
      tokenSymbol: 'acsCAKE-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xcd630D39F76C12Af11c2Ed9876ccF976C47A08C3',
      tokenSymbol: 'XVS-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63" target="_blank"><span data-i18n="get">Get</span> XVS-BNB</a>'
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 4,
      tokenSymbol: 'acsXVS-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xFd1EfbAe73E0BAF23fB0Fb394480405609B331b6',
      tokenSymbol: 'SXP-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A" target="_blank"><span data-i18n="get">Get</span> SXP-BNB</a>'
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 5,
      tokenSymbol: 'acsSXP-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x97391c2A035bdCF537c1ce2a70D14fAA3d44317f',
      tokenSymbol: 'BUSD-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0xe9e7cea3dedca5984780bafc599bd69add087d56" target="_blank"><span data-i18n="get">Get</span> BUSD-BNB</a>'
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 6,
      tokenSymbol: 'acsBUSD-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x51d6b8A1d3f6b4aEf8bcaECD8EaD7ff2EFDcbB73',
      tokenSymbol: 'ETH-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x2170Ed0880ac9A755fd29B2688956BD959F933F8" target="_blank"><span data-i18n="get">Get</span> ETH-BNB</a>'
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 3,
      tokenSymbol: 'acsETH-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x161A623c27D20d3717ffe279889e50eEb23962c3',
      tokenSymbol: 'BTC-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c" target="_blank"><span data-i18n="get">Get</span> BTC-BNB</a>'
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 2,
      tokenSymbol: 'acsBTC-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x15edF148b5d43684075B77EEa866FF833a54d73c',
      tokenSymbol: 'LTC-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x4338665CBB7B2485A8855A139b75D5e34AB0DB94" target="_blank"><span data-i18n="get">Get</span> LTC-BNB</a>',
      fromBlock: 3970888
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 21,
      tokenSymbol: 'acsLTC-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x55D2AE06B0904d70d091BC32608F37C5FBE375D4',
      tokenSymbol: 'YFI-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e" target="_blank"><span data-i18n="get">Get</span> YFI-BNB</a>',
      fromBlock: 4528888
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 24,
      tokenSymbol: 'acsYFI-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xDAC0c9b3CaccF7e76D2F238663c98fDd9D07F323',
      tokenSymbol: 'ADA-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47" target="_blank"><span data-i18n="get">Get</span> ADA-BNB</a>',
      fromBlock: 4168888
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 23,
      tokenSymbol: 'acsADA-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xe427a9688C0d16eA5d29DB0dcC1D6E1e61Ea9908',
      tokenSymbol: 'TWT-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x4B0F1812e5Df2A09796481Ff14017e6005508003" target="_blank"><span data-i18n="get">Get</span> TWT-BNB</a>',
      fromBlock: 3710934
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 18,
      tokenSymbol: 'acsTWT-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xDB335c7c4AD429A1F53971fd4644b599DC631306',
      tokenSymbol: 'UNFI-BNB',
      harvesterRewardSymbol: 'CAKE'
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 22,
      tokenSymbol: 'acsUNFI-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0x93e2e1e384dC298bDEafaEe9751841EA211f2d42',
      tokenSymbol: 'BTCST-BNB',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/BNB/0x78650B139471520656b9E7aA7A5e9276814a38e9" target="_blank"><span data-i18n="get">Get</span> BTCST-BNB</a>',
      fromBlock: 3949888
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 20,
      tokenSymbol: 'acsBTCST-BNB'
    },
    tags: ['acsV1']
  },
  {
    vault: {
      showFarmDeprecatedOnly: true,
      address: '0xe9d9f54Ab89F712ABBdbb3C0F63f2D6eDAa3869c',
      tokenSymbol: 'BETH-ETH',
      harvesterRewardSymbol: 'CAKE',
      walletInfo:
        '<a class="wallet-info" href="https://acs-lp.unrekt.net/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B" target="_blank"><span data-i18n="get">Get</span> BETH-ETH</a>',
      fromBlock: 4608888
    },
    farm: {
      transactionInfo: zeroFarmV1Fees,
      deprecated: true,
      master: Config.acsMasterFarm,
      pid: 25,
      tokenSymbol: 'acsBETH-ETH'
    },
    tags: ['acsV1']
  }
];

Config.masterFarms = {};

Config.abis = {
  erc20: [
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { name: 'guy', type: 'address' },
        { name: 'wad', type: 'uint256' }
      ],
      name: 'approve',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { name: 'src', type: 'address' },
        { name: 'dst', type: 'address' },
        { name: 'wad', type: 'uint256' }
      ],
      name: 'transferFrom',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ name: 'wad', type: 'uint256' }],
      name: 'withdraw',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ name: '', type: 'uint8' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ name: '', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { name: 'dst', type: 'address' },
        { name: 'wad', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'deposit',
      outputs: [],
      payable: true,
      stateMutability: 'payable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { name: '', type: 'address' },
        { name: '', type: 'address' }
      ],
      name: 'allowance',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    { payable: true, stateMutability: 'payable', type: 'fallback' },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: 'src', type: 'address' },
        { indexed: true, name: 'guy', type: 'address' },
        { indexed: false, name: 'wad', type: 'uint256' }
      ],
      name: 'Approval',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: 'src', type: 'address' },
        { indexed: true, name: 'dst', type: 'address' },
        { indexed: false, name: 'wad', type: 'uint256' }
      ],
      name: 'Transfer',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: 'dst', type: 'address' },
        { indexed: false, name: 'wad', type: 'uint256' }
      ],
      name: 'Deposit',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: 'src', type: 'address' },
        { indexed: false, name: 'wad', type: 'uint256' }
      ],
      name: 'Withdrawal',
      type: 'event'
    }
  ],
  uniswapV2Pair: [
    {
      inputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Approval',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount0',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount1',
          type: 'uint256'
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' }
      ],
      name: 'Burn',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount0',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount1',
          type: 'uint256'
        }
      ],
      name: 'Mint',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount0In',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount1In',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount0Out',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount1Out',
          type: 'uint256'
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' }
      ],
      name: 'Swap',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint112',
          name: 'reserve0',
          type: 'uint112'
        },
        {
          indexed: false,
          internalType: 'uint112',
          name: 'reserve1',
          type: 'uint112'
        }
      ],
      name: 'Sync',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Transfer',
      type: 'event'
    },
    {
      constant: true,
      inputs: [],
      name: 'DOMAIN_SEPARATOR',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'MINIMUM_LIQUIDITY',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'PERMIT_TYPEHASH',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' }
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' }
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
      name: 'burn',
      outputs: [
        { internalType: 'uint256', name: 'amount0', type: 'uint256' },
        { internalType: 'uint256', name: 'amount1', type: 'uint256' }
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'factory',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'getReserves',
      outputs: [
        { internalType: 'uint112', name: '_reserve0', type: 'uint112' },
        { internalType: 'uint112', name: '_reserve1', type: 'uint112' },
        { internalType: 'uint32', name: '_blockTimestampLast', type: 'uint32' }
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_token0', type: 'address' },
        { internalType: 'address', name: '_token1', type: 'address' }
      ],
      name: 'initialize',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'kLast',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
      name: 'mint',
      outputs: [
        { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'nonces',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' }
      ],
      name: 'permit',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'price0CumulativeLast',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'price1CumulativeLast',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
      name: 'skim',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'amount0Out', type: 'uint256' },
        { internalType: 'uint256', name: 'amount1Out', type: 'uint256' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'bytes', name: 'data', type: 'bytes' }
      ],
      name: 'swap',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'sync',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'token0',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'token1',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' }
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  controller: [
    {
      inputs: [
        { internalType: 'address', name: '_governance', type: 'address' },
        { internalType: 'address', name: '_rewards', type: 'address' }
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'address', name: '_strategy', type: 'address' }
      ],
      name: 'approveStrategy',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' }
      ],
      name: 'approvedStrategies',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '_token', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' }
      ],
      name: 'converters',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'earn',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: '_strategy', type: 'address' },
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'uint256', name: 'parts', type: 'uint256' }
      ],
      name: 'getExpectedReturn',
      outputs: [{ internalType: 'uint256', name: 'expected', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'governance',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_strategy', type: 'address' },
        { internalType: 'address', name: '_token', type: 'address' }
      ],
      name: 'inCaseStrategyTokenGetStuck',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'inCaseTokensGetStuck',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'max',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'onesplit',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'address', name: '_strategy', type: 'address' }
      ],
      name: 'revokeStrategy',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'rewards',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_input', type: 'address' },
        { internalType: 'address', name: '_output', type: 'address' },
        { internalType: 'address', name: '_converter', type: 'address' }
      ],
      name: 'setConverter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_governance', type: 'address' }
      ],
      name: 'setGovernance',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: '_onesplit', type: 'address' }],
      name: 'setOneSplit',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: '_rewards', type: 'address' }],
      name: 'setRewards',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_split', type: 'uint256' }],
      name: 'setSplit',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_strategist', type: 'address' }
      ],
      name: 'setStrategist',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'address', name: '_strategy', type: 'address' }
      ],
      name: 'setStrategy',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'address', name: '_vault', type: 'address' }
      ],
      name: 'setVault',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'split',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'strategies',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'strategist',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'vaults',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' }
      ],
      name: 'withdraw',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: '_token', type: 'address' }],
      name: 'withdrawAll',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_strategy', type: 'address' },
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'uint256', name: 'parts', type: 'uint256' }
      ],
      name: 'yearn',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  vault: [
    {
      inputs: [
        { internalType: 'address', name: '_token', type: 'address' },
        { internalType: 'address', name: '_controller', type: 'address' }
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Approval',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Transfer',
      type: 'event'
    },
    { payable: true, stateMutability: 'payable', type: 'fallback' },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' }
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'controller',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'governance',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'max',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'min',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'token',
      outputs: [{ internalType: 'contractIERC20', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'balance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_min', type: 'uint256' }],
      name: 'setMin',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_governance', type: 'address' }
      ],
      name: 'setGovernance',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_controller', type: 'address' }
      ],
      name: 'setController',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'available',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'earn',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'depositAll',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
      name: 'deposit',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'depositETH',
      outputs: [],
      payable: true,
      stateMutability: 'payable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'withdrawAll',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'withdrawAllETH',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'reserve', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'harvest',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_shares', type: 'uint256' }],
      name: 'withdraw',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_shares', type: 'uint256' }],
      name: 'withdrawETH',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'getPricePerFullShare',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    }
  ],
  strategy: [
    {
      constant: false,
      inputs: [],
      name: 'harvest',
      outputs: [
        { internalType: 'uint256', name: 'harvesterRewarded', type: 'uint256' }
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'borrowLimit',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  bVault: [
    {
      inputs: [
        {
          internalType: 'contractIAuthorizer',
          name: 'authorizer',
          type: 'address'
        },
        { internalType: 'contractIWETH', name: 'weth', type: 'address' },
        {
          internalType: 'uint256',
          name: 'pauseWindowDuration',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'bufferPeriodDuration',
          type: 'uint256'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'contractIAuthorizer',
          name: 'newAuthorizer',
          type: 'address'
        }
      ],
      name: 'AuthorizerChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'contractIERC20',
          name: 'token',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'recipient',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'ExternalBalanceTransfer',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'contractIFlashLoanRecipient',
          name: 'recipient',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'contractIERC20',
          name: 'token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'feeAmount',
          type: 'uint256'
        }
      ],
      name: 'FlashLoan',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'contractIERC20',
          name: 'token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'int256',
          name: 'delta',
          type: 'int256'
        }
      ],
      name: 'InternalBalanceChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'bool', name: 'paused', type: 'bool' }
      ],
      name: 'PausedStateChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'poolId',
          type: 'bytes32'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'liquidityProvider',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'contractIERC20[]',
          name: 'tokens',
          type: 'address[]'
        },
        {
          indexed: false,
          internalType: 'int256[]',
          name: 'deltas',
          type: 'int256[]'
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'protocolFeeAmounts',
          type: 'uint256[]'
        }
      ],
      name: 'PoolBalanceChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'poolId',
          type: 'bytes32'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'assetManager',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'contractIERC20',
          name: 'token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'int256',
          name: 'cashDelta',
          type: 'int256'
        },
        {
          indexed: false,
          internalType: 'int256',
          name: 'managedDelta',
          type: 'int256'
        }
      ],
      name: 'PoolBalanceManaged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'poolId',
          type: 'bytes32'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'poolAddress',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'enumIVault.PoolSpecialization',
          name: 'specialization',
          type: 'uint8'
        }
      ],
      name: 'PoolRegistered',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'relayer',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        },
        { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' }
      ],
      name: 'RelayerApprovalChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'poolId',
          type: 'bytes32'
        },
        {
          indexed: true,
          internalType: 'contractIERC20',
          name: 'tokenIn',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'contractIERC20',
          name: 'tokenOut',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amountIn',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amountOut',
          type: 'uint256'
        }
      ],
      name: 'Swap',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'poolId',
          type: 'bytes32'
        },
        {
          indexed: false,
          internalType: 'contractIERC20[]',
          name: 'tokens',
          type: 'address[]'
        }
      ],
      name: 'TokensDeregistered',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'poolId',
          type: 'bytes32'
        },
        {
          indexed: false,
          internalType: 'contractIERC20[]',
          name: 'tokens',
          type: 'address[]'
        },
        {
          indexed: false,
          internalType: 'address[]',
          name: 'assetManagers',
          type: 'address[]'
        }
      ],
      name: 'TokensRegistered',
      type: 'event'
    },
    {
      inputs: [],
      name: 'WETH',
      outputs: [{ internalType: 'contractIWETH', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'enumIVault.SwapKind', name: 'kind', type: 'uint8' },
        {
          components: [
            { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
            { internalType: 'uint256', name: 'assetInIndex', type: 'uint256' },
            { internalType: 'uint256', name: 'assetOutIndex', type: 'uint256' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'bytes', name: 'userData', type: 'bytes' }
          ],
          internalType: 'structIVault.BatchSwapStep[]',
          name: 'swaps',
          type: 'tuple[]'
        },
        { internalType: 'contractIAsset[]', name: 'assets', type: 'address[]' },
        {
          components: [
            { internalType: 'address', name: 'sender', type: 'address' },
            { internalType: 'bool', name: 'fromInternalBalance', type: 'bool' },
            {
              internalType: 'addresspayable',
              name: 'recipient',
              type: 'address'
            },
            { internalType: 'bool', name: 'toInternalBalance', type: 'bool' }
          ],
          internalType: 'structIVault.FundManagement',
          name: 'funds',
          type: 'tuple'
        },
        { internalType: 'int256[]', name: 'limits', type: 'int256[]' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' }
      ],
      name: 'batchSwap',
      outputs: [
        { internalType: 'int256[]', name: 'assetDeltas', type: 'int256[]' }
      ],
      stateMutability: 'payable',
      type: 'function',
      payable: true
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'contractIERC20[]', name: 'tokens', type: 'address[]' }
      ],
      name: 'deregisterTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'addresspayable', name: 'recipient', type: 'address' },
        {
          components: [
            {
              internalType: 'contractIAsset[]',
              name: 'assets',
              type: 'address[]'
            },
            {
              internalType: 'uint256[]',
              name: 'minAmountsOut',
              type: 'uint256[]'
            },
            { internalType: 'bytes', name: 'userData', type: 'bytes' },
            { internalType: 'bool', name: 'toInternalBalance', type: 'bool' }
          ],
          internalType: 'structIVault.ExitPoolRequest',
          name: 'request',
          type: 'tuple'
        }
      ],
      name: 'exitPool',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'contractIFlashLoanRecipient',
          name: 'recipient',
          type: 'address'
        },
        { internalType: 'contractIERC20[]', name: 'tokens', type: 'address[]' },
        { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'flashLoan',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'selector', type: 'bytes4' }],
      name: 'getActionId',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getAuthorizer',
      outputs: [
        { internalType: 'contractIAuthorizer', name: '', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getDomainSeparator',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'contractIERC20[]', name: 'tokens', type: 'address[]' }
      ],
      name: 'getInternalBalance',
      outputs: [
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
      name: 'getNextNonce',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getPausedState',
      outputs: [
        { internalType: 'bool', name: 'paused', type: 'bool' },
        {
          internalType: 'uint256',
          name: 'pauseWindowEndTime',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'bufferPeriodEndTime',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [{ internalType: 'bytes32', name: 'poolId', type: 'bytes32' }],
      name: 'getPool',
      outputs: [
        { internalType: 'address', name: '', type: 'address' },
        {
          internalType: 'enumIVault.PoolSpecialization',
          name: '',
          type: 'uint8'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'contractIERC20', name: 'token', type: 'address' }
      ],
      name: 'getPoolTokenInfo',
      outputs: [
        { internalType: 'uint256', name: 'cash', type: 'uint256' },
        { internalType: 'uint256', name: 'managed', type: 'uint256' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        { internalType: 'address', name: 'assetManager', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [{ internalType: 'bytes32', name: 'poolId', type: 'bytes32' }],
      name: 'getPoolTokens',
      outputs: [
        { internalType: 'contractIERC20[]', name: 'tokens', type: 'address[]' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getProtocolFeesCollector',
      outputs: [
        {
          internalType: 'contractProtocolFeesCollector',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'address', name: 'relayer', type: 'address' }
      ],
      name: 'hasApprovedRelayer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        {
          components: [
            {
              internalType: 'contractIAsset[]',
              name: 'assets',
              type: 'address[]'
            },
            {
              internalType: 'uint256[]',
              name: 'maxAmountsIn',
              type: 'uint256[]'
            },
            { internalType: 'bytes', name: 'userData', type: 'bytes' },
            { internalType: 'bool', name: 'fromInternalBalance', type: 'bool' }
          ],
          internalType: 'structIVault.JoinPoolRequest',
          name: 'request',
          type: 'tuple'
        }
      ],
      name: 'joinPool',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
      payable: true
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'enumIVault.PoolBalanceOpKind',
              name: 'kind',
              type: 'uint8'
            },
            { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
            { internalType: 'contractIERC20', name: 'token', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' }
          ],
          internalType: 'structIVault.PoolBalanceOp[]',
          name: 'ops',
          type: 'tuple[]'
        }
      ],
      name: 'managePoolBalance',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'enumIVault.UserBalanceOpKind',
              name: 'kind',
              type: 'uint8'
            },
            { internalType: 'contractIAsset', name: 'asset', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'address', name: 'sender', type: 'address' },
            {
              internalType: 'addresspayable',
              name: 'recipient',
              type: 'address'
            }
          ],
          internalType: 'structIVault.UserBalanceOp[]',
          name: 'ops',
          type: 'tuple[]'
        }
      ],
      name: 'manageUserBalance',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
      payable: true
    },
    {
      inputs: [
        { internalType: 'enumIVault.SwapKind', name: 'kind', type: 'uint8' },
        {
          components: [
            { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
            { internalType: 'uint256', name: 'assetInIndex', type: 'uint256' },
            { internalType: 'uint256', name: 'assetOutIndex', type: 'uint256' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'bytes', name: 'userData', type: 'bytes' }
          ],
          internalType: 'structIVault.BatchSwapStep[]',
          name: 'swaps',
          type: 'tuple[]'
        },
        { internalType: 'contractIAsset[]', name: 'assets', type: 'address[]' },
        {
          components: [
            { internalType: 'address', name: 'sender', type: 'address' },
            { internalType: 'bool', name: 'fromInternalBalance', type: 'bool' },
            {
              internalType: 'addresspayable',
              name: 'recipient',
              type: 'address'
            },
            { internalType: 'bool', name: 'toInternalBalance', type: 'bool' }
          ],
          internalType: 'structIVault.FundManagement',
          name: 'funds',
          type: 'tuple'
        }
      ],
      name: 'queryBatchSwap',
      outputs: [{ internalType: 'int256[]', name: '', type: 'int256[]' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'enumIVault.PoolSpecialization',
          name: 'specialization',
          type: 'uint8'
        }
      ],
      name: 'registerPool',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'contractIERC20[]', name: 'tokens', type: 'address[]' },
        { internalType: 'address[]', name: 'assetManagers', type: 'address[]' }
      ],
      name: 'registerTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'contractIAuthorizer',
          name: 'newAuthorizer',
          type: 'address'
        }
      ],
      name: 'setAuthorizer',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bool', name: 'paused', type: 'bool' }],
      name: 'setPaused',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'relayer', type: 'address' },
        { internalType: 'bool', name: 'approved', type: 'bool' }
      ],
      name: 'setRelayerApproval',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
            {
              internalType: 'enumIVault.SwapKind',
              name: 'kind',
              type: 'uint8'
            },
            {
              internalType: 'contractIAsset',
              name: 'assetIn',
              type: 'address'
            },
            {
              internalType: 'contractIAsset',
              name: 'assetOut',
              type: 'address'
            },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'bytes', name: 'userData', type: 'bytes' }
          ],
          internalType: 'structIVault.SingleSwap',
          name: 'singleSwap',
          type: 'tuple'
        },
        {
          components: [
            { internalType: 'address', name: 'sender', type: 'address' },
            { internalType: 'bool', name: 'fromInternalBalance', type: 'bool' },
            {
              internalType: 'addresspayable',
              name: 'recipient',
              type: 'address'
            },
            { internalType: 'bool', name: 'toInternalBalance', type: 'bool' }
          ],
          internalType: 'structIVault.FundManagement',
          name: 'funds',
          type: 'tuple'
        },
        { internalType: 'uint256', name: 'limit', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' }
      ],
      name: 'swap',
      outputs: [
        { internalType: 'uint256', name: 'amountCalculated', type: 'uint256' }
      ],
      stateMutability: 'payable',
      type: 'function',
      payable: true
    },
    { stateMutability: 'payable', type: 'receive', payable: true }
  ],
  weightedPool: [
    {
      inputs: [
        { internalType: 'contractIVault', name: 'vault', type: 'address' },
        { internalType: 'string', name: 'name', type: 'string' },
        { internalType: 'string', name: 'symbol', type: 'string' },
        { internalType: 'contractIERC20[]', name: 'tokens', type: 'address[]' },
        {
          internalType: 'uint256[]',
          name: 'normalizedWeights',
          type: 'uint256[]'
        },
        { internalType: 'uint256', name: 'swapFeePercentage', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'pauseWindowDuration',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'bufferPeriodDuration',
          type: 'uint256'
        },
        { internalType: 'address', name: 'owner', type: 'address' }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Approval',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'bool', name: 'paused', type: 'bool' }
      ],
      name: 'PausedStateChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'swapFeePercentage',
          type: 'uint256'
        }
      ],
      name: 'SwapFeePercentageChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'contractIERC20',
          name: 'token',
          type: 'address'
        },
        {
          components: [
            {
              internalType: 'uint64',
              name: 'targetPercentage',
              type: 'uint64'
            },
            {
              internalType: 'uint64',
              name: 'criticalPercentage',
              type: 'uint64'
            },
            { internalType: 'uint64', name: 'feePercentage', type: 'uint64' }
          ],
          indexed: false,
          internalType: 'structIAssetManager.PoolConfig',
          name: 'target',
          type: 'tuple'
        }
      ],
      name: 'TargetManagerPoolConfigChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Transfer',
      type: 'event'
    },
    {
      inputs: [],
      name: 'DOMAIN_SEPARATOR',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' }
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'pure',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'decreaseApproval',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'selector', type: 'bytes4' }],
      name: 'getActionId',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getAuthorizer',
      outputs: [
        { internalType: 'contractIAuthorizer', name: '', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getInvariant',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getLastInvariant',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getNormalizedWeights',
      outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getOwner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getPausedState',
      outputs: [
        { internalType: 'bool', name: 'paused', type: 'bool' },
        {
          internalType: 'uint256',
          name: 'pauseWindowEndTime',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'bufferPeriodEndTime',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getPoolId',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getRate',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getSwapFeePercentage',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getVault',
      outputs: [{ internalType: 'contractIVault', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'increaseApproval',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
      name: 'nonces',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'protocolSwapFeePercentage',
          type: 'uint256'
        },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'onExitPool',
      outputs: [
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'protocolSwapFeePercentage',
          type: 'uint256'
        },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'onJoinPool',
      outputs: [
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'enumIVault.SwapKind',
              name: 'kind',
              type: 'uint8'
            },
            {
              internalType: 'contractIERC20',
              name: 'tokenIn',
              type: 'address'
            },
            {
              internalType: 'contractIERC20',
              name: 'tokenOut',
              type: 'address'
            },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
            {
              internalType: 'uint256',
              name: 'lastChangeBlock',
              type: 'uint256'
            },
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'bytes', name: 'userData', type: 'bytes' }
          ],
          internalType: 'structIPoolSwapStructs.SwapRequest',
          name: 'request',
          type: 'tuple'
        },
        { internalType: 'uint256', name: 'balanceTokenIn', type: 'uint256' },
        { internalType: 'uint256', name: 'balanceTokenOut', type: 'uint256' }
      ],
      name: 'onSwap',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' }
      ],
      name: 'permit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'protocolSwapFeePercentage',
          type: 'uint256'
        },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'queryExit',
      outputs: [
        { internalType: 'uint256', name: 'bptIn', type: 'uint256' },
        { internalType: 'uint256[]', name: 'amountsOut', type: 'uint256[]' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'protocolSwapFeePercentage',
          type: 'uint256'
        },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'queryJoin',
      outputs: [
        { internalType: 'uint256', name: 'bptOut', type: 'uint256' },
        { internalType: 'uint256[]', name: 'amountsIn', type: 'uint256[]' }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'contractIERC20', name: 'token', type: 'address' },
        {
          components: [
            {
              internalType: 'uint64',
              name: 'targetPercentage',
              type: 'uint64'
            },
            {
              internalType: 'uint64',
              name: 'criticalPercentage',
              type: 'uint64'
            },
            { internalType: 'uint64', name: 'feePercentage', type: 'uint64' }
          ],
          internalType: 'structIAssetManager.PoolConfig',
          name: 'poolConfig',
          type: 'tuple'
        }
      ],
      name: 'setAssetManagerPoolConfig',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bool', name: 'paused', type: 'bool' }],
      name: 'setPaused',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'swapFeePercentage', type: 'uint256' }
      ],
      name: 'setSwapFeePercentage',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ],
  stablePool: [
    {
      inputs: [
        { internalType: 'contractIVault', name: 'vault', type: 'address' },
        { internalType: 'string', name: 'name', type: 'string' },
        { internalType: 'string', name: 'symbol', type: 'string' },
        { internalType: 'contractIERC20[]', name: 'tokens', type: 'address[]' },
        {
          internalType: 'uint256',
          name: 'amplificationParameter',
          type: 'uint256'
        },
        { internalType: 'uint256', name: 'swapFeePercentage', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'pauseWindowDuration',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'bufferPeriodDuration',
          type: 'uint256'
        },
        { internalType: 'address', name: 'owner', type: 'address' }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'startValue',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'endValue',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'startTime',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'endTime',
          type: 'uint256'
        }
      ],
      name: 'AmpUpdateStarted',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'currentValue',
          type: 'uint256'
        }
      ],
      name: 'AmpUpdateStopped',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Approval',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'bool', name: 'paused', type: 'bool' }
      ],
      name: 'PausedStateChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'swapFeePercentage',
          type: 'uint256'
        }
      ],
      name: 'SwapFeePercentageChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Transfer',
      type: 'event'
    },
    {
      inputs: [],
      name: 'DOMAIN_SEPARATOR',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' }
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'selector', type: 'bytes4' }],
      name: 'getActionId',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getAmplificationParameter',
      outputs: [
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'bool', name: 'isUpdating', type: 'bool' },
        { internalType: 'uint256', name: 'precision', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getAuthorizer',
      outputs: [
        { internalType: 'contractIAuthorizer', name: '', type: 'address' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getOwner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getPausedState',
      outputs: [
        { internalType: 'bool', name: 'paused', type: 'bool' },
        {
          internalType: 'uint256',
          name: 'pauseWindowEndTime',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'bufferPeriodEndTime',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getPoolId',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getRate',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getSwapFeePercentage',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getVault',
      outputs: [{ internalType: 'contractIVault', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
      name: 'nonces',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'protocolSwapFeePercentage',
          type: 'uint256'
        },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'onExitPool',
      outputs: [
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'protocolSwapFeePercentage',
          type: 'uint256'
        },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'onJoinPool',
      outputs: [
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'enumIVault.SwapKind',
              name: 'kind',
              type: 'uint8'
            },
            {
              internalType: 'contractIERC20',
              name: 'tokenIn',
              type: 'address'
            },
            {
              internalType: 'contractIERC20',
              name: 'tokenOut',
              type: 'address'
            },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
            {
              internalType: 'uint256',
              name: 'lastChangeBlock',
              type: 'uint256'
            },
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'bytes', name: 'userData', type: 'bytes' }
          ],
          internalType: 'structIPoolSwapStructs.SwapRequest',
          name: 'swapRequest',
          type: 'tuple'
        },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'indexIn', type: 'uint256' },
        { internalType: 'uint256', name: 'indexOut', type: 'uint256' }
      ],
      name: 'onSwap',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'enumIVault.SwapKind',
              name: 'kind',
              type: 'uint8'
            },
            {
              internalType: 'contractIERC20',
              name: 'tokenIn',
              type: 'address'
            },
            {
              internalType: 'contractIERC20',
              name: 'tokenOut',
              type: 'address'
            },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
            {
              internalType: 'uint256',
              name: 'lastChangeBlock',
              type: 'uint256'
            },
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'bytes', name: 'userData', type: 'bytes' }
          ],
          internalType: 'structIPoolSwapStructs.SwapRequest',
          name: 'request',
          type: 'tuple'
        },
        { internalType: 'uint256', name: 'balanceTokenIn', type: 'uint256' },
        { internalType: 'uint256', name: 'balanceTokenOut', type: 'uint256' }
      ],
      name: 'onSwap',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' }
      ],
      name: 'permit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'protocolSwapFeePercentage',
          type: 'uint256'
        },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'queryExit',
      outputs: [
        { internalType: 'uint256', name: 'bptIn', type: 'uint256' },
        { internalType: 'uint256[]', name: 'amountsOut', type: 'uint256[]' }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
        { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
        {
          internalType: 'uint256',
          name: 'protocolSwapFeePercentage',
          type: 'uint256'
        },
        { internalType: 'bytes', name: 'userData', type: 'bytes' }
      ],
      name: 'queryJoin',
      outputs: [
        { internalType: 'uint256', name: 'bptOut', type: 'uint256' },
        { internalType: 'uint256[]', name: 'amountsIn', type: 'uint256[]' }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'contractIERC20', name: 'token', type: 'address' },
        { internalType: 'bytes', name: 'poolConfig', type: 'bytes' }
      ],
      name: 'setAssetManagerPoolConfig',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bool', name: 'paused', type: 'bool' }],
      name: 'setPaused',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'swapFeePercentage', type: 'uint256' }
      ],
      name: 'setSwapFeePercentage',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'rawEndValue', type: 'uint256' },
        { internalType: 'uint256', name: 'endTime', type: 'uint256' }
      ],
      name: 'startAmplificationParameterUpdate',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'stopAmplificationParameterUpdate',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ]
};

export { Config };
