import { computed } from 'vue';
import useWeb3 from './web3/useWeb3';
import addresses from '@/constants/addresses';
import abis from '@/constants/abis';

export default function useMultiCall() {
  const { connector } = useWeb3();

  // COMPUTED
  const multiCallContract = computed(() =>
    connector.value.web3
      ? new connector.value.web3.eth.Contract(
          abis.multicall,
          addresses.multiCall
        )
      : null
  );

  // METHODS
  const multiCall = async calls => {
    if (!calls.length) {
      return;
    }
    const callsO = [];
    calls.forEach(call => {
      const method = call.method();
      if (method) {
        call.outputs =
          method._method.outputs.length > 1
            ? method._method.outputs
            : method._method.outputs[0];
        // @ts-ignore
        callsO.push([call.target, method.encodeABI()]);
      }
    });

    const o = await multiCallContract.value?.methods
      .aggregate(callsO)
      .call()
      .then(i => {
        return i[1].map((j, index) => {
          const outputs = calls[index].outputs;
          if (Array.isArray(outputs)) {
            j = connector.value.web3.eth.abi.decodeParameters(outputs, j);
          } else {
            j = connector.value.web3.eth.abi.decodeParameter(outputs, j);
          }
          return calls[index].cb(j, i[0]);
        });
      });

    return o;
  };

  return {
    // computed
    multiCallContract,
    // methods
    multiCall
  };
}
