<template>
  <div class="mynfts-bg">
    <div class="mynfts-con">
      <div class="mynfts-title">
        {{ $t('my_nfts').toUpperCase() }}
      </div>
      <div class="mynfts-list-header">
        <div @click="sort('name')">
          {{ $t('my_nfts').toUpperCase() }}
        </div>
        <div v-if="!upToLargeBreakpoint" @click="sort('id')">
          {{ $t('nos').toUpperCase() }}
        </div>
        <div @click="sort('stakedWvp')">
          {{ $t('price').toUpperCase() }}
        </div>
        <!--div @click="sort('stakedWvp')">
          {{ $t('staked').toUpperCase() }}
        </div-->
        <div @click="sort('stakedTgw')">
          {{ $t('staked').toUpperCase() }}
        </div>
        <div>
          {{ $t('type') }}
        </div>
      </div>
      <div class="mynfts-list">
        <div
          v-for="(item, index) in showedMyNfts"
          :key="index"
          class="mynfts-list-item"
          @click="gotoNftViewer(item.id)"
        >
          <div>
            {{ item.name || '--' }}
          </div>
          <div v-if="!upToLargeBreakpoint">#{{ item.id }}</div>
          <div>
            {{ item.stakedWvpUsd ? `$${showApr(item.stakedWvpUsd)}` : '--' }}
          </div>
          <!--div>
            {{ item.stakedWvp ? `${showApr(item.stakedWvp)} WVP` : '--' }}
          </div-->
          <div>
            {{ item.stakedTgw ? `${showApr(item.stakedTgw)} TGW` : '--' }}
          </div>
          <div>{{ item.tokenType ? NFT_TYPE[item.tokenType] : '--' }}</div>
        </div>
      </div>
      <div class="mynfts-stats">
        <StatsCard>
          <template v-slot:title>
            {{ $t('tgw_nft_stats') }}
          </template>
          <template v-slot:detail>
            <p>
              {{ $t('the_great_wave_season1') }}
            </p>
            <p>
              {{ $t('total_nft_minted').toUpperCase() }}:
              {{ totalSupply || '--' }}
            </p>
            <p>
              {{ $t('total_tgw_staked').toUpperCase() }}:
              {{ totalStaked ? `${showApr(totalStaked)} TGW` : '--' }}
            </p>
            <p>{{ $t('current_apy').toUpperCase() }}: {{ currentApy }}</p>
          </template>
        </StatsCard>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import StatsCard from './StatsCard.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useWeb3 from '@/services/web3/useWeb3';
import useNfts from '@/services/useNfts';
import useVaults from '@/services/useVaults';
import { sortArray } from '@/lib/utils/index';
import { showApr } from '@/lib/utils/web3';
import { NFT_TYPE } from '@/constants';

export default defineComponent({
  components: {
    StatsCard
  },

  setup() {
    const router = useRouter();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { account } = useWeb3();
    const { allNfts, totalSupply } = useNfts();
    const { formatedFarmVaults } = useVaults();

    // DATA
    const sortBy = ref('rank');
    const sortOrder = ref(false);

    // COMPUTED
    const myNfts = computed(() =>
      //sortArray(allNfts.value, 'stakedWvp', false).filter(
      //  item => item.owner?.toLowerCase() === account.value?.toLowerCase()
      //)
      sortArray(allNfts.value, 'stakedTgw', false).filter(
        item => item.owner?.toLowerCase() === account.value?.toLowerCase()
      )
    );

    const showedMyNfts = computed(() =>
      sortArray(myNfts.value, sortBy.value, sortOrder.value)
    );

    const totalStaked = computed(() =>
      /*allNfts.value.length
        ? allNfts.value.reduce(
            (total, item) => (item.stakedWvp || 0) + total,
            0
          )
        : 0
      */
      allNfts.value.length
        ? allNfts.value.reduce(
            (total, item) => (item.stakedTgw || 0) + total,
            0
          )
        : 0
    );

    const currentApy = computed(
      () =>
        formatedFarmVaults.value.find(item => item.name === 'TGW Vault')
          ?.apyShown || '--'
    );

    // METHODS
    const sort = key => {
      sortBy.value = key;
      sortOrder.value = !sortOrder.value;
    };
    const gotoNftViewer = nftId => {
      router.push({ path: `/nft-viewer/${nftId}` });
    };

    return {
      NFT_TYPE,
      showApr,
      totalSupply,
      upToLargeBreakpoint,
      // computed
      currentApy,
      totalStaked,
      showedMyNfts,
      // methods
      gotoNftViewer,
      sort
    };
  }
});
</script>

<style scoped>
.mynfts-bg {
  @apply w-full bg-transparent overflow-auto;
  background-image: url('~@/assets/images/nft-main-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.mynfts-con {
  @apply mx-auto py-32 px-5;
  max-width: 1136px;
}
.mynfts-title {
  @apply text-2xl lg:text-4xl;
  @apply text-white font-bold text-center;
  font-family: 'Exo 2';
}
.mynfts-stats {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-5 mt-6;
}
.mynfts-list-header {
  @apply bg-sky-420 grid gap-2 text-white font-bold pt-4 pb-2 px-8 text-sssm lg:text-md items-baseline mt-12;
  @apply grid-cols-5 lg:grid-cols-6;
  border-radius: 30px 30px 0px 0px;
}
.mynfts-list-header > * {
  @apply cursor-pointer;
}
.mynfts-list-header div:first-child {
  @apply text-lg lg:text-2xl col-span-2;
  font-family: 'Exo 2';
}
.mynfts-list-header div:not(:first-child) {
  font-family: 'Fira Sans';
}
.mynfts-list {
  @apply text-white box-border border-2 border-sky-420 bg-black bg-opacity-60 pt-4 pb-6 px-8;
  border-radius: 0px 0px 30px 30px;
  font-family: 'Fira Sans';
  min-height: 200px;
}
.mynfts-list-item {
  @apply grid grid-cols-5 lg:grid-cols-6 gap-5 py-4 text-sssm lg:text-base cursor-pointer items-center;
  border-bottom: 1px dashed #cde6e9;
}
.mynfts-list-item div:first-child {
  @apply col-span-2;
}
.mynfts-list::-webkit-scrollbar {
  @apply h-3 w-3 rounded-2xl;
  background: rgba(180, 179, 183, 0.2);
}
.mynfts-list::-webkit-scrollbar-thumb {
  @apply bg-gray-450 rounded-2xl border-solid;
  border-width: 3px;
  border-color: rgba(180, 179, 183, 0.2);
}
.mynfts-list-no-nft {
  @apply py-16 text-center;
}
</style>
