
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useNfts from '@/services/useNfts';
import useVaults from '@/services/useVaults';
import { showTokenBalance } from '@/lib/vault/farmVaultUtils';
import { fw, bn } from '@/lib/utils/bn';
import { NFT_DEFAULT_TYPE } from '@/constants';
import StakeMintRedirectModal from '@/components/modals/StakeMintRedirectModal.vue';

export default defineComponent({
  components: { StakeMintRedirectModal },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      stakeMint,
      minBnbAmount,
      userBnbBalance,
      nftTypesData,
      typeMintFeesDesc
    } = useNfts();
    const { acsStats } = useVaults();

    // COMPUTED
    // const balance = computed(() => {
    //   const acsVaultFarm = formatedFarmVaults.value.find(
    //     item => item.name === 'ACS'
    //   );
    //   if (acsVaultFarm.walletEmpty) {
    //     return null;
    //   } else {
    //     return acsVaultFarm.vault.stats.userBalanceNormalized;
    //   }
    // });
    const minMintFeeUsd = computed(() =>
      // @ts-ignore
      acsStats.value?.bnbPriceUsd
        ? // @ts-ignore
          Number(acsStats.value.bnbPriceUsd) * Number(fw(minBnbAmount.value))
        : 0
    );
    const nftType = computed(() => route.query.type || NFT_DEFAULT_TYPE);
    const nftTypeData = computed(
      () => nftTypesData.value[Number(nftType.value)] || {}
    );

    // DATA
    const showModal = ref(false);
    const stakeMinting = ref(false);
    const stakeMintAmount = ref('0.1');
    const link = ref('');
    const image = ref('');

    // Watchers
    watch(minBnbAmount, (newVal, oldVal) => {
      if (
        Number(fw(oldVal)) !== Number(fw(newVal)) &&
        (fw(oldVal) === '0' ||
          Number(fw(oldVal)) === Number(stakeMintAmount.value) ||
          Number(fw(newVal)) > Number(stakeMintAmount.value))
      ) {
        stakeMintAmount.value = fw(newVal);
      }
    });
    watch(nftTypeData, newVal => {
      if (!newVal.id) {
        router.push({
          path: '/buy-nft',
          query: { type: NFT_DEFAULT_TYPE }
        });
      }
    });

    // METHODS
    const stakeMintAction = async () => {
      stakeMinting.value = true;
      const res: any = await stakeMint(stakeMintAmount.value, nftType.value);
      stakeMinting.value = false;

      //TODO remove hardcode replace
      if (res && nftTypeData.value._post_mint_redirect) {
        link.value = nftTypeData.value._post_mint_redirect.replace(
          '{{tx}}',
          res.transactionHash
        );
        image.value = res.image;
        showModal.value = true;
      }
    };
    const goTo = des => {
      router.push({ path: `/${des}` });
    };
    const setMax = () => {
      if (userBnbBalance.value) {
        stakeMintAmount.value = fw(userBnbBalance.value);
      }
    };

    return {
      bn,
      fw,
      minBnbAmount,
      typeMintFeesDesc,
      userBnbBalance,
      showTokenBalance,
      // computed
      nftTypeData,
      // balanceShown,
      minMintFeeUsd,
      // data
      link,
      image,
      showModal,
      stakeMinting,
      stakeMintAmount,
      // methods
      goTo,
      setMax,
      stakeMintAction
    };
  }
});
