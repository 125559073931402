<template>
  <BalPopover no-pad>
    <template v-slot:activator>
      <BalBtn
        color="sky-dark"
        :size="upToLargeBreakpoint ? 'xs' : 'sm'"
        rounded
      >
        <Avatar :address="account" :size="upToLargeBreakpoint ? '16' : 'sm'" />
        <span class="pl-2 eth-address">
          {{ _shorten(account) }}
        </span>
      </BalBtn>
    </template>
    <AppNavSettings />
  </BalPopover>
</template>

<script>
import { defineComponent } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';
import AppNavSettings from './AppNavSettings.vue';
import Avatar from '@/components/images/Avatar.vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  name: 'AppNavAccountBtn',

  components: {
    AppNavSettings,
    Avatar
  },

  setup() {
    const { connector, account, connectStatus } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      upToLargeBreakpoint,
      connector,
      connectStatus,
      account
    };
  }
});
</script>
