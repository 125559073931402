import vaultWeight from './vaultWeight.json';
import vault from './vault.json';
import nft from './nft.json';
import erc20 from './erc20.json';
import multicall from './multicall.json';
import uniswapV2Pair from './uniswapV2Pair.json';
import strategy from './strategy.json';
import bVault from './bVault.json';
import weightedPool from './weightedPool.json';
import stablePool from './stablePool.json';
import vyper from './vyper.json';
import vaultV2 from './vaultV2.json';
import vaultLiquid from './vaultLiquid.json';
import vaultBestDayReturn from './vaultBestDayReturn.json';

export default {
  vaultWeight,
  vault,
  vaultV2,
  vaultLiquid,
  vaultBestDayReturn,
  nft,
  erc20,
  multicall,
  uniswapV2Pair,
  strategy,
  bVault,
  weightedPool,
  stablePool,
  vyper
};
