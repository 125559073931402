<template>
  <div class="reward-container">
    <div class="main-label">
      {{ $t('rewards') }}
      <BalIcon
        @click="showRewardsDetail = !showRewardsDetail"
        class="cursor-pointer ml-auto text-sky-420"
        :name="
          showRewardsDetail
            ? 'ant-design:minus-circle-outlined'
            : 'ant-design:plus-circle-outlined'
        "
        size="sm"
      />
    </div>
    <div class="divider"></div>
    <div class="flex my-2">
      <div class="rewards-label"></div>
      <div class="rewards-min-max-title-container">
        <div class="rewards-min underline">{{ $t('min') }}</div>
        <div class="rewards-dash">-</div>
        <div class="rewards-max underline">{{ $t('max') }}</div>
      </div>
      <div class="rewards-current-title">
        {{ $t('current') }}
      </div>
    </div>
    <div v-if="!showRewardsDetail">
      <div class="flex my-1" v-for="(item, index) in dataTotal" :key="index">
        <div class="rewards-label">{{ $t(item.label) }}:</div>
        <div class="rewards-min-max-value-container">
          <div class="rewards-min">
            {{ farmVault[item.min] ? showApr(farmVault[item.min]) : '--' }}
          </div>
          <div class="rewards-dash">-</div>
          <div class="rewards-max">
            {{
              farmVault[item.max] ? `${showApr(farmVault[item.max])}%` : '--'
            }}
          </div>
        </div>
        <div class="rewards-current-value">
          {{
            farmVault[item.value] ? showApr(farmVault[item.value]) + '%' : '--'
          }}
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="farmVault.swapApy" class="mb-2">
        <div class="flex" v-for="(item, index) in dataSwap" :key="index">
          <div class="rewards-label">{{ $t(item.label) }}:</div>
          <div class="rewards-min-max-value-container">
            <div class="rewards-min"></div>
            <div class="rewards-dash">-</div>
            <div class="rewards-max"></div>
          </div>
          <div class="rewards-current-value">
            {{ `${showApr(farmVault[item.value])}%` }}
          </div>
        </div>
      </div>
      <div v-if="farmVault.vaultApy" class="mb-2">
        <div class="flex" v-for="(item, index) in dataVault" :key="index">
          <div class="rewards-label">{{ $t(item.label) }}:</div>
          <div class="rewards-min-max-value-container">
            <div class="rewards-min"></div>
            <div class="rewards-dash">-</div>
            <div class="rewards-max"></div>
          </div>
          <div class="rewards-current-value">
            {{ `${showApr(farmVault[item.value])}%` }}
          </div>
        </div>
      </div>
      <div v-if="farmVault.farmApy" class="mb-2">
        <div class="flex" v-for="(item, index) in dataFarm" :key="index">
          <div class="rewards-label">{{ $t(item.label) }}:</div>
          <div class="rewards-min-max-value-container">
            <div class="rewards-min">
              {{ farmVault[item.min] ? showApr(farmVault[item.min]) : '--' }}
            </div>
            <div class="rewards-dash">-</div>
            <div class="rewards-max">
              {{
                farmVault[item.max] ? `${showApr(farmVault[item.max])}%` : '--'
              }}
            </div>
          </div>
          <div class="rewards-current-value">
            {{
              farmVault[item.boosted]
                ? `${showApr(farmVault[item.boosted])}%`
                : farmVault[item.value]
                ? `${showApr(farmVault[item.value])}%`
                : '--'
            }}
          </div>
        </div>
      </div>
      <div class="flex" v-if="farmVault.farm && farmVault.farm.allocPoint">
        <div class="rewards-label">{{ $t('acs_weight') }}:</div>
        <div class="rewards-min-max-value-container">
          <div class="rewards-min">{{ farmVault.farm.allocPoint / 100 }}X</div>
          <div class="rewards-dash"></div>
          <div class="rewards-max"></div>
        </div>
        <div class="rewards-current-value"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { showApr } from '@/lib/vault/farmVaultUtils';
import { fwp } from '@/lib/utils/bn';

export default defineComponent({
  components: {},

  props: {
    farmVault: { type: Object }
  },

  setup() {
    // DATA
    const showRewardsDetail = ref(false);
    const dataTotal = ref([
      {
        label: 'apy',
        min: 'apyTotalMin',
        max: 'apyTotalMax',
        value: 'apyTotal'
      },
      {
        label: 'apr',
        min: 'aprTotalMin',
        max: 'aprTotalMax',
        value: 'aprTotal'
      },
      {
        label: 'daily',
        min: 'dailyTotalMin',
        max: 'dailyTotalMax',
        value: 'dailyTotal'
      }
    ]);
    const dataSwap = ref([
      {
        label: 'swap_apy',
        value: 'swapApy'
      },
      {
        label: 'swap_apr',
        value: 'swapApr'
      },
      {
        label: 'swap_daily',
        value: 'swapDaily'
      }
    ]);
    const dataVault = ref([
      {
        label: 'vault_apy',
        value: 'vaultApy'
      },
      {
        label: 'vault_apr',
        value: 'vaultApr'
      },
      {
        label: 'vault_daily',
        value: 'vaultDaily'
      }
    ]);
    const dataFarm = ref([
      {
        label: 'farm_apy',
        min: 'farmApyMin',
        max: 'farmApyMax',
        boosted: 'farmApyBoosted',
        value: 'farmApy'
      },
      {
        label: 'farm_apr',
        min: 'farmAprMin',
        max: 'farmAprMax',
        boosted: 'farmAprBoosted',
        value: 'farmApr'
      },
      {
        label: 'farm_daily',
        min: 'farmDailyMin',
        max: 'farmDailyMax',
        boosted: 'farmDailyBoosted',
        value: 'farmDaily'
      }
    ]);

    return {
      fwp,
      showApr,
      // data
      showRewardsDetail,
      dataTotal,
      dataSwap,
      dataVault,
      dataFarm
    };
  }
});
</script>
<style scoped>
.reward-container {
  @apply col-span-2 3xl:col-span-1;
  max-width: 300px;
}
.rewards-label {
  @apply text-gray-450 text-sssm lg:text-sm font-medium leading-4 lg:leading-6;
  width: 85px;
}
.rewards-min-max-value-container {
  @apply flex text-sssm lg:text-base font-bold leading-4 lg:leading-6;
  width: calc(100% - 145px);
}
.rewards-min {
  @apply text-right;
  width: calc(50% - 16px);
}
.rewards-dash {
  @apply w-4 text-center;
}
.rewards-max {
  @apply text-left;
}
.rewards-min-max-title-container {
  @apply flex italic text-2xs lg:text-xs font-light;
  width: calc(100% - 145px);
}
.rewards-current-title {
  @apply underline italic text-2xs lg:text-xs font-light;
}
.rewards-current-title {
  @apply text-right pr-1;
  width: 60px;
}
.rewards-current-value {
  @apply text-sssm lg:text-base font-bold leading-4 lg:leading-6 text-green-490 text-right;
  width: 60px;
}
</style>
