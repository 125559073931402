<template>
  <transition name="opacity-transition">
    <div
      v-if="upToLargeBreakpoint && selectedVaultId === -1"
      class="flex justify-end mb-4"
    >
      <AppNavActions />
    </div>
  </transition>
  <transition name="opacity-transition">
    <VaultsTopNav
      v-if="
        !upToLargeBreakpoint || (upToLargeBreakpoint && selectedVaultId === -1)
      "
    />
  </transition>
  <!-- <transition name="opacity-transition">
    <VaultsFilterWithSearch
      v-if="
        !upToLargeBreakpoint || (upToLargeBreakpoint && selectedVaultId === -1)
      "
      @search="search"
      :filterKey="filterKey"
      @filter="filter"
    />
  </transition> -->
  <transition name="opacity-transition">
    <div
      v-if="
        !upToLargeBreakpoint || (upToLargeBreakpoint && selectedVaultId === -1)
      "
      class="mt-4 lg:mt-8 mb-4 grid grid-cols-5 gap-8"
    >
      <div class="sort-container">
        <div
          v-for="(item, index) in sortMap"
          :key="index"
          :class="[
            `col-span-${item.col} relative`,
            { 'flex justify-center': item.label !== 'name' }
          ]"
        >
          <div
            class="flex items-center cursor-pointer"
            @click="sortUpdate(item.sortKey)"
          >
            <span class="sort-label">{{ $t(item.label) }}</span>
            <BalIcon
              v-if="!upToLargeBreakpoint"
              class="text-gray-390 dark:text-gray-450"
              name="carbon:chevron-sort"
              size="md"
            />
          </div>
          <img
            v-if="item.label === 'pending'"
            @click="sortUpdate('rewardPending')"
            class="plant-sort"
            src="/images/plants/plant4.png"
          />
        </div>
      </div>
    </div>
  </transition>
  <transition name="opacity-transition">
    <div
      class="grid grid-cols-5 gap-8"
      v-show="
        !upToLargeBreakpoint || (upToLargeBreakpoint && selectedVaultId === -1)
      "
    >
      <div id="vaults_list_container">
        <BalLoadingBlock class="h-full" v-if="isLoadingData" rounded="2xl" />
        <div v-else>
          <div v-if="deprecated" class="notice-container">
            <span
              >{{ $t('notice_deprecated') }}
              <router-link class="text-blue-600" to="vaults">{{
                $t('stake_in_current')
              }}</router-link
              >.</span
            >
          </div>
          <div
            v-for="(farmVault, i) in sortedFarmVaults"
            :key="i"
            class="vault-container"
            :class="[
              selectedVaultId === farmVault.id
                ? 'vault-selected'
                : 'vault-unselected',
              { 'mt-2': i !== 0 }
            ]"
            @click="setSelectedVaultId(farmVault.id)"
          >
            <!-- <div v-if="upToLargeBreakpoint" class="vault-item-mobile-tags">
              <Tags :farmVaultTags="farmVault.tags" />
              <span class="crown" v-if="farmVault.name === 'A2B2 (ACSI)'">
                <BalIcon name="jam:crown" size="2xs" />
              </span>
            </div> -->
            <div class="vault">
              <div class="col-span-2">
                <p class="vault-item-title">{{ farmVault.name }}</p>
                <p
                  class="vault-item-subtitle"
                  v-if="farmVault.name !== farmVault.tokenSymbol"
                >
                  {{ farmVault.tokenSymbol }}
                </p>
                <!-- <div
                  class="crown mr-2"
                  v-if="
                    !upToLargeBreakpoint && farmVault.name === 'A2B2 (ACSI)'
                  "
                >
                  <BalIcon name="jam:crown" size="xs" />
                </div> -->
              </div>
              <!-- <div
                v-if="!upToLargeBreakpoint"
                class="col-span-2 items-center flex"
              >
                <Tags :farmVaultTags="farmVault.tags" />
              </div> -->
              <div class="vault-item-value col-span-1">
                {{ farmVault.apyShown }}
              </div>
              <div class="vault-item-value col-span-1">
                {{ farmVault.dailyShown }}
              </div>
              <div class="vault-item-value col-span-1">
                {{ farmVault.tvlShown }}
              </div>
              <div class="vault-item-value col-span-1">
                {{ farmVault.balanceShown }}
              </div>
              <div class="vault-item-value col-span-2 relative">
                {{ farmVault.rewardPendingShown }}
                <img
                  v-if="plantLevel(farmVault)"
                  class="vault-plant"
                  :src="`/images/plants/plant${plantLevel(farmVault)}.png`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!upToLargeBreakpoint"
        id="vaults_detail_container"
        :class="{ 'height-fit': !isLoadingData }"
      >
        <VaultsDetail
          :farmVault="formatedFarmVaultsForPage[selectedVaultId]"
          :isLoadingData="isLoadingData"
        />
      </div>
    </div>
  </transition>
  <transition name="opacity-transition">
    <div
      v-if="upToLargeBreakpoint && selectedVaultId !== -1"
      class="height-fit mobile-vault-detail"
    >
      <VaultsDetail
        :farmVault="formatedFarmVaultsForPage[selectedVaultId]"
        :isLoadingData="isLoadingData"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import AppNavActions from '../navs/AppNav/AppNavActions.vue';
import VaultsTopNav from './top/index.vue';
// import VaultsFilterWithSearch from './VaultsFilterWithSearch.vue';
import VaultsDetail from './detail/index.vue';
// import Tags from './Tags.vue';
import useMenuCollapsed from '@/composables/useMenuCollapsed';
import useBreakpoints from '@/composables/useBreakpoints';
import useVaultsList from '@/composables/useVaultsList';
// import { filterList } from '@/constant';
import useVaults from '@/services/useVaults';
import { plantLevel } from '@/lib/vault/farmVaultUtils';

export default defineComponent({
  components: {
    AppNavActions,
    VaultsTopNav,
    // VaultsFilterWithSearch,
    VaultsDetail
    // Tags
  },

  props: {
    deprecated: { type: Boolean }
  },

  setup(props) {
    const {
      farmVaults,
      formatedFarmVaults,
      formatedFarmVaultsDeprecated
    } = useVaults();
    const { menuCollapsed } = useMenuCollapsed();
    const { width, upToLargeBreakpoint } = useBreakpoints();
    const { selectedVaultId, setSelectedVaultId } = useVaultsList();

    //COMPUTED
    const isLoadingData = computed(() => !farmVaults.value.length);

    const formatedFarmVaultsForPage = computed(() =>
      props.deprecated
        ? formatedFarmVaultsDeprecated.value
        : formatedFarmVaults.value
    );

    const searchedFarmVaults = computed(() => {
      return formatedFarmVaultsForPage.value.filter(
        item =>
          item.name.toLowerCase().indexOf(searchInput.value?.toLowerCase()) > -1
      );
    });

    const filteredFarmVaults = computed(() => {
      return searchedFarmVaults.value.filter(
        item =>
          filterKey.value === 'all' ||
          item.tags.includes(filterKey.value) ||
          (filterKey.value === 'swords' &&
            (!item.walletEmpty ||
              !item.vaultEmpty ||
              !item.walletFarmEmpty ||
              !item.farmEmpty ||
              !item.farmRewardsEmpty))
      );
    });

    const sortedFarmVaults = computed(() => {
      if (sortBy.value === '') {
        return filteredFarmVaults.value;
      } else {
        return [...filteredFarmVaults.value].sort(function(a, b) {
          const x = a[sortBy.value];
          const y = b[sortBy.value];
          const result = sortOrder.value
            ? x < y
              ? -1
              : x > y
              ? 1
              : 0
            : x < y
            ? 1
            : x > y
            ? -1
            : 0;
          return result;
        });
      }
    });

    // MOUNTED
    onMounted(() => {
      setVaultsViewHeight();
      setSelectedVaultId(upToLargeBreakpoint.value ? -1 : 0);
    });

    // WATCHERS
    watch(width, () => {
      setVaultsViewHeight();
    });
    watch(menuCollapsed, () => {
      setTimeout(() => {
        setVaultsViewHeight();
      }, 1000);
    });
    watch(upToLargeBreakpoint, () => {
      setSelectedVaultId(
        upToLargeBreakpoint.value
          ? -1
          : selectedVaultId.value > -1
          ? selectedVaultId.value
          : formatedFarmVaultsForPage.value[0].id
      );
    });

    // DATA
    const searchInput = ref('');
    const filterKey = ref('all');
    const sortBy = ref('');
    const sortOrder = ref(true);
    const sortMap = ref([
      { label: 'name', col: 2, sortKey: 'name' },
      { label: 'apy', col: 1, sortKey: 'apy' },
      { label: 'daily', col: 1, sortKey: 'daily' },
      { label: 'tvl', col: 1, sortKey: 'tvl' },
      { label: 'balance', col: 1, sortKey: 'balance' },
      { label: 'pending', col: 2, sortKey: 'rewardPending' }
    ]);

    // METHODS
    const search = input => {
      searchInput.value = input;
    };

    const filter = key => {
      filterKey.value = key;
    };

    const sortUpdate = key => {
      if (key === sortBy.value) {
        sortOrder.value = !sortOrder.value;
      } else {
        sortBy.value = key;
        sortOrder.value = true;
      }
    };

    const setVaultsViewHeight = () => {
      if (upToLargeBreakpoint.value) {
        document.getElementById(
          'vaults_list_container'
        )!.style.height = `calc(100vh - 325px)`;
        return;
      }
      const rect = document
        .getElementById('vaults_list_container')!
        .getBoundingClientRect();
      const y = rect.top;
      const height = `calc(100vh - ${y + 8}px)`;
      document.getElementById('vaults_list_container')!.style.height = height;
      document.getElementById(
        'vaults_detail_container'
      )!.style.maxHeight = height;
    };

    return {
      upToLargeBreakpoint,
      menuCollapsed,
      selectedVaultId,
      setSelectedVaultId,
      // data
      filterKey,
      searchInput,
      sortBy,
      sortOrder,
      sortMap,
      // computed
      isLoadingData,
      formatedFarmVaultsForPage,
      searchedFarmVaults,
      filteredFarmVaults,
      sortedFarmVaults,
      // methods
      plantLevel,
      setVaultsViewHeight,
      filter,
      search,
      sortUpdate
    };
  }
});
</script>

<style scoped>
@media (max-width: 1024px) {
  .vault-container {
    height: 88px;
  }
  .vault-selected {
    padding: 0 15px;
  }
  .crown img {
    @apply w-2;
  }
}
@media (min-width: 1024px) {
  .vault-container {
    height: 104px;
  }
  .vault-selected {
    padding: 0 23px;
  }
}
.sort-container {
  @apply col-span-5 lg:col-span-3;
  @apply grid grid-cols-8 px-4 lg:px-6 relative;
}
.sort-img {
  @apply h-2.5 lg:h-4 -ml-0.5;
}
.sort-label {
  @apply text-sssm lg:text-base font-bold lg:font-medium text-gray-390 dark:text-gray-450;
}
.venus {
  color: #5ed7ff;
  background-color: rgba(94, 215, 255, 0.2);
  font-size: 10px;
}
#vaults_list_container {
  @apply col-span-5 lg:col-span-3 lg:overflow-auto pr-0.5;
}
#vaults_detail_container {
  @apply overflow-auto pr-0.5 col-span-2;
}
.height-fit {
  height: fit-content;
}
.vault-container {
  @apply box-border rounded-2xl cursor-pointer bg-white dark:bg-gray-730 font-bold flex items-center justify-center relative;
}
.vault-selected {
  @apply border-2 border-sky-320 dark:border-yellow-550;
}
.vault-unselected {
  @apply border border-gray-130 dark:border-gray-730 px-4 lg:px-6;
}
.vault-plant {
  @apply w-2 lg:w-4 right-0 absolute;
}
.vault-item-mobile-tags {
  @apply flex justify-between items-center w-full;
}
.vault-selected .vault-item-mobile-tags {
  @apply pt-0.5;
}
.vault-unselected .vault-item-mobile-tags {
  @apply pt-1;
}
.vault {
  @apply grid grid-cols-8 w-full flex items-center;
}
.vault-item-value {
  @apply items-center flex justify-center text-sssm lg:text-smd;
}
.notice-container {
  @apply box-border my-1 rounded-2xl flex items-center text-ssm lg:text-base;
  height: 72px;
  padding: 0 22px;
  background-color: #cff4fc;
  color: #055160;
}
.mobile-vault-detail {
  @apply absolute mr-4 top-7;
  width: calc(100% - 32px);
}
.plant-sort {
  @apply absolute cursor-pointer h-3 lg:h-5 right-1;
}
.vault-item-title {
  @apply text-sssm lg:text-lg;
}
.vault-item-subtitle {
  @apply text-sssm lg:text-smd italic text-gray-390 mt-1;
}
</style>
<style>
@media (max-width: 1024px) {
  .crown img {
    @apply w-2;
  }
}
@media (min-width: 1024px) {
  .crown {
    min-width: 20px;
  }
}
.crown {
  @apply flex items-center justify-center rounded-full h-3.5 w-3.5 lg:h-5 lg:w-5 bg-blue-520 dark:bg-green-490 text-white;
}
</style>
