
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {},

  props: {
    size: {
      type: String,
      default: 'sm'
    },
    initValue: {
      type: Boolean,
      default: false
    }
  },

  emits: ['onChange'],

  setup(props, { emit }) {
    // DATA
    const toggled = ref(props.initValue);
    // METHOD
    const toggle = () => {
      toggled.value = !toggled.value;
      emit('onChange', toggled.value);
    };
    return {
      // data
      toggled,
      // method
      toggle
    };
  }
});
