
import { defineComponent } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';
import { NFT_TYPE } from '@/constants';

export default defineComponent({
  props: {
    nft: { type: Object }
  },

  setup() {
    const { explorerLinks } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      NFT_TYPE,
      explorerLinks,
      upToLargeBreakpoint
    };
  }
});
