
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import useNfts from '@/services/useNfts';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  emits: ['close'],

  props: {
    nft: { type: Object },
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const { burn } = useNfts();
    const { upToLargeBreakpoint } = useBreakpoints();

    // DATA
    const isBurning = ref(false);

    // METHODS
    const burnNft = async () => {
      if (props.nft && props.nft.id && !isBurning.value) {
        isBurning.value = true;
        try {
          await burn(props.nft.id);
          isBurning.value = false;
          toast(t('alert_burn_nft_success'));
          emit('close');
        } catch (error) {
          console.log(error);
          toast.error(t('alert_burn_nft_error'));
          isBurning.value = false;
        }
      }
    };

    return {
      upToLargeBreakpoint,
      // data
      isBurning,
      // method
      burnNft
    };
  }
});
