
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  PropType,
  watch,
  toRef
} from 'vue';
import { useI18n } from 'vue-i18n';
import useTokenLists from '@/composables/useTokenLists';
import TokenListItem from '@/components/lists/TokenListItem.vue';
import TokenListsListItem from '@/components/lists/TokenListsListItem.vue';
import Search from './Search.vue';
import useTokens from '@/composables/useTokens';
import { orderBy } from 'lodash';
import useUrls from '@/composables/useUrls';
import { TokenInfoMap } from '@/types/TokenList';

interface ComponentState {
  loading: boolean;
  selectTokenList: boolean;
  query: string;
  results: TokenInfoMap;
}

export default defineComponent({
  components: {
    TokenListItem,
    TokenListsListItem,
    Search
  },

  emits: ['close', 'selectTokenlist', 'select'],

  props: {
    open: { type: Boolean, default: false },
    excludedTokens: { type: Array as PropType<string[]>, default: () => [] },
    includeEther: { type: Boolean, default: false }
  },

  setup(props, { emit }) {
    /**
     * STATE
     */
    const state: ComponentState = reactive({
      loading: false,
      selectTokenList: false,
      query: '',
      results: {}
    });

    /**
     * COMPOSABLES
     */
    const {
      activeTokenLists,
      approvedTokenLists,
      toggleTokenList,
      isActiveList
    } = useTokenLists();
    const {
      searchTokens,
      priceFor,
      balanceFor,
      dynamicDataLoading,
      nativeAsset
    } = useTokens();
    const { t } = useI18n();
    const { resolve } = useUrls();

    /**
     * COMPUTED
     */
    const title = computed(() => {
      if (state.selectTokenList) return t('manageLists');
      return t('tokenSearch');
    });

    const tokenLists = computed(() => {
      const query = state.query.toLowerCase();
      const tokenListArray = Object.entries(approvedTokenLists.value);
      console.log(tokenListArray);
      const results = tokenListArray.filter(([, tokenList]) =>
        tokenList.name.toLowerCase().includes(query)
      );
      return Object.fromEntries(results);
    });

    const tokens = computed(() => {
      const tokensWithValues = Object.values(state.results).map(token => {
        const balance = balanceFor(token.address);
        const price = priceFor(token.address);
        const value = Number(balance) * price;
        return {
          ...token,
          price,
          balance,
          value
        };
      });

      return orderBy(tokensWithValues, ['value', 'balance'], ['desc', 'desc']);
    });

    const excludedTokens = computed(() => [
      ...props.excludedTokens,
      ...(props.includeEther ? [] : [nativeAsset.address])
    ]);

    /**
     * METHODS
     */
    function onSelectToken(token: string): void {
      emit('select', token);
      emit('close');
    }

    async function onToggleList(uri: string): Promise<void> {
      toggleTokenList(uri);
      state.results = await searchTokens(state.query, excludedTokens.value);
    }

    function onListExit(): void {
      state.selectTokenList = false;
      state.query = '';
    }

    function toggleSelectTokenList(): void {
      state.selectTokenList = !state.selectTokenList;
      state.query = '';
    }

    /**
     * WATCHERS
     */
    watch(
      toRef(state, 'query'),
      async newQuery => {
        state.results = await searchTokens(newQuery, excludedTokens.value);
      },
      { immediate: true }
    );

    return {
      // data
      ...toRefs(state),
      // computed
      title,
      tokens,
      tokenLists,
      activeTokenLists,
      dynamicDataLoading,
      // methods
      onSelectToken,
      onToggleList,
      onListExit,
      toggleSelectTokenList,
      isActiveList,
      resolve
    };
  }
});
