import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { isAddress } from '@ethersproject/address';
import { Web3Provider } from '@ethersproject/providers';
import { Connect } from '@/lib/vault/connect';
import useNetwork from '@/composables/useNetwork';
import useVaults from '../useVaults';
import { configService } from '../config/config.service';
import { rpcProviderService } from '../rpc-provider/rpc-provider.service';
import { web3Service } from './web3.service';

/** STATE */
const connector = ref(new Connect());
const isInited = ref(false);
const account = ref('');
const blockNumber = ref(0);
const connectStatus = ref('disconnected');

/** MUTATIONS */
function setBlockNumber(n: number): void {
  blockNumber.value = n;
}

/** INIT STATE */
rpcProviderService.initBlockListener(setBlockNumber);

connector.value.addEventListener('accountChanged', () => {
  account.value = connector.value.account;
});
connector.value.addEventListener('updateConnectStatus', () => {
  connectStatus.value = connector.value.connectStatus;
});

export default function useWeb3() {
  const toast = useToast();
  const { t } = useI18n();
  const { networkId } = useNetwork();
  const { init: initVaults, resolveVaults } = useVaults();

  const appNetworkConfig = configService.network;

  // COMPUTED
  const userNetworkConfig = computed(() =>
    configService.getNetworkConfig(networkId.value)
  );
  const explorerLinks = computed(() => ({
    txLink: (txHash: string) =>
      `${userNetworkConfig.value.explorer}/tx/${txHash}`,
    addressLink: (address: string) =>
      `${userNetworkConfig.value.explorer}/address/${address}`,
    tokenLink: (address: string) =>
      `${userNetworkConfig.value.explorer}/token/${address}`
  }));
  const isWalletReady = computed(() => account.value !== '');
  const isV1Supported = computed(() =>
    isAddress(userNetworkConfig.value.addresses.exchangeProxy)
  );
  const isEIP1559SupportedNetwork = computed(
    () => userNetworkConfig.value.supportsEIP1559
  );
  const isMismatchedNetwork = computed(() => {
    return (
      isWalletReady.value &&
      userNetworkConfig.value?.key !== appNetworkConfig.key
    );
  });
  const isSupportedAcsiFinance = computed(() => {
    return userNetworkConfig.value.supportsAcsiFinance;
  });
  const userProvider = computed(() => {
    return new Web3Provider(connector.value?.web3?.currentProvider as any);
  });

  // DATA
  const supportedWallets = ['metamask', 'walletconnect', 'binance'];
  const walletNameMap = {
    metamask: 'Metamask',
    walletconnect: 'WalletConnect',
    binance: 'Binance Chain Wallet'
  };

  // METHODS
  const getProvider = () =>
    new Web3Provider(connector.value?.web3?.currentProvider as any);
  const getSigner = () => getProvider().getSigner();
  const connectWallet = async () => {
    try {
      await connector.value?.connect();
      toast(t('alert_connect_wallet_success'));
    } catch (error) {
      console.error(error);
      if (error !== 'Modal closed by user') {
        toast.error(t('alert_connect_wallet_error'));
      }
    }
  };
  const disconnectWallet = async () => {
    try {
      await connector.value.disconnect();
    } catch (error) {
      console.log(error);
      if (error !== 'Modal closed by user') {
        toast.error(t('alert_connect_wallet_error'));
      }
    }
  };
  const init = async () => {
    isInited.value = false;
    initVaults();
    const initFunc = async () => {
      try {
        if (!isInited.value) {
          await connector.value.init(userNetworkConfig.value);
        }
        if (connector.value.web3) {
          web3Service.setUserProvider(userProvider);
          isInited.value = true;
          if (interval) {
            clearInterval(interval);
          }
          await resolveVaults(connector.value);
        }
      } catch (error) {
        console.error(error);
      }
    };

    initFunc();
    const interval = setInterval(async () => {
      initFunc();
    }, 5000);
  };

  return {
    explorerLinks,
    // computed
    isV1Supported,
    isWalletReady,
    userNetworkConfig,
    isMismatchedNetwork,
    isSupportedAcsiFinance,
    isEIP1559SupportedNetwork,
    // data
    account,
    connector,
    blockNumber,
    connectStatus,
    supportedWallets,
    walletNameMap,
    // methods
    init,
    getSigner,
    getProvider,
    setBlockNumber,
    disconnectWallet,
    connectWallet
  };
}
