
import { defineComponent } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';
import AppNavAccountBtn from './AppNavAccountBtn.vue';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  name: 'AppNavActions',

  components: {
    AppNavAccountBtn
  },

  setup() {
    const { connectWallet, connectStatus } = useWeb3();
    const { upToLargeBreakpoint } = useBreakpoints();

    return {
      upToLargeBreakpoint,
      connectWallet,
      connectStatus
    };
  }
});
