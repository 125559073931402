
import { defineComponent } from 'vue';
import TradeCard from '@/components/cards/TradeCard/TradeCard.vue';
import useTokenLists from '@/composables/useTokenLists';

export default defineComponent({
  emits: ['close'],

  components: {
    TradeCard
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { loadingTokenLists } = useTokenLists();

    return {
      loadingTokenLists
    };
  }
});
