<template>
  <div class="nft-viewer-bg">
    <div class="nft-viewer-con">
      <div class="nft-viewer-image-action">
        <video
          v-if="nft && nft.animation_url"
          class="nft-viewer-image"
          autoPlay
          controls
          controlsList="nodownload"
          loop
          playsInline
          preload="metadata"
          data-xblocker="passed"
        >
          <source :src="nft.animation_url" type="video/mp4" />
        </video>
        <img
          v-else-if="nft && nft.image"
          :src="nft.image"
          class="nft-viewer-image"
        />
        <BalLoadingBlock v-else rounded="2xl" class="nft-viewer-image" />
        <div class="nft-viewer-action">
          <div>
            <BalBtn
              @click="copyLink()"
              class="mr-2 lg:mr-4 nft-viewer-action-icon"
              circle
              outline
              :size="upToLargeBreakpoint ? 'sm' : 'md'"
            >
              <BalIcon
                :name="copiedLink ? 'akar-icons:check' : 'carbon:share'"
                class="text-white"
                :size="upToLargeBreakpoint ? 'sm' : 'md'"
              />
            </BalBtn>
            <BalBtn
              @click="downloadImage()"
              class="nft-viewer-action-icon"
              circle
              outline
              :disabled="!nft?.image && !nft?.animation_url"
              :size="upToLargeBreakpoint ? 'sm' : 'md'"
            >
              <BalIcon
                name="ic:outline-file-download"
                class="text-white"
                :size="upToLargeBreakpoint ? 'md' : 'lg'"
              />
            </BalBtn>
          </div>
          <div v-if="isUserNft">
            <BalBtn
              @click="trade"
              class="w-28 mr-2 lg:mr-4"
              color="sky-dark"
              :size="upToLargeBreakpoint ? 'sm' : 'md'"
              rounded
              :disabled="!(nft?.id || nft?.id === 0)"
            >
              <span class="nft-viewer-action-btn-label">{{
                $t('trade').toUpperCase()
              }}</span>
            </BalBtn>
            <BalBtn
              class="w-28"
              @click="isOpenBurnAlertModal = true"
              color="sky-dark"
              :size="upToLargeBreakpoint ? 'sm' : 'md'"
              rounded
              :disabled="!(nft?.id || nft?.id === 0)"
            >
              <span class="nft-viewer-action-btn-label">{{
                $t('burn_nft').toUpperCase()
              }}</span>
            </BalBtn>
          </div>
        </div>
      </div>
      <div class="nft-viewer-detail">
        <div class="nft-viewer-detail-title">
          {{
            nft?.tokenType === NftType.FOUNDER_NFT
              ? $t('genesis')
              : nft?.tokenType === NftType.WATERFALL
              ? $t('waterfall')
              : '--'
          }}
        </div>
        <div class="nft-viewer-detail-subtitle">
          <p>{{ $t('the_great_wave') }}</p>
          <p v-if="nft?.tokenType === NftType.WATERFALL">{{ $t('season1') }}</p>
          <p>{{ nft?.tokenType ? NFT_TYPE_FULL[nft.tokenType] : '--' }}</p>
        </div>
        <TypeOwner :nft="nft" />
        <div
          v-if="nft && nft.description"
          class="nft-viewer-desc"
          v-html="nft.description.replaceAll('\n', '<br />')"
        />
        <BalLoadingBlock v-else rounded="2xl" class="nft-viewer-desc" />
        <div class="nft-viewer-card-header">
          {{ $t('attributes').toUpperCase() }}
        </div>
        <div class="nft-viewer-card-body">
          <div class="nft-viewer-card-body-stats">
            <div class="nft-viewer-card-item">
              <span class="nft-viewer-card-item-label">{{ $t('type') }}</span>
              <span>
                {{
                  nft?.tokenType === NftType.FOUNDER_NFT
                    ? $t('founder')
                    : nft?.tokenType === NftType.WATERFALL
                    ? $t('governance')
                    : '--'
                }}</span
              >
            </div>
            <div class="nft-viewer-card-item">
              <span class="nft-viewer-card-item-label">{{
                $t('tgw_in_vault')
              }}</span>

              <span
                v-if="nft?.tokenType === NftType.FOUNDER_NFT"
                class="text-right"
              >
                --
              </span>
              <span v-else-if="nft?.stakedTgw" class="text-right">
                {{ `${showApr(nft.stakedTgw)} TGW` }}
                <br />
                <span class="text-gray-450" v-if="nft.stakedWvpUsd">
                  (${{ showApr(nft.stakedWvpUsd) }})
                </span>
              </span>
              <BalLoadingBlock
                v-else
                rounded="md"
                class="nft-viewer-value-loading"
              />
            </div>
          </div>
          <div class="nft-viewer-card-body-link">
            <div class="nft-viewer-card-item">
              <span class="nft-viewer-card-item-label">{{
                $t('contract')
              }}</span>
              <span class="nft-viewer-card-item-value">
                {{ shorten(addresses.tgwNft) }}
                <a
                  class="nft-viewer-card-item-value-icon"
                  :href="explorerLinks.addressLink(addresses.tgwNft)"
                  target="_blank"
                >
                  <BalIcon
                    name="charm:link-external"
                    :size="upToLargeBreakpoint ? 'xs' : 'smd'"
                  />
                </a>
              </span>
            </div>
            <div class="nft-viewer-card-item">
              <span class="nft-viewer-card-item-label">{{
                $t('token_id')
              }}</span>
              <span
                v-if="nft?.id || nft?.id === 0"
                class="nft-viewer-card-item-value"
              >
                {{ nft.id }}
                <a
                  class="nft-viewer-card-item-value-icon"
                  :href="
                    explorerLinks.tokenLink(`${addresses.tgwNft}?a=${nft.id}`)
                  "
                  target="_blank"
                >
                  <BalIcon
                    name="charm:link-external"
                    :size="upToLargeBreakpoint ? 'xs' : 'smd'"
                  />
                </a>
              </span>
              <BalLoadingBlock
                v-else
                rounded="md"
                class="nft-viewer-value-loading"
              />
            </div>
            <div class="nft-viewer-card-item">
              <span class="nft-viewer-card-item-label">{{
                $t('blockchain')
              }}</span>
              <span class="nft-viewer-card-item-value mr-6">
                BSC
              </span>
            </div>
            <div class="nft-viewer-card-item">
              <span class="nft-viewer-card-item-label">{{
                $t('metadata')
              }}</span>
              <span v-if="nft?.tokenUri" class="nft-viewer-card-item-value">
                {{ $t('centralized') }}
                <a
                  class="nft-viewer-card-item-value-icon"
                  :href="nft.tokenUri"
                  target="_blank"
                >
                  <BalIcon
                    name="charm:link-external"
                    :size="upToLargeBreakpoint ? 'xs' : 'smd'"
                  />
                </a>
              </span>
              <BalLoadingBlock
                v-else
                rounded="md"
                class="nft-viewer-value-loading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BurnAlertModal
    :nft="nft"
    :isOpen="isOpenBurnAlertModal"
    @close="isOpenBurnAlertModal = false"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import useBreakpoints from '@/composables/useBreakpoints';
import useNfts from '@/services/useNfts';
import useWeb3 from '@/services/web3/useWeb3';
import addresses from '@/constants/addresses';
import { shorten } from '@/lib/utils/index';
import { showApr } from '@/lib/utils/web3';
import { TGW_NFT_BASE_URI, tofunft, NFT_TYPE_FULL, NftType } from '@/constants';
import TypeOwner from './TypeOwner.vue';
import BurnAlertModal from './BurnAlertModal.vue';

export default defineComponent({
  components: {
    BurnAlertModal,
    TypeOwner
  },

  setup() {
    const route = useRoute();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { allNfts } = useNfts();
    const { explorerLinks, account } = useWeb3();

    // DATA
    const copiedLink = ref(false);
    const isOpenBurnAlertModal = ref(false);

    // COMPUTED
    const nft = computed(() =>
      allNfts.value.find(item => item && item.id === Number(route.params.id))
    );

    const isUserNft = computed(
      () =>
        nft.value?.owner &&
        account.value &&
        nft.value?.owner.toLowerCase() === account.value.toLowerCase()
    );

    // METHODS
    const downloadImage = async () => {
      if (nft.value && nft.value.image) {
        const url = nft.value.animation_url || nft.value.image;
        const splited = url.split('/');
        const fileName = splited[splited.length - 1];
        const link = document.createElement('a');
        link.href = await fetch(url)
          .then(response => response.blob())
          .then(blob => URL.createObjectURL(blob));
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    const copyLink = () => {
      if (!copiedLink.value) {
        navigator.clipboard.writeText(window.location.href);
        copiedLink.value = true;

        setTimeout(() => {
          copiedLink.value = false;
        }, 2 * 1000);
      }
    };

    const trade = () => {
      if (nft.value?.id || nft.value?.id === 0) {
        window
          .open(`${tofunft}/${addresses.tgwNft}/${nft.value.id}`, '_blank')
          ?.focus();
      }
    };

    return {
      NftType,
      showApr,
      shorten,
      addresses,
      NFT_TYPE_FULL,
      TGW_NFT_BASE_URI,
      explorerLinks,
      upToLargeBreakpoint,
      // computed
      nft,
      isUserNft,
      // data
      copiedLink,
      isOpenBurnAlertModal,
      // methods
      trade,
      copyLink,
      downloadImage
    };
  }
});
</script>

<style scoped>
.nft-viewer-bg {
  @apply w-full lg:overflow-auto lg:h-screen;
  background: linear-gradient(
    160.49deg,
    #040008 4.13%,
    #010208 21.34%,
    #201a3f 43.61%,
    #010208 68.42%,
    #201a3f 85.63%,
    #010101 101.33%,
    #010101 101.33%
  );
  box-shadow: 14px 31px 38px rgba(24, 23, 55, 0.58);
}
.nft-viewer-con {
  @apply mx-auto px-5 pt-24 lg:pt-52 grid grid-cols-2 gap-5 pb-12;
  width: min(1040px, 100%);
}
.nft-viewer-image-action {
  @apply col-span-2 lg:col-span-1 mx-auto lg:mx-0;
  max-width: 510px;
}
.nft-viewer-image {
  @apply rounded-2xl lg:w-full;
  min-height: 300px;
  border-radius: 30px;
}
.nft-viewer-action {
  @apply mt-4 flex justify-between w-full;
}
.nft-viewer-action > * {
  @apply flex;
}
.nft-viewer-action-icon {
  border: none;
}
.nft-viewer-action-btn-label {
  @apply font-bold;
  font-family: 'Exo 2';
}
.nft-viewer-detail {
  @apply col-span-2 lg:col-span-1 text-white;
}
.nft-viewer-detail-title {
  @apply font-extrabold text-3xl;
  font-family: 'Exo 2';
  line-height: 48px;
}
.nft-viewer-detail-subtitle {
  @apply font-extrabold text-2xl mt-12;
  font-family: 'Exo 2';
  line-height: 34px;
}
.nft-viewer-desc {
  @apply mt-12 text-ssm lg:text-sm font-normal w-full text-center lg:text-left;
  font-family: 'Fira Sans';
  min-height: 84px;
}
.nft-viewer-card-header {
  @apply bg-sky-420 py-4 px-8 mt-8 font-black text-xl;
  border-radius: 30px 30px 0px 0px;
  font-family: 'Exo 2';
}
.nft-viewer-card-body {
  @apply bg-black bg-opacity-60 px-8 py-3;
  border: 2px solid #19cbe0;
  border-radius: 0px 0px 30px 30px;
  font-family: 'Fira Sans';
}
.nft-viewer-card-body-stats {
  @apply pt-9 pb-5;
  border-bottom: 1px dashed #cde6e9;
}
.nft-viewer-card-item {
  @apply flex justify-between mb-4;
}
.nft-viewer-card-item-label {
  @apply text-base font-semibold;
}
.nft-viewer-value-loading {
  @apply h-7 w-48;
}
.nft-viewer-card-body-link {
  @apply mt-6;
}
.nft-viewer-card-item-value {
  @apply flex text-gray-310;
}
.nft-viewer-card-item-value-icon {
  @apply ml-2 cursor-pointer flex items-center;
}
</style>
