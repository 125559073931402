<template>
  <div
    class="sidebar-backdrop"
    @click="setMenuShow(false)"
    v-if="menuShow && upToLargeBreakpoint"
  ></div>
  <transition name="slide">
    <div
      class="sidebar-container"
      v-if="menuShow || !upToLargeBreakpoint"
      :class="[
        menuCollapsed ? 'w-18' : 'uncollapsed',
        { 'sidebar-panel fixed': upToLargeBreakpoint },
        {
          'sidebar-panel bg-opacity absolute':
            !upToLargeBreakpoint && currentRouteName === 'stake_mint'
        }
      ]"
    >
      <div class="relative">
        <div class="logo-container">
          <transition name="opacity-transition">
            <BalIcon
              v-if="menuCollapsed"
              name="akar-icons:circle-chevron-right"
              class="menu-icon right-3.5"
              size="lg"
              @click="setMenuCollapsed(false)"
            />
          </transition>
          <transition
            name="opacity-transition-logo"
            v-on:after-enter="afterUncollapse"
          >
            <div v-if="!menuCollapsed" class="logo"></div>
          </transition>
          <transition name="opacity-transition">
            <BalIcon
              v-if="!menuCollapsed"
              name="akar-icons:circle-chevron-left"
              class="menu-icon opened-menu-icon"
              :size="upToLargeBreakpoint ? 'lg' : 'lg'"
              @click="setMenuClose()"
            />
          </transition>
        </div>
        <div class="menu-list-container" :class="{ 'w-58': !menuCollapsed }">
          <div v-for="(item, i) in menuList" :key="i">
            <div
              @click="selectMenu(i)"
              class="menu-item"
              :class="
                currentRouteName === item.url ||
                (item.childs &&
                  item.childs.find(it => it.url === currentRouteName) &&
                  !item.isOpen)
                  ? 'selected'
                  : ''
              "
            >
              <BalIcon
                name="akar-icons:circle"
                class="menu-list-icon"
                :size="upToLargeBreakpoint ? 'sm' : 'lg'"
              />
              <transition name="opacity-transition">
                <span v-if="!menuCollapsed" class="menu-label">{{
                  $t(item.value)
                }}</span>
              </transition>
              <transition name="opacity-transition">
                <BalIcon
                  v-if="item.childs && !menuCollapsed"
                  name="akar-icons:chevron-left"
                  :size="upToLargeBreakpoint ? 'sm' : 'md'"
                  class="expend-icon"
                  :class="item.isOpen ? 'transform -rotate-90' : ''"
                />
              </transition>
            </div>
            <transition :name="openingMenu ? 'opacity-transition' : ''">
              <div v-if="item.childs && item.isOpen && !menuCollapsed">
                <div
                  v-for="(child, ind) in item.childs"
                  :key="ind"
                  @click="selectMenu(i, ind)"
                  class="menu-sub-item"
                  :class="
                    currentRouteName === child.url &&
                    (!child.type || child.type === currentRouteQueryType)
                      ? 'selected'
                      : ''
                  "
                >
                  <span class="menu-label">{{ $t(child.value) }}</span>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <transition name="opacity-transition">
        <div
          v-if="!menuCollapsed"
          class="sidebar-bottom"
          data-dev-hint="second-main-navigation or footer navigation"
        >
          <div v-if="acsStats" class="acs">
            <p class="mb-1">
              <span>WAV: </span>
              <span class="number">
                {{ `$${acsStats.wavPriceUsd}` }}
              </span>
            </p>
            <p>
              <span>{{ $t('tvl') }}: </span>
              <span class="number">
                {{ `$${acsStats.tvl}` }}
              </span>
            </p>
          </div>
          <div class="social-container">
            <a
              target="_blank"
              href="https://t.me/thegreatwavedao"
              class="telegram bg-blue-810 pl-1.5"
            >
              <BalIcon
                name="bx:bxl-telegram"
                :size="upToLargeBreakpoint ? 'xsd' : 'smd'"
              />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/thegreatwaveio"
              class="twitter"
            >
              <BalIcon
                name="akar-icons:twitter-fill"
                :size="upToLargeBreakpoint ? 'xsd' : 'smd'"
              />
            </a>
            <a
              target="_blank"
              href="https://medium.com/thegreatwave"
              class="medium"
            >
              <BalIcon
                name="ant-design:medium-outlined"
                :size="upToLargeBreakpoint ? 'xsd' : 'smd'"
              />
            </a>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
// import { useI18n } from 'vue-i18n';
// import { useToast } from 'vue-toastification';
import useDarkMode from '@/composables/useDarkMode';
import useBreakpoints from '@/composables/useBreakpoints';
import useMenuCollapsed from '@/composables/useMenuCollapsed';
import useBuyTokenModal from '@/composables/useBuyTokenModal';
import useVaults from '@/services/useVaults';
import { configService } from '@/services/config/config.service';
import addresses from '@/constants/addresses';
import { NFT_DEFAULT_TYPE, NftType } from '@/constants';

const { nativeAsset } = configService.network;

export default defineComponent({
  setup() {
    // const { t } = useI18n();
    // const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const { toggleBuyTokenModal } = useBuyTokenModal();
    const { darkMode } = useDarkMode();
    const { upToLargeBreakpoint } = useBreakpoints();
    const {
      menuCollapsed,
      setMenuCollapsed,
      menuShow,
      setMenuShow
    } = useMenuCollapsed();
    const { acsStats } = useVaults();

    onMounted(() => {
      if (!upToLargeBreakpoint.value) {
        setMenuShow(true);
      }
    });

    // COMPUTED
    const currentRouteName = computed(() => router.currentRoute.value.name);
    const currentRouteQueryType = computed(
      () => route.query.type || NFT_DEFAULT_TYPE
    );
    const toolsMenuIndex = computed(() =>
      menuList.value.findIndex(item => item.value === 'tools')
    );

    // DATA
    const openingMenu = ref(false);
    const menuList = ref([
      {
        value: 'buy_nft',
        isOpen: false,
        childs: [
          {
            value: 'waterfall',
            url: 'buy-nft',
            type: NftType.WATERFALL
          },
          {
            value: 'sparks',
            url: 'buy-nft',
            type: NftType.SPARKS
          }
        ]
      },
      {
        value: 'my_nfts',
        url: 'my-nfts'
      },
      {
        value: 'leaderboard',
        isOpen: false,
        childs: [
          {
            value: 'waterfall',
            url: 'leaderboard',
            type: NftType.WATERFALL
          },
          {
            value: 'sparks',
            url: 'leaderboard',
            type: NftType.SPARKS
          }
        ]
      },
      {
        value: 'buy_wav'
      },
      {
        value: 'wav_farm',
        url: 'wav-farm'
      },
      {
        value: 'governance_vote',
        link: 'https://vote.thegreatwave.io'
      },
      {
        value: 'docs',
        //icon: 'carbon:document-blank',
        isOpen: false,
        childs: [
          {
            value: 'documentation',
            link: 'https://docs.thegreatwave.io/'
          },
          {
            value: 'tokenomics',
            link: 'https://docs.thegreatwave.io/tokenomics'
          },
          {
            value: 'smart_contracts',
            link: 'https://docs.thegreatwave.io/contracts'
          }
        ]
      }
    ]);

    // METHODS
    const selectMenu = (index, childIndex = -1) => {
      if (menuList.value[index].value === 'buy_wav') {
        if (upToLargeBreakpoint.value) {
          setMenuShow(false);
        }
        toggleBuyTokenModal(true, nativeAsset.address, addresses.wav);
      }
      if (childIndex >= 0) {
        if (menuList.value[index].childs[childIndex].link) {
          window
            .open(menuList.value[index].childs[childIndex].link, '_blank')
            .focus();
        } else if (menuList.value[index].childs[childIndex].url) {
          if (menuList.value[index].childs[childIndex].type) {
            router.push({
              path: '/' + menuList.value[index].childs[childIndex].url,
              query: { type: menuList.value[index].childs[childIndex].type }
            });
          } else {
            router.push({
              path: '/' + menuList.value[index].childs[childIndex].url
            });
          }
          if (upToLargeBreakpoint.value) {
            setMenuShow(false);
          }
        }
      } else {
        closeSubMenu(index);
        if (menuList.value[index].childs) {
          if (menuCollapsed.value) {
            openingMenu.value = true;
            setMenuCollapsed(false);
          }
          menuList.value[index].isOpen = !menuList.value[index].isOpen;
        } else {
          // if (menuList.value[index].value === 'governance_nft') {
          //   toast.warning(t('alert_coming_soon'));
          // }
          if (menuList.value[index].link) {
            window.open(menuList.value[index].link, '_blank').focus();
          } else if (menuList.value[index].url) {
            if (menuList.value[index].type) {
              router.push({
                path: '/' + menuList.value[index].url,
                query: { type: menuList.value[index].type }
              });
            } else {
              router.push({ path: '/' + menuList.value[index].url });
            }
            if (upToLargeBreakpoint.value) {
              setMenuShow(false);
            }
          }
        }
      }
    };
    const setMenuClose = () => {
      if (upToLargeBreakpoint.value) {
        setMenuShow(false);
      } else {
        setMenuCollapsed(true);
        closeSubMenu();
      }
    };
    const closeSubMenu = (index = -1) => {
      for (let i = 0; i < menuList.value.length; i++) {
        if (i === index) {
          continue;
        }
        menuList.value[i].isOpen = false;
      }
    };
    const setOpenChildMenu = value => {
      for (let i = 0; i < menuList.value.length; i++) {
        if (
          menuList.value[i].childs &&
          menuList.value[i].childs.find(item => item.value === value)
        ) {
          menuList.value[i].isOpen = true;
        }
      }
    };
    const afterUncollapse = () => {
      openingMenu.value = false;
    };

    // WATCHERS
    watch(upToLargeBreakpoint, newVal => {
      setMenuShow(!newVal);
      setMenuCollapsed(!newVal);
      closeSubMenu();
    });
    watch(currentRouteName, newVal => {
      setOpenChildMenu(newVal);
      if (newVal === 'stake_mint') {
        closeSubMenu();
        if (!upToLargeBreakpoint.value) {
          setMenuCollapsed(true);
        }
      }
    });

    return {
      darkMode,
      acsStats,
      upToLargeBreakpoint,
      menuShow,
      menuCollapsed,
      setMenuShow,
      setMenuCollapsed,
      // computed
      toolsMenuIndex,
      currentRouteName,
      currentRouteQueryType,
      // data
      openingMenu,
      menuList,
      // methods
      afterUncollapse,
      closeSubMenu,
      setMenuClose,
      selectMenu
    };
  }
});
</script>

<style scoped>
@media (max-width: 1024px) {
  .menu-list-container {
    /* height: calc(100vh - 296px); */
  }
  .uncollapsed {
    min-width: 232px;
  }
}
@media (min-width: 1024px) {
  .menu-list-container {
    /* height: calc(100vh - 514px); */
  }
  .uncollapsed {
    width: 232px;
  }
}
.sidebar-container {
  @apply flex flex-col justify-between bg-white dark:bg-gray-780;
  transition: width 1s;
  height: 100vh;
}
.logo-container {
  @apply mt-10 ml-6 mr-4 flex;
}
.logo {
  @apply rounded-lg h-36 w-36;
  background-image: url('~@/assets/images/logo.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.menu-icon {
  @apply cursor-pointer w-6 h-6 text-sky-320;
}
.opened-menu-icon {
  @apply ml-6;
}
.menu-list-container {
  @apply overflow-auto mt-16;
}
.menu-item,
.menu-sub-item {
  @apply cursor-pointer flex items-center;
}
.menu-item {
  @apply py-2 pl-4 lg:pl-6 relative;
}
.menu-sub-item {
  @apply py-2 pl-11 lg:pl-16;
}
.menu-list-icon {
  @apply mr-3 lg:mr-4 text-sky-320 dark:text-gray-450;
}
.expend-icon {
  @apply duration-100 absolute left-48 dark:text-gray-450;
}
.menu-label {
  @apply dark:text-gray-450 font-medium text-ssm lg:text-base lg:mt-0.5;
}
.selected .expend-icon,
.menu-item:hover .expend-icon,
.selected .menu-label,
.menu-item:hover .menu-label,
.menu-sub-item:hover .menu-label {
  @apply text-sky-320;
}
.acs {
  @apply font-medium text-sssm lg:text-sm bg-gray-70 dark:bg-gray-730 p-4 rounded-2xl;
}
.number {
  @apply text-gray-450 dark:text-gray-350;
}
.social-container {
  @apply flex mt-6 lg:mt-16;
}
.telegram,
.twitter,
.medium {
  @apply h-6 w-6 lg:h-8 lg:w-8 rounded-full;
}
.telegram {
  @apply bg-blue-810 pl-1 lg:pl-1.5 flex items-center text-white;
}
.twitter {
  @apply bg-blue-930 flex items-center justify-center ml-2 lg:ml-4 text-white;
}
.medium {
  @apply bg-gray-670 dark:bg-white flex items-center justify-center ml-2 lg:ml-4 text-white dark:text-black;
}
.w-18 {
  width: 72px;
}
.w-58 {
  width: 232px;
}
.sidebar-backdrop {
  @apply fixed top-0 left-0 cursor-pointer bg-black opacity-80;
  width: 100vw;
  height: 100vh;
  z-index: 998;
}
.sidebar-panel {
  left: 0;
  top: 0;
  z-index: 999;
}
.bg-opacity {
  --tw-bg-opacity: 0.9 !important;
}
.sidebar-bottom {
  @apply mb-32 cursor-default px-6;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.opacity-transition-logo-enter-active {
  transition: opacity 0.3s;
  transition-delay: 0.7s;
}
.opacity-transition-logo-leave-active {
  transition: opacity 0.3s;
}
.opacity-transition-logo-enter-from,
.opacity-transition-logo-leave-to {
  opacity: 0;
}
</style>
