import { Config } from './config';

export { StableSwap };

import { bn, fw } from './bn.js';

class StableSwap extends EventTarget {
  static stableSwapsByLpToken = {};

  constructor(i, connect) {
    super();

    StableSwap.stableSwapsByLpToken[i.lpToken] = this;

    for (let k in i) {
      this[k] = i[k];
    }
    this.defaultBaseTokenDisplayDecimals = this.baseSymbol == 'BTC' ? 8 : 4;

    this.connect = connect;
    this.contract = new this.connect.web3.eth.Contract(i.abi, i.address);
    this.contractAgo = new this.connect.web3Ago.eth.Contract(i.abi, i.address);
    if (i.metaDeposit) {
      this.metaDepositContract = new this.connect.web3.eth.Contract(
        i.metaDepositAbi,
        i.metaDeposit
      );
    }
    this.lpTokenContract = new this.connect.web3.eth.Contract(
      Config.erc20abi,
      i.lpToken
    );
    this.coinContracts = this.coins.map(
      address => new this.connect.web3.eth.Contract(Config.erc20abi, address)
    );
    this.coinMultipliers = this.coins.map(address =>
      bn(
        Config.tokens[address].decimals
          ? 10 ** (18 - Config.tokens[address].decimals)
          : 1
      )
    );
    this.balances = [];
    this.userBalances = [];
  }

  async init() {
    // this.contract
  }

  initPricePollers() {
    this.connect.initPoller.add({
      target: this.address,
      method: () => this.contract.methods.get_virtual_price(),
      cb: (i, b) => {
        this.currentBlock = b;
        this.virtualPrice = bn(i);
      }
    });

    if (
      !this.fromBlock ||
      this.connect.agoPoller.options.blockNumber > this.fromBlock
    ) {
      this.connect.agoPoller.add({
        target: this.address,
        method: () => this.contract.methods.get_virtual_price(),
        cb: (i, b) => {
          this.agoBlock = b;
          this.agoVirtualPrice = bn(i);
          const daysAgo = (this.currentBlock - this.agoBlock) / 28800;
          this.roiDay =
            (fw(this.virtualPrice) / fw(this.agoVirtualPrice)) **
              (1 / daysAgo) -
            1;
          this.apyDay = (this.roiDay + 1) ** 365 - 1;
          this.aprDay = this.roiDay * 365;
          // console.log(this.apyDay*100)
        }
      });
    }
  }

  async calcTokenAmount(amounts, isDeposit) {
    amounts = this.unapplyCoinMultipliers(amounts);
    return bn(
      await (this.metaDepositContract || this.contract).methods
        .calc_token_amount(amounts, isDeposit)
        .call()
    );
  }

  async calcRemoveLiquidity(amountLpToken) {
    let coins = await (this.metaDepositContract || this.contract).methods
      .remove_liquidity(
        amountLpToken,
        this.coins.map(() => 0)
      )
      .call({ from: this.connect.account });
    coins = this.applyCoinMultipliers(coins.map(i => bn(i)));
    return coins;
  }

  async calcWithdrawOneCoin(amountLpToken, index) {
    return this.applyCoinMultiplier(
      index,
      bn(
        await (this.metaDepositContract || this.contract).methods
          .calc_withdraw_one_coin(amountLpToken, index)
          .call()
      )
    );
  }

  async getVirtualPrice() {
    return bn(await this.contract.methods.get_virtual_price().call());
  }

  async getDy(i, j, dx) {
    dx = this.unapplyCoinMultiplier(i, dx);
    // console.log(i,j,dx)
    //get_dy(i: int128, j: int128, dx: uint256)
    let method = this.basePool
      ? this.contract.methods.get_dy_underlying
      : this.contract.methods.get_dy;
    let dy = this.applyCoinMultiplier(j, bn(await method(i, j, dx).call()));
    // console.log(i,j,dy.toString())
    return dy;
  }

  async updateInit() {
    this.updateInit = () => {
      //run once only
    };

    let q = [];

    q.push(
      this.connect.web3.eth.getBlockNumber().then(block => {
        this.virtualPriceAgoBlock = block - 28800 * 3;
        if (!this.fromBlock || this.virtualPriceAgoBlock > this.fromBlock) {
          return this.contractAgo.methods
            .get_virtual_price()
            .call({}, this.virtualPriceAgoBlock)
            .then(i => (this.virtualPriceAgo = bn(i)))
            .catch(e => {
              console.error(e);
              // alert('Error retrieving APY data from RPC. APY data will not show correctly, please refresh to try again.')
            });
        }
      })
    );

    return Promise.all(q);
  }

  async update() {
    //TODO: throttle? as calls get cascaded to basepool
    console.log('updateStableSwap', this.lpTokenSymbol);

    let q = [this.updateInit()];

    let nCoins =
      this.coins.length - ((this.basePool && this.basePool.coins.length) || 0);
    for (let index = 0; index < nCoins; index++) {
      q.push(
        this.contract.methods
          .balances(index)
          .call()
          .then(
            i => (this.balances[index] = this.applyCoinMultiplier(index, bn(i)))
          )
      );
    }
    if (this.basePool) {
      q.push(
        this.contract.methods
          .balances(nCoins)
          .call()
          .then(i => (this.basePoolBalance = bn(i)))
      );
      q.push(this.basePool.update());
    }

    q.push(
      ...[
        this.lpTokenContract.methods
          .totalSupply()
          .call()
          .then(i => (this.lpTokenSupply = bn(i))),
        this.contract.methods
          .get_virtual_price()
          .call()
          .then(i => (this.virtualPrice = bn(i)))
      ]
    );

    await Promise.all(q);

    //adjust base pool coin balances
    if (this.basePool) {
      this.basePool.balances.forEach((v, index) => {
        this.balances[index + nCoins] = v
          .mul(this.basePoolBalance)
          .div(this.basePool.lpTokenSupply);
      });
    }

    this.totalBalance = this.balances.reduce((a, v) => a.add(bn(v)), bn(0));
    this.totalBalanceBase = this.lpTokenSupply
      .mul(this.virtualPrice)
      .div(bn(1e18));

    if (this.virtualPriceAgo) {
      let block = await this.connect.web3.eth.getBlockNumber();
      let daysAgo = (block - this.virtualPriceAgoBlock) / 28800;
      this.roiDay =
        parseFloat(
          fw(this.virtualPrice.mul(bn(1e18)).div(this.virtualPriceAgo))
        ) **
          (1 / daysAgo) -
        1;
      this.apyDay = (this.roiDay + 1) ** 365 - 1;
      this.aprDay = this.roiDay * 365;

      this.volume =
        (parseFloat(fw(this.totalBalanceBase)) * this.roiDay) /
        (this.fee * this.adminFee);
    }

    this.dispatchEvent(new CustomEvent('update'));
  }

  async updateUser() {
    if (this.connect.account) {
      console.log('updateUserStableSwap', this.lpTokenSymbol);
      let q = this.coinContracts.map((cc, index) =>
        cc.methods
          .balanceOf(this.connect.account)
          .call()
          .then(
            i =>
              (this.userBalances[index] = this.applyCoinMultiplier(
                index,
                bn(i)
              ))
          )
      );

      q.push(
        this.lpTokenContract.methods
          .balanceOf(this.connect.account)
          .call()
          .then(i => (this.userBalanceLpToken = bn(i)))
      );
      await Promise.all(q);
    } else {
      this.userBalances = [];
      delete this.userBalanceLpToken;
    }
    this.dispatchEvent(new CustomEvent('update'));
  }

  //transactions

  async exchange(i, j, dx, minDy) {
    //exchange(i: int128, j: int128, dx: uint256, min_dy: uint256) -> uint256:
    dx = this.unapplyCoinMultiplier(i, dx);
    minDy = this.unapplyCoinMultiplier(j, minDy);
    console.log('exchange(i,j,dx,minDy)', i, j, dx, minDy);

    let allowance = bn(
      await this.coinContracts[i].methods
        .allowance(this.connect.account, this.address)
        .call()
    );
    if (allowance.lt(bn(dx))) {
      await this.connect.send(
        this.coinContracts[i].methods.approve(this.address, Config.maxuint),
        {},
        true
      );
    }

    let method = this.basePool
      ? this.contract.methods.exchange_underlying
      : this.contract.methods.exchange;
    return this.connect.send(method(i, j, dx, minDy));
  }

  async deposit(amounts, min_mint_amount) {
    amounts = this.unapplyCoinMultipliers(amounts);
    console.log('deposit(amounts,min_mint_amount)', amounts, min_mint_amount);
    for (let index = 0; index < amounts.length; index++) {
      console.log('check allowance', index);
      let allowance = bn(
        await this.coinContracts[index].methods
          .allowance(this.connect.account, this.metaDeposit || this.address)
          .call()
      );
      if (allowance.lt(bn(amounts[index]))) {
        await this.connect.send(
          this.coinContracts[index].methods.approve(
            this.metaDeposit || this.address,
            Config.maxuint
          ),
          {},
          true
        );
      }
    }

    //need allowance to spend lpToken here in order to use remove_liquidity call in calcRemoveLiquidity
    let allowance = bn(
      await this.lpTokenContract.methods
        .allowance(this.connect.account, this.metaDeposit || this.address)
        .call()
    );
    if (allowance.lten(0)) {
      await this.connect.send(
        this.lpTokenContract.methods.approve(
          this.metaDeposit || this.address,
          Config.maxuint
        ),
        {},
        true
      );
    }

    return this.connect.send(
      (this.metaDepositContract || this.contract).methods.add_liquidity(
        amounts,
        min_mint_amount
      )
    );
  }

  async withdrawOneCoin(amountLpToken, index, minAmount) {
    minAmount = this.unapplyCoinMultiplier(index, minAmount);
    console.log(
      'withdrawOneCoin(amountLpToken,index,minAmount)',
      amountLpToken,
      index,
      minAmount
    );
    //remove_liquidity_one_coin(_token_amount: uint256, i: int128, _min_amount: uint256) -> uint256:
    let allowance = bn(
      await this.lpTokenContract.methods
        .allowance(this.connect.account, this.metaDeposit || this.address)
        .call()
    );
    if (allowance.lt(bn(amountLpToken))) {
      await this.connect.send(
        this.lpTokenContract.methods.approve(
          this.metaDeposit || this.address,
          Config.maxuint
        ),
        {},
        true
      );
    }
    return this.connect.send(
      (
        this.metaDepositContract || this.contract
      ).methods.remove_liquidity_one_coin(amountLpToken, index, minAmount)
    );
  }

  async withdraw(amountLpToken, minAmounts) {
    minAmounts = this.unapplyCoinMultipliers(minAmounts);
    console.log(
      'withdraw(amountLpToken,minAmounts)',
      amountLpToken,
      minAmounts
    );
    //remove_liquidity(_amount: uint256, min_amounts: uint256[N_COINS]) -> uint256[N_COINS]:
    let allowance = bn(
      await this.lpTokenContract.methods
        .allowance(this.connect.account, this.metaDeposit || this.address)
        .call()
    );
    if (allowance.lt(bn(amountLpToken))) {
      await this.connect.send(
        this.lpTokenContract.methods.approve(
          this.metaDeposit || this.address,
          Config.maxuint
        ),
        {},
        true
      );
    }
    return this.connect.send(
      (this.metaDepositContract || this.contract).methods.remove_liquidity(
        amountLpToken,
        minAmounts
      )
    );
  }
  async withdrawImbalance(amounts, amountLpTokenMax) {
    amounts = this.unapplyCoinMultipliers(amounts);
    console.log(
      'withdrawImbalance(amounts,amountLpTokenMax)',
      amounts,
      amountLpTokenMax
    );
    //remove_liquidity_imbalance(amounts: uint256[N_COINS], max_burn_amount: uint256) -> uint256:
    let allowance = bn(
      await this.lpTokenContract.methods
        .allowance(this.connect.account, this.metaDeposit || this.address)
        .call()
    );
    if (allowance.lt(bn(amountLpTokenMax))) {
      await this.connect.send(
        this.lpTokenContract.methods.approve(
          this.metaDeposit || this.address,
          Config.maxuint
        ),
        {},
        true
      );
    }
    return this.connect.send(
      (
        this.metaDepositContract || this.contract
      ).methods.remove_liquidity_imbalance(amounts, amountLpTokenMax)
    );
  }

  applyCoinMultiplier(i, amount) {
    return typeof amount === 'string'
      ? bn(amount)
          .mul(this.coinMultipliers[i])
          .toString()
      : amount.mul(this.coinMultipliers[i]);
  }
  unapplyCoinMultiplier(i, amount) {
    return typeof amount === 'string'
      ? bn(amount)
          .div(this.coinMultipliers[i])
          .toString()
      : amount.div(this.coinMultipliers[i]);
  }
  applyCoinMultipliers(amounts) {
    return amounts.map((amount, i) => this.applyCoinMultiplier(i, amount));
  }
  unapplyCoinMultipliers(amounts) {
    return amounts.map((amount, i) => this.unapplyCoinMultiplier(i, amount));
  }
}
