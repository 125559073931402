<template>
  <BalBtn
    circle
    color="sky-dark"
    outline
    :size="upToLargeBreakpoint ? 'xs' : 'sm'"
    @click="toggleDarkMode"
  >
    <BalIcon name="bx:bxs-sun" :size="upToLargeBreakpoint ? 'sm' : 'md'" />
  </BalBtn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useDarkMode from '@/composables/useDarkMode';
import useBreakpoints from '@/composables/useBreakpoints';

export default defineComponent({
  name: 'DarkModeToggle',

  setup() {
    const { upToLargeBreakpoint } = useBreakpoints();
    const { darkMode, toggleDarkMode } = useDarkMode();

    return {
      darkMode,
      toggleDarkMode,
      upToLargeBreakpoint
    };
  }
});
</script>
