import { Config } from './config';
import { Vault } from './vault.js';
import { Farm } from './farm.js';
import { Pair } from './pair.ts';
import { Blp } from './blp.js';
import { StableSwap } from './stable-swap.js';
import { Poller } from './poller.js';
import { quoter } from './quoter';
import addresses from '@/constants/addresses';

import { bn, fw, fwp } from './bn.js';

import ConfigGenerated from './config/generated.json';

class VaultsController extends EventTarget {
  constructor() {
    super();
  }

  async resolve(connect) {
    this.connect = connect;
    await this.init();
  }

  async init() {
    // this.init = () => { }
    this.connect.poller = new Poller(this.connect.web3);
    this.connect.pairPoller = new Poller(this.connect.web3);
    this.connect.initPoller = new Poller(this.connect.web3);
    // this.connect.poller = new Poller(this.connect.web3Ago)
    // this.connect.initPoller = new Poller(this.connect.web3Ago)

    const initBlockNumber = parseInt(
      await this.connect.web3.eth.getBlockNumber()
    );
    console.log('initBlockNumber', initBlockNumber);
    const daysAgo = 3;
    this.connect.agoPoller = new Poller(this.connect.web3Ago, {
      blockNumber: initBlockNumber - daysAgo * 28800
    });
    const daysAgoShort = 1;
    this.connect.agoPollerShort = new Poller(this.connect.web3Ago, {
      blockNumber: initBlockNumber - daysAgoShort * 28800
    });

    Object.entries(
      // this.deprecated ? ConfigGenerated.pairsDeprecated : ConfigGenerated.pairs
      ConfigGenerated.pairs
    ).forEach(
      ([address, pair]) =>
        new Pair({
          ...pair,
          address,
          web3: this.connect.web3,
          poller: this.connect.pairPoller,
          agoPoller: this.connect.agoPoller,
          fromBlock: Config.contractsFromBlock[address]
        })
    );
    // Object.entries(ConfigGenerated.pairsDeprecated).forEach(
    //   ([address, pair]) =>
    //     new Pair({
    //       ...pair,
    //       address,
    //       web3: this.connect.web3,
    //       poller: this.connect.pairPoller,
    //       agoPoller: this.connect.agoPoller,
    //       fromBlock: Config.contractsFromBlock[address]
    //     })
    // );
    Object.entries(ConfigGenerated.blps).forEach(
      ([address, blp]) =>
        new Blp({
          ...blp,
          address,
          web3: this.connect.web3,
          poller: this.connect.pairPoller,
          agoPoller: this.connect.agoPoller,
          fromBlock: Config.contractsFromBlock[address]
        })
    );
    // Object.entries(ConfigGenerated.blpsDeprecated).forEach(
    //   ([address, blp]) =>
    //     new Blp({
    //       ...blp,
    //       address,
    //       web3: this.connect.web3,
    //       poller: this.connect.pairPoller,
    //       agoPoller: this.connect.agoPoller,
    //       fromBlock: Config.contractsFromBlock[address]
    //     })
    // );

    this.farmVaults = Config.farmVaults
      .filter(farmVault => farmVault.tags.includes('wav'))
      .map(i => {
        const farmVault = i;
        if (i.farm) {
          i.farm.token ||= i.vault && i.vault.address;
          farmVault.farm = new Farm(i.farm, this.connect);
        }
        if (i.vault) {
          i.vault.token ||= ConfigGenerated.vaults[i.vault.address].token;
          i.vault.strategy ||= ConfigGenerated.vaults[i.vault.address].strategy;
          farmVault.vault = new Vault(i.vault, this.connect);
        }
        if (farmVault.farm && farmVault.vault) {
          farmVault.farm.vault = farmVault.vault;
          farmVault.vault.farm = farmVault.farm;
        }

        return farmVault;
      });
    this.farmVaultsDeprecated = [];
    // this.farmVaultsDeprecated = Config.farmVaultsDeprecated.map(i => {
    //   const farmVault = i;
    //   if (i.farm) {
    //     i.farm.token ||= i.vault && i.vault.address;
    //     farmVault.farm = new Farm(i.farm, this.connect);
    //   }
    //   if (i.vault) {
    //     i.vault.token ||= ConfigGenerated.vaults[i.vault.address].token;
    //     i.vault.strategy ||= ConfigGenerated.vaults[i.vault.address].strategy;
    //     farmVault.vault = new Vault(i.vault, this.connect);
    //   }
    //   if (farmVault.farm && farmVault.vault) {
    //     farmVault.farm.vault = farmVault.vault;
    //     farmVault.vault.farm = farmVault.farm;
    //   }

    //   return farmVault;
    // });
    // if (!this.deprecated) {
    Farm.acsVault = this.farmVaults.find(
      farmVault => farmVault.vault && farmVault.vault.tokenSymbol == 'ACS'
    )?.vault;
    Farm.acsiVault = this.farmVaults.find(
      farmVault => farmVault.vault && farmVault.vault.tokenSymbol == 'ACSI'
    )?.vault;
    Farm.wavVault = this.farmVaults.find(
      farmVault => farmVault.vault && farmVault.vault.title == 'TGW Vault'
    )?.vault;
    // }

    // to get stableswap prices
    Config.stableSwaps.forEach(i => {
      const ss = new StableSwap(i, this.connect);
      ss.initPricePollers();
    });

    while (this.connect.initPoller.calls.length > 0) {
      console.log('initPoller');
      await this.connect.initPoller.poll({ clearCalls: true });
    }
    console.log('agoPoller');
    await this.connect.agoPoller.poll().catch(e => {
      console.error(e);
      // alert('Error retrieving APY data from RPC. APY data will show as lower than actual, please refresh to try again. App is still fully functional, we are working on a fix.')
    });
    console.log('agoPollerShort');
    await this.connect.agoPollerShort.poll().catch(e => {
      console.error(e);
      // alert('Error retrieving APY data from RPC. APY data will show as lower than actual, please refresh to try again. App is still fully functional, we are working on a fix.')
    });

    // if (!this.deprecated) {
    this.acsStats = {};

    // this.contractAcs = new this.connect.web3.eth.Contract(
    //   Config.erc20abi,
    //   Config.acs
    // );
    // this.contractAcsi = new this.connect.web3.eth.Contract(
    //   Config.erc20abi,
    //   Config.acsi
    // );

    // this.connect.poller.add({
    //   target: Config.acs,
    //   method: () => this.contractAcs.methods.totalSupply(),
    //   cb: i => (this.acsSupply = bn(i))
    // });
    // this.connect.poller.add({
    //   target: Config.acsi,
    //   method: () => this.contractAcsi.methods.totalSupply(),
    //   cb: i => (this.acsiSupply = bn(i))
    // });

    this.connect.poller.addEventListener('poll', () => {
      this.acsStats.tvl = fwp(this.getStats()[0], 0);
      this.acsStats.userTvl = fwp(this.getStats()[1]);
      this.acsStats.wavTotalHarvestPending = this.getStats()[2];
      this.acsStats.wavTotalHarvestPendingUsd = this.getStats()[3];

      // this.acsStats.acsSupply = fwp(this.acsSupply, 0);
      // this.acsStats.acsiSupply = fwp(this.acsiSupply, 0);

      if (
        quoter.q(addresses.wav, Config.wbnb) &&
        quoter.q(Config.wbnb, Config.busd)
      ) {
        const wvpPriceUsd = quoter
          .q(addresses.wvp, Config.wbnb)
          .mul(quoter.q(Config.wbnb, Config.busd))
          .div(bn(1e18));
        const wavPriceUsd = quoter
          .q(addresses.wav, Config.wbnb)
          .mul(quoter.q(Config.wbnb, Config.busd))
          .div(bn(1e18));
        const bnbPriceUsd = quoter.q(Config.wbnb, Config.busd);

        this.acsStats.wvpPriceUsd = fwp(wvpPriceUsd, 3);
        this.acsStats.wavPriceUsd = fwp(wavPriceUsd, 3);
        this.acsStats.bnbPriceUsd = fwp(bnbPriceUsd, 3);
        // this.acsStats.acsPriceBNB = fwp(quoter.q(Config.acs, Config.wbnb), 4);

        // this.acsStats.acsMcap = fwp(
        //   this.acsSupply.mul(acsPriceUsd).div(bn(1e18)),
        //   0
        // );
        // this.acsStats.acsiMcap = fwp(
        //   this.acsiSupply.mul(acsiPriceUsd).div(bn(1e18)),
        //   0
        // );
      }
      this.dispatchEvent(new CustomEvent('update'));
    });
    // }

    this.connect.addEventListener('accountChanged', () => {
      this.connect.poller.poll();
    });

    console.log('pairPoll');
    await this.connect.pairPoller.poll();
    console.log('poll');
    await this.connect.poller.poll();
    setInterval(() => {
      console.log('pairPoll');
      this.connect.pairPoller.poll();
    }, 66888);
    setInterval(() => {
      console.log('poll');
      this.connect.poller.poll();
    }, 16888);

    if (
      Farm.acsVault &&
      Farm.acsVault.stats.vaultBalance.gt(bn(1e18).muln(1000))
    ) {
      const postDefistationTimestampHour = parseInt(
        Date.now() / (60 * 60 * 1000)
      );
      if (
        parseInt(localStorage.getItem('postDefistationTimestampHour')) !=
        postDefistationTimestampHour
      ) {
        localStorage.setItem(
          'postDefistationTimestampHour',
          postDefistationTimestampHour
        );
        // console.log('postDefistation', postDefistationTimestampHour);
        // limit this to trigger on >1000 acsACS only
        await this.postDefistation();
      }
    }
  }

  getStats() {
    const tvl = bn(0);
    const usertvl = bn(0);
    const wavTotalHarvestPending = bn(0);
    const wavTotalHarvestPendingUsd = bn(0);

    this.farmVaults.forEach(farmVault => {
      if (farmVault.vault && farmVault.vault.stats.vaultBalanceUsd) {
        tvl.iadd(farmVault.vault.stats.vaultBalanceUsd);
      } else if (farmVault.farm && farmVault.farm.stats.farmBalanceUsd) {
        tvl.iadd(farmVault.farm.stats.farmBalanceUsd);
      }

      if (farmVault.farm && farmVault.farm.stats.userBalanceFarmUsd) {
        usertvl.iadd(farmVault.farm.stats.userBalanceFarmUsd);
      }
      if (farmVault.vault && farmVault.vault.stats.userBalanceVaultUsd) {
        usertvl.iadd(farmVault.vault.stats.userBalanceVaultUsd);
      }

      if (
        farmVault.farm?.masterFarm.rewardTokenSymbol === 'WAV' &&
        farmVault.farm?.stats?.rewardPending?.gtn(0)
      ) {
        wavTotalHarvestPending.iadd(farmVault.farm.stats.rewardPending);
        wavTotalHarvestPendingUsd.iadd(farmVault.farm.stats.rewardPendingUsd);
      }
    });
    return [tvl, usertvl, wavTotalHarvestPending, wavTotalHarvestPendingUsd];
  }

  async postDefistation() {
    // console.log('postDefistation',)
    const data = { data: {} };
    const tvl = bn(0);
    const tbl = bn(0);
    this.farmVaults.forEach(farmVault => {
      if (farmVault.vault && farmVault.vault.stats.vaultBalanceUsd) {
        tvl.iadd(farmVault.vault.stats.vaultBalanceUsd);

        let bnbLocked = bn(0);
        if (farmVault.vault.tokenSymbol.endsWith('-BNB')) {
          bnbLocked = farmVault.vault.stats.vaultBalanceBnb.divn(2);
        }
        if (farmVault.vault.tokenSymbol == 'BNB') {
          bnbLocked = farmVault.vault.stats.vaultBalanceBnb;
        }

        tbl.iadd(bnbLocked);

        data.data[farmVault.vault.tokenSymbol + ' Vault'] = {
          address: farmVault.vault.address,
          token: farmVault.vault.token,
          tokenSymbol: farmVault.vault.tokenSymbol,
          tvl: fw(farmVault.vault.stats.vaultBalanceUsd),
          bnbLocked: fw(bnbLocked)
        };
      } else if (farmVault.farm && farmVault.farm.stats.farmBalanceUsd) {
        tvl.iadd(farmVault.farm.stats.farmBalanceUsd);

        let bnbLocked = bn(0);
        if (farmVault.farm.tokenSymbol.endsWith('-BNB')) {
          bnbLocked = farmVault.farm.stats.farmBalanceBnb.divn(2);
        }
        if (farmVault.farm.tokenSymbol == 'BNB') {
          bnbLocked = farmVault.farm.stats.farmBalanceBnb;
        }

        tbl.iadd(bnbLocked);

        data.data[farmVault.farm.tokenSymbol + ' Farm'] = {
          address: farmVault.farm.master,
          token: farmVault.farm.token,
          tokenSymbol: farmVault.farm.tokenSymbol,
          tvl: fw(farmVault.farm.stats.farmBalanceUsd),
          bnbLocked: fw(bnbLocked)
        };
      }
    });
    data.tvl = parseFloat(fw(tvl));
    data.bnb = parseFloat(fw(tbl));
    // console.log(JSON.stringify(data))

    await fetch('https://api.defistation.io/dataProvider/tvl', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          'ACryptoS:b2b9984e-b98b-4235-bb10-5bd67fa2aa7a'
        )}`
      },
      body: JSON.stringify(data)
    });
  }
}
const vaultsController = new VaultsController();

export { vaultsController };
